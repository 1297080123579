import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: null,
  status: 'idle',
};

export const loggedInUserDetailsSlice = createSlice({
  name: 'user',
  initialState,

  reducers: {
    setUser: (state, action) => {
        state.value = action.payload;
    },
  },
});

export const { setUser } = loggedInUserDetailsSlice.actions;

export const userDetails = (state) => state.user.value;

export default loggedInUserDetailsSlice.reducer;