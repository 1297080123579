import React from 'react';
import { Route, withRouter } from 'react-router';
import Event from './events';
import EventDetails from './eventDetails';


function EventLayout(props) {
  const pathChangeCallback = (path) => {
    props.pathChange(path);
  }
  return <>
    <Route exact={true} path={`${props.match.path}`}>
      <Event />
    </Route>
    <Route exact={true} path={`${props.match.path}/event-details/:id`}>
      <EventDetails path={pathChangeCallback} />
    </Route>
  </>
}

export default withRouter(EventLayout);
