import './App.css';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import ScrollToTop from './component/basic/ScrollToTop';
import { Suspense, useEffect, useRef, useState, createContext } from 'react';
import Home from './component/home/home';
import Header from './component/common/header/header';
import Footer from './component/common/footer/footer';
import { LazyLoader } from './utils/Utils';
import Loader from './utils/Loader';
import { useSelector, useDispatch } from 'react-redux';
import axiosApi from './core/interceptor/interceptor';
import { config } from './config';
import { setUser } from './store/reducer/LoggedInUserDetails';
import { ToastContainer } from 'react-toast';
import ResetPassword from './component/common/resetPassword/ResetPassword';
import InvitePlayer from './component/invitePlayer/InvitePlayer';
import LogInGuard from './core/guard/LogInGuard';
import LeagueLayOutComponent from './component/league/LeagueLayout';
import Contact from './component/contact/Contact';
import AccountLayoutComponent from './component/account/AccountLayout';
import EventLayout from './component/events/EventLayout';
import AccountMenuSidebar from './component/account/Account';
import { useIdleTimer } from 'react-idle-timer';
import { userDetails } from './store/reducer/LoggedInUserDetails';
import WarningDialogue from './utils/WarningDialogue';
import CompletePayment from './component/complete-payment/completePayment';
import TodaySchedule from './component/today-schedule/today-schedule';

export const ReponsiveSidebarToggle = createContext(false);

function App(props) {
  const dispatch = useDispatch();
  const user = useSelector(userDetails);
  const loader = useSelector((state) => state.loader.value);
  const refHeader = useRef(null);
  const [marginTop, setMarginTop] = useState(0);
  const [pathValue, setPathValue] = useState(false);
  const [headerNotifiClass, setHeaderNotifiClass] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [showTimeoutPopup, setShowTimeoutPopup] = useState(false);
  const [popupContent, setPopupContent] = useState({});

  useEffect(() => {
    getUserDetails();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTimeout(() => {
      refHeader && refHeader.current && setMarginTop(refHeader.current.firstChild.clientHeight)
      if (window.location.pathname !== '/') {
        setPathValue(window.location.pathname);
      }
    }, 100);
  }, [refHeader?.current?.firstChild?.clientHeight, headerNotifiClass]);



  const getUserDetails = () => {
    if (localStorage.getItem('token')) {
      axiosApi.get(`${config.apiUrl}/user/me`)
        .then((res) => {
          dispatch(setUser(res.data.data));
        })
    }
  }
  const pathChange = (pathVal) => {
    if (pathVal !== '/' && pathVal !== '') {
      setPathValue(pathVal);
    } else {
      setPathValue(false);
    }
  }

  const isHeaderNotification = (cb) => {
    setHeaderNotifiClass(cb);
  }

  const toggleMenu = (flag) => {
    switch (flag) {
      case undefined:
        setOpenMenu(!openMenu);
        break;

      default:
        setOpenMenu(flag);
        break;
    }
  }

  /**IdleTimer */
  const handleOnIdle = event => {
    if (user) {
      localStorage.clear();
      dispatch(setUser(null));
      handleSessionLogout();
    } else {
      window.location.reload();
    }
  }

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
    debounce: 500
  })

  const handleSessionLogout = () => {
    setShowTimeoutPopup(true);
    setPopupContent({
      header: 'Session Expired',
      description: 'Your session has been expired due to inactivity, please login to continue.',
      action: () => {
        setShowTimeoutPopup(false);
        window.location.reload();
      }
    })
  }

  return (
    <>
      <Router>
        <div className={`ns-header-div ${headerNotifiClass ? 'Header-noti-exist' : ''}`} ref={refHeader}>
          <Header isHeaderNotification={isHeaderNotification} pathChange={(e) => { pathChange(e) }} openSidebar={() => setOpenSidebar(o => !o)} openMenu={() => toggleMenu()} />
        </div>
        <AccountMenuSidebar open={openMenu} close={() => toggleMenu(false)} />
        {
          <div style={{
            // marginTop: pathValue && pathValue !== '/events' ? marginTop : 0
            marginTop: marginTop
          }}>
            <ScrollToTop>
              <Suspense fallback={< LazyLoader />}>
                <Switch>

                  <Route exact={true} path={`/`}>
                    <Home pathChange={(e) => { pathChange(e) }} />
                  </Route>
                  <Route exact={true} path={`/today-schedule`}>
                    <TodaySchedule />
                  </Route>
                  <Route path={`/league`}>
                    <LeagueLayOutComponent pathChange={(e) => { pathChange(e) }} />
                  </Route>
                  <Route path={`/my-account`}>
                    <ReponsiveSidebarToggle.Provider value={{ open: openSidebar, setOpen: () => setOpenSidebar(!openSidebar) }}>
                      <LogInGuard Component={AccountLayoutComponent} pathChange={pathChange} />
                    </ReponsiveSidebarToggle.Provider>
                  </Route>

                  <Route exact={true} path={`/contact`}>
                    <Contact />
                  </Route>
                  <Route exact={true} path={`/reset-password/:token`}>
                    <ResetPassword />
                  </Route>
                  <Route exact={true} path={'/player-invitation/:accepted/:token'}>
                    <InvitePlayer />
                  </Route>
                  <Route exact={true} path={'/player-invitation/:accepted/:token'}>
                    <InvitePlayer />
                  </Route>
                  <Route path={'/events'}>
                    <EventLayout pathChange={pathChange} />
                  </Route>
                  <Route exact={true} path={'/complete-payment/:token/:type'}>
                    <CompletePayment />
                  </Route>
                  <Redirect to={"/"} />
                </Switch>
              </Suspense >
            </ScrollToTop>
          </div>
        }
        <ToastContainer
          position={'top-right'}
          delay={8000}
        />
        {loader && <Loader />}
        <Footer pathChange={(e) => { pathChange(e) }} />
      </Router>
      <WarningDialogue
        type='session'
        isOpen={showTimeoutPopup}
        header={popupContent.header}
        description={popupContent.description}
        action={popupContent.action}
        onClose={() => setShowTimeoutPopup(false)}
        closeOnDocumentClick={true}
      />
    </>
  );
}

export default App;
