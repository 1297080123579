import debounce from "lodash.debounce";
import moment from 'moment';
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { Link, NavLink, useHistory, useParams, withRouter } from "react-router-dom";
import Popup from "reactjs-popup";
import arrowRight from "../../../assets/icons/arrowRight.svg";
import searchIcon from '../../../assets/images/search-icon.png';
import {
  HOSTED_BY,
  config,
} from "../../../config";
import axiosApi from "../../../core/interceptor/interceptor";
import { userDetails } from "../../../store/reducer/LoggedInUserDetails";
import { sportsListDetails } from "../../../store/reducer/SportsList";
import { toTitleCase } from "../../../utils/Utils";
import WarningDialogue from "../../../utils/WarningDialogue";
import Login from "../../common/login/Login";
import Signup from "../../common/signup/signup";
const LeagueList = (props) => {
  const sportId = useParams().id;
	const [sportName ,setSportName] = useState('');
  const [oldId, setOldId] = useState(sportId);
  const sportsList = useSelector(sportsListDetails);
  const history = useHistory()
  const isLoggedIn = useSelector(userDetails);
  const [leagues, setLeagues] = useState([]);
  const [sportOverview, setSportOverview] = useState({});
  const [type, setType] = useState('');
  const [filter, setFilter] = useState({
    status: '',
    limit: 50,
    page: 1,
    search: ''
  });
  const [searchString, setSearchString] = useState('');
  const [count, setCount] = useState(0);
  const [regType, setRegType] = useState('team');
  const loader = useSelector((state) => state.loader.value);
  const [login, setLogIn] = useState(false);
  const [signup, setSignUp] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [leagueId, setLeagueId] = useState(0);

  const types = {
    sign_up: 'sign_up',
    individual: 'sign_up',
    active: 'active',
    archived: 'complete',
  };

  /****** Leagues related to sports **********/
  const getLeagues = (params) => {
    if (sportId) {
      axiosApi.get(`${config.apiUrl}/leagues/all-leagues/${sportId}`, { params }).then((response) => {
        if (response && response.data && response.data.data && response.data.data.rows) {
          setCount(response.data.data?.count);
          if (filter.page > 1) {
            setLeagues((prev) => ([...prev, ...response.data.data?.rows]));
          } else {
            setLeagues([...response?.data?.data?.rows]);
          }
        }
      }).catch(error => {
        console.error(error);
      });
    }

  }

  /******* Leagues for Individual Registration ****/
  const getIndividualLeagues = (params) => {
    axiosApi.get(`${config.apiUrl}/leagues/all-leagues-host`, { params }).then((response) => {
      if (response && response.data && response.data.data && response.data.data.rows) {
        setCount(response.data.data?.count);
        if (filter.page > 1) {
          setLeagues((prev) => ([...prev, ...response.data.data?.rows]));
        } else {
          setLeagues([...response?.data?.data?.rows]);
        }
      }
    }).catch(error => {
      console.error(error);
    });
  }

  const clearFilter = () => {
    setLeagues([]);
    setSportOverview({});
    setSearchString('');
    setFilter((prev) => ({
      ...prev,
      status: 'sign_up',
      limit: 50,
      page: 1,
      search: ''
    }));
  }

  /******** League Overview *******/
  useEffect(() => {
    if (sportId > 0) {
      axiosApi.get(`${config.apiUrl}/leagues/overview/${sportId}`, { params: { indv: regType !== 'team' } }).then((response) => {
        if (response && response.data && response.data.data) {
          setSportOverview(response.data.data);
        }
      }).catch(error => {
        console.error(error);
      });
    }
  }, [sportId, regType])

  // const searchLeague = () => {
  //   setFilter((prev) => ({
  //     ...prev,
  //     page: 1,
  //     search: searchString,
  //   }));
  // }

  useEffect(() => {
    if (sportId > 0) {
      if (props.match.params.type !== type || oldId !== sportId) {
        // getLeagues({ ...filter, indv: regType !== 'team' ? true : '', status: types[props?.match?.params?.type], page_type: props?.match?.params?.type });
        setRegType(props?.match?.params?.type === 'individual' ? 'indv' : 'team');
        setType(props?.match?.params?.type);
        setOldId(sportId);
        clearFilter();
      }
    } else {
      // getIndividualLeagues(filter);
      // setRegType('indv');
      setType(props?.match?.params?.type);
      setRegType(props?.match?.params?.type === 'individual' ? 'indv' : 'team');
      setLeagues([]);
      setOldId(0);
    }
    //eslint-disable-next-line
  }, [sportId, props]);


  useEffect(() => {
    getLeagues({ ...filter, indv: regType !== 'team' ? true : '', status: types[props?.match?.params?.type], page_type: props?.match?.params?.type, current_date: moment().format('MM-DD-YYYY') });
    // eslint-disable-next-line
  }, [filter.page, filter.status, filter.search, type, sportId]);

  const registerTeam = (leagueId) => {
    const url = regType !== 'team' ? `/league/individual-registration/${leagueId}` : `/league/team-registration/${leagueId}/${sportId}`;
    history.push(url, {
      from: regType === 'team' ? 'team' : 'indv',
    });
  };

  const searchLeague = (e) => {
    filter.page = 1;
    filter.search = e.target.value;

    setFilter((prev) => ({
      ...prev,
      page: 1,
      search: e.target.value
    }));
    if (sportId) {
      // getLeagues(filter);
    } else {
      getIndividualLeagues(filter);
    }
  }
  const debouncedSearch = debounce(searchLeague, 1000);


  const getNextPage = () => {
    setFilter((prev) => ({
      ...prev,
      page: prev.page + 1,
    }));
  }

  useEffect(() => {
		if (sportId && sportsList && sportsList[type]) {
			const sport = sportsList[type].find(x => +x.id === +sportId);
			if (sport?.title) {
				setSportName(sport?.title);
			}
		}
	}, [ type, sportsList, sportId ])

  return <section className={`main-side ${sportId ? '' : 'no-sidebar'}`}>
    <div className="main-side-container">
      <div className={`main-top-headings ${type === 'active' || type === 'archived' ? 'detail-page-headings' : ''}`}>
        {<div className="main-top-tabs">

          {
            type === 'sign_up' && <h3> {sportId ? 'TEAM' : 'INDIVIDUAL'} REGISTRATION for &nbsp;<span>{ sportName || ''}</span></h3>
          }

          {
            type === 'active' && <h3> ACTIVE LEAGUES IN &nbsp;<span>{ sportName || ''}</span></h3>
          }

          {
            type === 'archived' && <h3> ARCHIVED LEAGUES IN &nbsp;<span>{ sportName || ''}</span></h3>
          }

          {
            type === 'individual' && <h3> INDIVIDUAL REGISTRATION
            </h3>
          }

        </div>}
        <div className="right-side-filters">
          <div className={`fleids-block input-field-search ${sportId ? '' : 'no-bar-tabs'}`}>
            <div className="search-input-box">
              <input id="searchBox" type="text" autocomplete="off" className="form-control" placeholder="Search..." onChange={(e) => debouncedSearch(e)} />
              <button disabled="" className="search-btn">
                <img src={searchIcon} alt="" />
              </button>
            </div>
            {
							sportId && isMobile &&
							<NavLink className='btn btn-primary btn-with-search leagues-show-btn link' to={`/league/sports/${type}`}>Available Sports</NavLink>
						}
          </div>

          <div className="change-page-btn">
            {
              type === 'sign_up' &&
              <button className="btn btn-primary" onClick={() => history.push(`/league/team/${sportsList && sportsList.individual && sportsList.individual.length ? sportsList.individual[0].id : 0}/individual`)}>Register as an Individual</button>
            }

            {
              type === 'individual' &&
              <button className="btn btn-primary" onClick={() => history.push(`/league/team/${sportsList && sportsList.sign_up && sportsList.sign_up.length ? sportsList.sign_up[0].id : 0}/sign_up`)}>Register as a Team</button>
            }

            {
              sportId && isMobile && !['sign_up', 'individual'].includes(type) &&
              <NavLink className='btn btn-primary btn-with-search' to={`/league/sports/${type}`}>Available Sports</NavLink>
            }
          </div>
        </div>
      </div>
      <div className="main-side-body">
        <div className={`league-cards ${leagues.length > 0 ? '' : 'no-records-in-table'}`}>
          {
            Array.isArray(leagues) && leagues.length ?
              leagues.map((league, i) => {
                return <div className={`league-card ${['individual','archived','sign_up'].includes(type) ? "um-cards league-um-cards-rs" : ""}`} key={`league-list-@#$-${i + 1}`}>
                  <div className="league-card-header">
                    <h3>{league.name}</h3>
                  </div>
                  <div className={`league-card-body ${['individual','archived','sign_up'].includes(type) ? "border-bottom-unset" : ""}`}>
                    <div className="league-card-items">
                      {
                        type !== 'active' && <>
                          <div className="league-card-item">
                            <p className="legue-detail-title">Sport:</p>
                            <p className="league-detail-info">{toTitleCase(league?.sport?.title)}</p>
                          </div>
                          <div className="league-card-item">
                            <p className="legue-detail-title">Level of play:</p>
                            <p className="league-detail-info">{toTitleCase(league?.play_level)}</p>
                          </div>
                          <div className="league-card-item">
                            <p className="legue-detail-title">
                              Registration deadline:
                            </p>
                            <p className="league-detail-info">{league?.reg_deadline ? moment(league?.reg_deadline, 'YYYY-MM-DD').format('MM/DD/YYYY') : 'N/A'}</p>
                          </div>
                        </>
                      }
                      <div className="league-card-item">
                        <p className="legue-detail-title">Start Date:</p>
                        <p className="league-detail-info">{league?.start_date ? moment(league?.start_date, 'YYYY-MM-DD').format('MM/DD/YYYY') : 'N/A'}</p>
                      </div>
                      <div className="league-card-item">
                        <p className="legue-detail-title">League Length:</p>
                        <p className="league-detail-info">{league?.no_of_weeks || 0} Week{league?.no_of_weeks > 1 ? 's' : ''}</p>
                      </div>

                      {
                        type !== 'active' &&
                        <>
                          <div className="league-card-item">
                            <p className="legue-detail-title">Hosted by:</p>
                            <p className="league-detail-info">{league?.hosted_by === HOSTED_BY ? 'Cleveland' : 'Mulberry'}</p>
                          </div>
                          <div className="league-card-item">
														<p className="legue-detail-title">Location:</p>
														<p className="league-detail-info hosted">{
															league && Array.isArray(league.league_locations) && league.league_locations.length ?
															league.league_locations.map((loc, idx) => {
																if (loc.location) {
																	return loc.location.title + (idx < league.league_locations.length - 1 ? ', ' : '');
																}
																return 'N/A';
																}) : 'N/A'
															}
														</p>
													</div>
                        </>
                      }
                    </div>
                  </div>
                  <div className="league-card-actions">
                    {league?.status === 'sign_up' && <a className="btn btn-primary" onClick={(e) => {
                      e.preventDefault();
                      if (parseInt(league.teams) && league.teams >= league.team_size) {
                        setLeagueId(league.id);
                        return setShowWarning(true);
                      }
                      if (isLoggedIn) {
                        registerTeam(league.id);
                      } else {
                        setLeagueId(league.id);
                        setLogIn(true);
                      }
                    }}>
                      Register Now
                      <img src={arrowRight} alt="" />
                    </a>}
                    <Link className="btn btn-dark-gray" to={{
                      pathname: `/league/league-details/${league.id}/${regType === 'team' ? sportId : ''}`,
                      state: {
                        from: regType !== 'team' ? 'indv' : '',
                      }
                    }}>
                      League Info
                      <img src={arrowRight} alt="" />
                    </Link>
                  </div>
                </div>
              })
              :
              !loader && <div className="noRecord-found">
                <p>No Record Found</p>
              </div>
          }
        </div>
        {leagues.length && leagues.length < count ? <div className="card-more-btn">
          <button className="btn btn-primary green" onClick={(e) => {
            e.preventDefault();
            if (count > leagues.length) {
              getNextPage();

            }
          }}>Additional Leagues, Click Here</button>
        </div> : <></>}
      </div>
    </div>
    <Popup
      open={login}
      onClose={() => {
        setLogIn(false);
      }}
      closeOnDocumentClick={true}
    >
      <Login
        closeLoginModal={(resp) => {
          if (resp) {
            registerTeam(leagueId);
          }
          setLogIn(false);
        }}
        login={true}
        openSignUp={() => setSignUp(true)}
      ></Login>
    </Popup>
    <Popup
      open={signup}
      onClose={() => {
        setSignUp(false);
      }}
      closeOnDocumentClick={false}
    >
      <Signup
        closeSignupModal={() => setSignUp(false)}
        openLogin={() => setLogIn(true)}
      ></Signup>
    </Popup>
    <WarningDialogue
      isOpen={showWarning}
      header={"Alert"}
      description={
        <>
          <span>Are you sure, you want to register in this league?</span>
          <br />
          <span>You'll be moved to the wait list.</span>
          <br />
          <i className="wait-list-note"><strong>Note:</strong> Your card will not be charged if left on the wait list.</i>
        </>
      }
      action={() => {
        setShowWarning(false);
        isLoggedIn ? registerTeam(leagueId) : setLogIn(true);
      }}
      onClose={() => setShowWarning(false)}
      backdrop={true}
    />
  </section>
}

export default withRouter(LeagueList);