import { useHistory, useParams, withRouter } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { sportsListDetails } from "../../../store/reducer/SportsList";
import { config } from "../../../config";
import "./LeagueSports.css";
function LeagueSports() {
  const history = useHistory();
  const TYPE = {
    active: "ACTIVE",
    archived: "ARCHIVED",
    sign_up: "REGISTRATION",
    individual: "INDIVIDUAL REGISTRATION",
  };
  const { type } = useParams();
  const [selectedSports, setSelectedSports] = useState([]);
  const sports = useSelector(sportsListDetails);

  useEffect(() => {
    if (type && sports?.[type]) {
      setSelectedSports(sports[type]);
    }
  }, [type, sports]);

  const redirectTo = (id) => {
    history.push(`/league/team/${id}/${type}`);
  }
  return (
    <>
      <section className='main-side sport-page-ui'>
        <div className='main-side-container'>
          <div className='main-top-headings leagues-list-head detail-page-headings'>
            <div className='main-top-tabs'>
              <h3>SELECT SPORT</h3>
            </div>
          </div>
          <div className='sports-list'>
            {selectedSports?.length > 0 &&
              selectedSports.map((val, key) => (
                <div className='s-details' key={key} onClick={() => redirectTo(val.id)}>
                  <div className='img-blk'>
                    <img className='sport-img' src={`${config.sportImage}/${val.image}`} alt={val.title} />
                  </div>
                  <div className='title-blk'>{val.title}</div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default withRouter(LeagueSports);
