import React, { useEffect, useMemo, useState } from "react";
import { withRouter, useParams } from 'react-router-dom';
import './eventDetails.css';
import InputMask from 'react-input-mask';
import fundImg from '../../assets/images/fundraiser-img.jpg';
import organiserImg from '../../assets/images/organiser.png';
import membersImg from '../../assets/icons/default-user.svg';
import arrowImg from '../../assets/images/arrowImg.svg';
import axiosApi from "../../core/interceptor/interceptor";
import { config } from "../../config";
import moment from 'moment';
import { formatNumberToCurrency, phoneNumberMask, validateEmail } from "../../utils/Utils";
import resetIcon from "../../assets/icons/reset.svg";
import { userDetails } from '../../store/reducer/LoggedInUserDetails';
import { useSelector, useDispatch } from 'react-redux';
import Login from "../common/login/Login";
import Signup from "../common/signup/signup";
import Popup from "reactjs-popup";
import Payment from "../events/Payment/Payment";
import NumberFormat from 'react-number-format';
import { messages } from "../../messages";
import WarningDialogue from '../../utils/WarningDialogue';
import print from "../../assets/images/print-icon.svg";

function EventDetails(props) {
  const eventId = useParams().id;
  const [event, setEvent] = useState({});
  const [showBookingSection, setShowBookingSection] = useState(false);
  const [isBookingOn, setIsBookingOn] = useState(false);
  const [bookingList, setBookingList] = useState([]);
  const loggedInUserDetails = useSelector(userDetails);
  const [login, setLogIn] = useState(false);
  const [signup, setSignUp] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [openPayment, setOpenPayment] = useState(false);
  const [error, setError] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [openWarningPopup, setOpenWarningPopup] = useState(false);
  const [popupContent, setPopupContent] = useState({});
  const [availableTickets, setAvalableTickets] = useState(0);

  const onFormChange = (e, index) => {
    const temp = [...tickets];
    if (!temp[index]) {
      temp[index] = {};
    }
    temp[index][e.target.name] = e.target.value;
    setTickets(temp);
  }

  const getEvent = (id, call) => {
    axiosApi.get(`${config.apiUrl}/events/${id}`)
      .then((res) => {
        if (res && res.data && res.data.data) {
          const resp = res.data.data;
          setEvent(resp);
          if (resp.booking_start_date && resp.booking_deadline) {
            const today = moment(new Date()).format('MM/DD/YYYY');
            if (today >= moment(resp.booking_start_date).format('MM/DD/YYYY') && today <= moment(resp.booking_deadline).format('MM/DD/YYYY')) {
              setIsBookingOn(true);
            }
          }
          getAvailableTickets();
        }
      })
  }

  useEffect(() => {
    if (event.id > 0) {
      getAvailableTickets();
    }
  }, [event.id]);

  useEffect(() => {
    if (eventId > 0) {
      getEvent(eventId);
    }
  }, [eventId]);

  useEffect(() => {
    setTickets([{
      first_name: loggedInUserDetails?.first_name || '',
      last_name: loggedInUserDetails?.last_name || '',
      email: loggedInUserDetails?.email || '',
      phone: loggedInUserDetails?.cell_phone || '',
      no_delete: true,
    }]);

    getBookingList();
    // getAvailableTickets();
  }, [loggedInUserDetails])

  useEffect(() => {
    const tickts = (event?.no_of_guest - (event?.booked || 0))
    setAvalableTickets(tickts);
  }, [event])

  const getBookingList = () => {
    if (loggedInUserDetails) {
      axiosApi.get(`${config.apiUrl}/events/bookings/${eventId}`)
        .then((res) => {
          if (res && res?.data?.data && res.data.data.length > 0) {
            setBookingList(res?.data?.data);
            setShowBookingSection(false);
          } else {
            setShowBookingSection(true);
          }
        });
    } else {
      setShowBookingSection(true);
    }
  }

  const getAvailableTickets = () => {
    axiosApi.get(`${config.apiUrl}/events/available-tickets/${eventId}`)
      .then((res) => {
        if (res && res?.data?.data) {
          setEvent((prev) => ({
            ...prev,
            booked: res?.data?.data,
          }));
        }

        const date = moment().unix();
        if (event?.booking_start_date && event.booking_deadline) {
          const dateValid = date >= moment(`${event.booking_start_date} 00:00:00`).unix() && date <= moment(`${event.booking_deadline} 23:59:00`).unix();
          setEvent((prev) => ({
            ...prev,
            dateValid: dateValid,
          }));
          console.log('dateValid', dateValid);
        }
      });
  }

  useEffect(() => {
    handleValidation();
  }, [tickets])

  /**Path change callback */
  useEffect(() => {
    props.path('/event-details');
  }, [])

  const addMember = () => {
    const temp = [...tickets];
    temp.push({});
    setTickets(temp);
  }

  const removeMember = (key) => {
    const temp = [...tickets];
    temp.splice(key, 1);
    setTickets(temp);
  }

  const registerTeam = () => {
    // const url = props.location.state?.from === 'indv' ? `/league/individual-registration/${leagueData?.id}` : `/league/team-registration/${leagueData?.id}/${sportId}`;
    // history.push(url, {
    //   from: props.location.state?.from === "indv" ? "indv" : "",
    // });
  };

  const paynow = () => {
    setSubmitted(true);
    if (!loggedInUserDetails) {
      setLogIn(true);
    } else {
      if (handleValidation()) {
        setOpenPayment(true);
      }
    }
  }

  const booknow = () => {
    setSubmitted(true);
    if (!loggedInUserDetails) {
      setLogIn(true);
    } else {
      if (handleValidation()) {
        setOpenWarningPopup(true);
        setPopupContent({
          header: 'Confirm',
          description: 'Would like to book free tickets for this event?',
          action: () => {
            setOpenWarningPopup(false);
            axiosApi.post(`${config.apiUrl}/events/fbook-tickets/${event.id}`, {
              tickets: tickets, email: loggedInUserDetails?.email
            })
              .then((res) => {
                showSuccessMessage();
              })
          }
        });
      }
    }
  }

  const paymentAmounts = useMemo(() => {
    let total = 0;
    let stripe = 0;
    let fee = 0;
    if (tickets.length > 0) {
      total = ((+(tickets.length * event?.ticket_price) + 0.30) / (1 - 0.029)).toFixed(2);
      stripe = (total - (tickets.length * event?.ticket_price)).toFixed(2);
      fee = (tickets.length * event?.ticket_price).toFixed(2);
    }
    return {
      total: total,
      stripe: stripe,
      fee: fee
    };
  }, [tickets.length, event]);


  const handleValidation = () => {
    let valid = true;
    let err = [];
    if (tickets && tickets.length > 0) {
      const fields = ['first_name', 'last_name', 'email', 'phone'];
      tickets.forEach((val, i) => {

        if (!err[i]) {
          err[i] = {};
        }

        for (const iterator of fields) {
          if (!val[iterator]) {
            valid = false;
            err[i][iterator] = messages[`empty_${iterator}`];
          } else if (iterator === 'email' && !validateEmail(val[iterator])) {
            valid = false;
            err[i][iterator] = messages[`invalidEmail`];
          }
        }

      });
    } else {
      valid = false;
    }
    setError(err);
    return valid;
  }

  const showSuccessMessage = () => {
    getBookingList();
    getAvailableTickets();

    setOpenWarningPopup(true);
    setPopupContent({
      header: 'Success',
      description: <div>Your booking for this event is confirmed. You will receive a confirmation email.</div>,
      success: true,
      action: () => {
        setOpenWarningPopup(false);
      },
      type: 'payment_success'
    });
    reset();
  }

  const reset = () => {
    setTickets([{}]);
  }

  const setCancelTickets = (pkey, ckey, e) => {
    const temp = [...bookingList];
    temp[pkey].event_tickets[ckey]['cancel'] = e.target.checked;
    const found = temp[pkey].event_tickets.find(x => x.cancel === true);
    temp[pkey].cancel_ticket = found ? true : false;
    setBookingList(temp);
  }

  const cancelWarning = (k) => {
    setOpenWarningPopup(true);
    setPopupContent({
      header: 'Confirm',
      description: 'Would like to send cancelation request to event manager? On cancelation you will receive confirmation email.',
      action: () => {
        setOpenWarningPopup(false);
        const params = {
          transactionId: bookingList[k].id,
          ticketIds: bookingList[k].event_tickets.filter(x => x.cancel).map(x => x.id),
        };
        axiosApi.post(`${config.apiUrl}/events/ticket-cancelation`, params)
          .then((res) => {
            getBookingList();
          })
      }
    });
  }

  const openInvoice = async (event) => {
    fetch(`${config.apiUrl}/events/get-ticket/${event.id}`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token') ? localStorage.getItem('token') : ''}`,
        'content-type': 'application/json'
      },
    })
      .then(response => {
        if (response.status === 200) {
          response.blob().then((blb) => {
            const url = window.URL.createObjectURL(
              new Blob([blb]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              event.invoice_file ? event.invoice_file : `${event.id}-invoice.pdf`,
            );
            // Append to html link element page
            document.body.appendChild(link);
            // Start download
            link.click();
            // Clean up and remove the link
            link.parentNode.removeChild(link);
          })
        } else {
          response.json().then((res) => {
            console.log(res)
          })
        }
      })
  }
  return (
    <>
      <section className="fundraiser-details">
        <div className="event-container">
          <div className="event-row">
            <div className="fundraiser-img">
              <img src={event?.image ? `${config.api}/${config.imageFolder}/events/${event?.image}` : fundImg} alt=" funImg" />
            </div>
            <div className="fundraiser-content">
              <h2>{event?.title || 'N/A'}</h2>
              <p dangerouslySetInnerHTML={{ __html: event?.detail || '' }}></p>
              <div className="event-row add-event-box">
                {new Date(event?.booking_start_date) > new Date() && <div className="event-date event-booking-date">
                  <span>Booking Start Date</span>
                  <div className="start-date">
                    <h5>{moment(event?.booking_start_date).format('ll hh:mm A')}</h5>
                  </div>
                </div>}
                <div className="event-date event-start-date">
                  <span>{new Date(event?.booking_start_date) > new Date() ? 'Event ' : ''}Start Date</span>
                  <div className="start-date">
                    <h5>{moment(event?.start_date_time).format('ll hh:mm A')}</h5>
                  </div>
                </div>
                <div className="event-date event-end-date">
                  <span>{new Date(event?.booking_start_date) > new Date() ? 'Event ' : ''}End Date</span>
                  <div className="end-date">
                    <h5>{moment(event?.end_date_time).format('ll hh:mm A')}</h5>
                  </div>
                </div>
              </div>
              <div className="organiser-details event-row">
                <div className="organiser-img">
                  <img src={event?.Organizer?.profile_photo ? `${config.api}/${config.imageFolder}/profile/${event?.Organizer?.profile_photo}` : membersImg} alt="Porifle Image" />
                </div>
                <div>
                  <span>Organiser</span>
                  {/* <div className="organiser-name">
                    <h5>{event?.client_name || 'N/A'}</h5>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="ticket-venue-details">
        <div className="event-container">
          <div className="ticket-venue-content event-row">
            <div className="ticket-price ticket-venue">
              <span>Ticket Price</span>
              <div>
                <h5>{formatNumberToCurrency(event?.ticket_price) || '$0.00'}</h5>
              </div>
            </div>
            <div className="available-tickets ticket-venue">
              <span>Available Tickets</span>
              <div className="ticket-menu">
                <h5>{availableTickets}</h5>
                <button className="refresh-btn" onClick={() => { getEvent(eventId, true); }}>
                  <img className="refresh" src={resetIcon} />
                </button>
              </div>
            </div>
            <div className="event-type ticket-venue">
              <span>Event Type</span>
              <div>
                <h5>{event?.occasion || 'N/A'}</h5>
              </div>
            </div>
            <div className="venue ticket-venue">
              <span>Venue</span>
              <div>
                <h5>{event?.event_venue?.name || "TBD"}</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {
        event && showBookingSection && availableTickets > 0 && isBookingOn &&
        <>
          <section className="ticket-booking">
            <div className="event-container">
              <div className="ticket-booking-content">
                <div className="add-members event-row">
                  <div className="addmembers-left">
                    <h2>Book Tickets for <span>{event?.title}</span></h2>
                    {
                      loggedInUserDetails &&
                      <div className="members-details">
                        <div className="members-img">
                          <img src={loggedInUserDetails?.profile_photo ? `${config.api}/${config.imageFolder}/profile/${loggedInUserDetails?.profile_photo}` : membersImg} alt="memberImg" />
                        </div>
                        <div className="members-name">
                          <h5>{loggedInUserDetails?.first_name} {loggedInUserDetails?.last_name}</h5>
                          <h6>{loggedInUserDetails?.email}</h6>
                        </div>
                      </div>
                    }

                  </div>
                  <div className="addmembers-right">
                    <button className="btn" onClick={() => { loggedInUserDetails ? addMember() : setLogIn(true) }}>ADD MEMBERS</button>
                  </div>
                </div>

                {
                  tickets && tickets.length > 0 &&
                  tickets.map((ticket, key) => (
                    <div className="member-details" key={key}>
                      <div className="event-row ">
                        <div className="member-details-input">
                          <div className="fleids-block">
                            <label>First Name:</label>
                            <input className="form-control" type="text" name="first_name" value={ticket?.first_name || ''} onChange={(e) => onFormChange(e, key)} autoComplete="off" placeholder="Enter First Name" />
                            <span className="error">{submitted && error[key] && error[key]?.first_name}</span>
                          </div>
                        </div>
                        <div className="member-details-input">
                          <div className="fleids-block">
                            <label>Last Name:</label>
                            <input className="form-control" type="text" name="last_name" value={ticket?.last_name || ''} onChange={(e) => onFormChange(e, key)} autoComplete="off" placeholder="Enter Last Name" />
                            <span className="error">{submitted && error[key] && error[key]?.last_name}</span>
                          </div>
                        </div>
                        <div className="member-details-input">
                          <div className="fleids-block">
                            <label>Email:</label>
                            <input className="form-control" type="text" name="email" value={ticket?.email || ''} onChange={(e) => onFormChange(e, key)} autoComplete="new-password" placeholder="Enter Email" />
                            <span className="error">{submitted && error[key] && error[key]?.email}</span>
                          </div>
                        </div>
                        <div className="member-details-input">
                          <div className="fleids-block" >
                            <label>Phone:</label>
                            <InputMask className="form-control" mask={phoneNumberMask} name="phone" value={ticket?.phone || ''} onChange={(e) => { onFormChange(e, key) }} autoComplete={'off'} placeholder="Enter Phone Number"></InputMask>
                            <span className="error">{submitted && error[key] && error[key]?.phone}</span>
                          </div>
                        </div>

                        <div className="member-details-arrow">
                          {
                            key > 0 && <i onClick={() => removeMember(key)}>&times;</i>
                          }
                        </div>

                      </div>
                    </div>
                  ))
                }

                {
                  event.paid_type === 'paid' && <div className="ticket-price-container">
                    <div className="ticket-price-details event-row">
                      <div className="ticket-heading">
                        <h4>{tickets.length} Member Ticket{tickets.length > 1 ? 's' : ''} Price</h4>
                      </div>
                      <div className="ticket-total">
                        <div>
                          <h4>{formatNumberToCurrency(event?.ticket_price)} x {tickets.length}:</h4>
                        </div>
                        <div className="ticket-price-side">
                          <h3>{formatNumberToCurrency(paymentAmounts.fee) || '$0'}</h3>
                        </div>
                      </div>
                    </div>
                    <div className="ticket-price-details event-row">
                      <div className="ticket-heading">
                        <h4></h4>
                      </div>
                      <div className="ticket-total">
                        <div>
                          <h4>Stripe Fee: </h4>
                        </div>
                        <div className="ticket-price-side">
                          <h3>{formatNumberToCurrency(paymentAmounts.stripe) || '$0'}</h3>
                        </div>
                      </div>
                    </div>
                    <div className="ticket-price-details event-row total-amount">
                      <div className="ticket-heading">
                        <h4></h4>
                      </div>
                      <div className="ticket-total">
                        <div >
                          <h4>Total Amount:</h4>
                        </div>
                        <div className="ticket-price-side">
                          <h3>{formatNumberToCurrency(paymentAmounts.total) || '$0'}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                }

              </div>
            </div>
          </section>
          <section className="event-submit-buttons">
            <div className="event-container">
              <div className="event-submit-buttons-content event-row">
                {
                  event.paid_type === 'paid' ?
                    <button className="btn" onClick={paynow}>PAY NOW</button>
                    :
                    <button className="btn" onClick={booknow}>BOOK NOW</button>
                }

                <button className="btn cancel-btn" onClick={reset}>CANCEL</button>
              </div>
            </div>
          </section>
        </>
      }

      {
        bookingList && availableTickets > 0 && !showBookingSection && bookingList.length > 0 && isBookingOn &&
        <section className="ticket-booking book-more">
          <div className="event-container">
            <button className="btn" onClick={() => setShowBookingSection(true)}>Book Tickets</button>
          </div>
        </section>
      }
      {
        bookingList && bookingList.length > 0 && bookingList.map((booking, k) => (
          <section className="ticket-booking booked" key={k}>
            <div className="event-container">
              <div className="ticket-booking-content">
                <div className="booked-title">
                  <h2>Tickets booked on <span>{moment(booking.created_at).format('MM/DD/YYYY')}</span></h2>
                  <div className="action-btn">
                    {
                      booking.status === 'active' && booking.cancel_ticket && <button className="btn btn-cancel" onClick={() => cancelWarning(k)}>
                        Cancel
                      </button>
                    }

                    {
                      booking.status === 'active' && <button className="btn btn-cancel small" onClick={() => openInvoice(booking)}>
                        <img src={print} title="View Invoice" />
                      </button>
                    }
                  </div>


                </div>
                <div className="booked-table-container">
                  <div class="booking-info-view">
                    {
                      booking.event_tickets && booking.event_tickets.length > 0 &&
                      booking.event_tickets.map((ticket, key) => {
                        return (
                          <div key={key} className={` ${ticket.cancelation_requested || ticket.cancel ? 'booking-info-row tr-del' : 'booking-info-row'} `}>
                            <div className="item">
                              <span>Ticket No.</span>
                              <label>#{ticket?.ticket_no || ''}</label>
                            </div>
                            <div className="item">
                              <span>First Name</span>
                              <label>{ticket?.first_name || ''}</label>
                            </div>
                            <div className="item">
                              <span>Last Name</span>
                              <label>{ticket?.last_name || ''}</label>
                            </div>
                            <div className="item">
                              <span>Email</span>
                              <label>{ticket?.email || ''}</label>
                            </div>
                            <div className="item">
                              <span>Phone</span>
                              <label>#{ticket?.phone || ''}</label>
                            </div>
                            <div className="item">
                              <span>Cancel</span>
                              <label>
                                {
                                  !ticket.cancelation_requested && ticket.status !== 'cancel' && <label className="toggle-radio">
                                    <input type="checkbox" name={`cancel-${k}-${key}`} onChange={(e) => setCancelTickets(k, key, e)} />
                                    <span>
                                      <p><i>Yes</i><i>No</i></p>
                                    </span>
                                  </label>
                                }

                                {
                                  ticket.cancelation_requested && ticket.status !== 'cancel' && <strong>Requested</strong>
                                }

                                {
                                  ticket.status === 'cancel' && <strong>Yes</strong>
                                }
                              </label>
                            </div>
                          </div>
                        );
                      })
                    }
                  </div>
                </div>

                {
                  event.paid_type === 'paid' &&
                  <div className="ticket-price-container">
                    <div className="ticket-price-details event-row">
                      <div className="ticket-heading">
                        <h4>{booking.event_tickets.length} Member Tickets Price</h4>
                      </div>
                      <div className="ticket-total">
                        <div>
                          <h4>{formatNumberToCurrency(booking?.ticket_price)} x {booking.event_tickets.length}:</h4>
                        </div>
                        <div className="ticket-price-side">
                          <h3>{formatNumberToCurrency(booking.total_price) || '$0'}</h3>
                        </div>
                      </div>
                    </div>
                    <div className="ticket-price-details event-row">
                      <div className="ticket-heading">
                        <h4></h4>
                      </div>
                      <div className="ticket-total">
                        <div>
                          <h4>Stripe Fee: </h4>
                        </div>
                        <div className="ticket-price-side">
                          <h3>{formatNumberToCurrency(booking.stripe_fees) || '$0'}</h3>
                        </div>
                      </div>
                    </div>
                    <div className="ticket-price-details event-row total-amount">
                      <div className="ticket-heading">
                        <h4></h4>
                      </div>
                      <div className="ticket-total">
                        <div >
                          <h4>Total Amount:</h4>
                        </div>
                        <div className="ticket-price-side">
                          <h3>{formatNumberToCurrency(booking.total_price) || '$0'}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>

            </div>
          </section>
        ))
      }

      <Popup
        open={login}
        onClose={() => {
          setLogIn(false);
        }}
        closeOnDocumentClick={true}
      >
        <Login
          closeLoginModal={(resp) => {
            if (resp) {
              registerTeam();
            }
            setLogIn(false);
          }}
          login={true}
          openSignUp={() => setSignUp(true)}
        ></Login>
      </Popup>
      <Popup
        open={signup}
        onClose={() => {
          setSignUp(false);
        }}
        type={'event'}
        eventId={eventId || 0}
        closeOnDocumentClick={false}
      >
        <Signup
          closeSignupModal={() => setSignUp(false)}
          openLogin={() => setLogIn(true)}
          type={'event-ticket'}
          registeredFor={'event-ticket'}
          eventId={eventId || 0}
        ></Signup>
      </Popup>

      <Popup
        open={openPayment}
        onClose={() => {
          setSignUp(false);
        }}
        type={'event'}
        eventId={eventId}
        closeOnDocumentClick={false}
        className={`stripe-popup stripePayment`}
      >
        <Payment showSuccessPopup={showSuccessMessage} event={event} payment={paymentAmounts} tickets={tickets} closeModal={() => setOpenPayment(false)} />
      </Popup>

      <WarningDialogue
        isOpen={openWarningPopup}
        header={popupContent.header}
        description={popupContent.description}
        action={popupContent.action}
        onClose={() => setOpenWarningPopup(false)}
        closeOnDocumentClick={true}
        success={popupContent.success}
        type={popupContent.type}
      />
    </>
  )
}
export default withRouter(EventDetails);