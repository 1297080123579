import React, { useState, useEffect, useMemo } from 'react';
import './signup.css';
import cross from '../../../assets/icons/cross-icon.svg';
import main from '../../../assets/images/mulberry-logo.svg';
import info from '../../../assets/images/info-icon.svg';
import upload from '../../../assets/images/upload-img.png';
import { Link, useLocation } from 'react-router-dom';
import axiosApi from '../../../core/interceptor/interceptor';
import { config } from '../../../config';
import { messages } from '../../../messages';
import InputMask from 'react-input-mask';
import { phoneNumberMask, maskingToPlainNumber, validateRequiredFields, validateEmail, REGISTERING_FOR, EVENT_OCCASION, EVENT_TYPE } from '../../../utils/Utils';
import ErrorInput from '../../common/ErrorInput';
import DatePicker from 'react-date-picker';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../store/reducer/LoggedInUserDetails';
import _ from 'lodash';
import CustomSelect from '../../../utils/CustomSelect';
import { REGISTERED_THROUGH } from '../../../config';
import ReCAPTCHA from "react-google-recaptcha";
import SimpleBarReact from 'simplebar-react';
import { MiniLoader } from '../../../utils/Loader';
import NumberFormat from 'react-number-format';
import DateTimePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import calIcon from "../../../assets/icons/myEvents.svg";
import moment from 'moment';
import FullCalendarPopup from '../FullCalendar/FullCalendarPopup';
import Popup from "reactjs-popup";
import { isValidPhoneNumber } from 'libphonenumber-js';

export default function Signup(props) {
	const REGISTERED_FOR = {
		sport: `League's Participation`,
		event: 'Organising Events'
	}
	const ticketBooking = props.type === 'event-ticket' ? true : false;
	const EVENT_SIGNUP_PROP = props && props.registeredFor === REGISTERING_FOR.EVENT ? true : false;
	const [tabCount, setTabCount] = useState(1);
	const img = <img src={upload} alt='upload-img' />;
	const [selectedFile, setSelectedFile] = useState('');
	const [formSubmitted, setFormSubmitted] = useState(false);
	const [showDisclaimer, setShowDisclaimer] = useState(false);
	const [errors, setErrors] = useState({
		email: '', password: '', confirmPassword: '', first_name: '', last_name: '', gender: '', birth_date: '', cell_phone: '', zip: '', shirt_size: '', disclaimer: '', profile_photo: '', captcha: '', phone_validation: '', registered_for: 'sports', title: '', budget: '', start_date_time: '', end_date_time: '', occasion: '', type: ''
	});
	const [emailExists, setEmailExist] = useState(false);
	const [stateList, setStateList] = useState('');
	const dispatch = useDispatch();
	const captchaSitekey = config.captchaSitekey;
	const [captcha, setCaptcha] = useState('');
	const [formData, setFormData] = useState({
		email: '',
		password: '',
		confirmPassword: '',
		first_name: '',
		middle_name: '',
		last_name: '',
		gender: 'male',
		birth_date: '',
		cell_phone: '',
		address: '',
		secondary_address: '',
		city: '',
		state_id: null,
		state_name: '',
		zip: '',
		shirt_size: null,
		// jersey:'',
		profile_photo: '',
		text_notification: false,
		email_notification: false,
		disclaimer: false,
		registered_for: props && props.registeredFor ? props.registeredFor : 'sport',
	});
	const [leadData, setLeadData] = useState({
		title: '',
		budget: '',
		occasion: '',
		start_date_time: '',
		end_date_time: '',
		type: '',
		status: 'open',
		user_id: 0
	})
	const [isPhoneValidated, setIsPhonevalidated] = useState(false);
	const [showChangeImage, setShowChangeImage] = useState(false);
	const [checkingEmail, setCheckingEmail] = useState(false);
	const [state, setState] = useState('');
	const search = useLocation().search;
	const invitePlayerId = new URLSearchParams(search).get('invitePlayerId');
	const [disclaimerData, setDisclaimerData] = useState([]);
	const [eventExists, setEventExists] = useState(false);
	const [openCalendar, setOpenCalendar] = useState(false);

	useEffect(() => {
		handleValidation();
		//eslint-disable-next-line
	}, [formData, emailExists, eventExists, captcha, formData.email, isPhoneValidated, leadData, leadData.start_date_time, leadData.end_date_time])

	useEffect(() => {
		if (props.signupEmail) {
			setFormData(prev => ({ ...prev, email: props.signupEmail }));
		}
	}, [props?.signupEmail]);

	useEffect(() => {
		getStateList();
		getDisclaimerInfo();
	}, []);

	const getDisclaimerInfo = () => {
		axiosApi.get(`${config.apiUrl}/misc/disclaimer/${REGISTERED_THROUGH}/signUp_disclaimer`)
			.then((res) => {
				setDisclaimerData(res.data.data);
			}, (err) => {

			})
	}


	useEffect(() => {
		const stateVal = stateList && stateList.filter(val => val.name.toLowerCase() === state.toLowerCase());
		setFormData((prev) => ({
			...prev,
			state_id: stateVal && stateVal[0]?.id,
			state_name: state,
		}))
		// eslint-disable-next-line
	}, [state])

	const getStateList = () => {
		axiosApi.get(`${config.apiUrl}/misc/states`)
			.then((res) => {
				setStateList(res.data.data);
			}, (err) => {
				console.error(err);
			})
	}

	const debouncedCheckEmailExistance = useMemo(
		() => _.debounce((email, id) => checkEmail(email, id), 1500),
		[],
	);

	const debounceCheckEventExistence = useMemo(
		() => _.debounce((start, end) => checkEvent(start, end), 1500),
		[],
	)

	const checkEmail = (email, id) => {
		setCheckingEmail(true);
		axiosApi.post(`${config.apiUrl}/misc/check-email-existance`, { email, id, user_type: 'admin' })
			.then((res) => {
				setEmailExist(!!res.data.data);
				setCheckingEmail(false);
			})
			.catch((err) => {
				console.error(err);
				setCheckingEmail(false);
			});
	}

	const checkEvent = (start, end) => {
		axiosApi.post(`${config.apiUrl}/misc/check-event-existence`, { start: moment(start).format(), end: moment(end).format() })
			.then((res) => {
				setEventExists(res?.data?.data);
			})
			.catch((err) => {

			})
	}

	useEffect(() => {
		if (validateEmail((formData.email || '')?.trim())) {
			debouncedCheckEmailExistance((formData.email || '')?.trim(), formData.id);
		}
		//eslint-disable-next-line
	}, [formData.email]);

	useEffect(() => {
		if (leadData.start_date_time && leadData.end_date_time) {
			debounceCheckEventExistence(leadData.start_date_time, leadData.end_date_time);
		}
		//eslint-disable-next-line
	}, [leadData.start_date_time, leadData.end_date_time]);

	const closeSignupModal = (resp = false) => {
		if (showDisclaimer) {
			setCaptcha('');
			setFormSubmitted(true);
			setShowDisclaimer(false);
		} else {
			if (props.closeSignupModal) {
				props.closeSignupModal(resp || false);
			}
		}
	}

	const onChange = (value) => {
		setCaptcha(value);
	}

	const handleSubmit = async (event) => {
		setFormSubmitted(true);
		event.preventDefault();
		if (handleValidation()) {
			let data = new FormData();
			data.append('email', formData.email?.trim());
			data.append('password', formData.password);
			data.append('first_name', formData.first_name?.trim());
			data.append('middle_name', formData.middle_name?.trim());
			data.append('last_name', formData.last_name?.trim());
			data.append('gender', (formData.gender === 'male' ? 'M' : 'F'));
			data.append('birth_date', formData.birth_date);
			data.append('cell_phone', maskingToPlainNumber(formData.cell_phone));
			data.append('address', formData.address?.trim());
			data.append('secondary_address', formData.secondary_address?.trim());
			data.append('city', formData.city?.trim());
			if (formData.state_id) {
				data.append('state_id', formData.state_id);
			}
			data.append('zip', formData.zip?.trim());
			data.append('shirt_size', formData.shirt_size);
			// data.append('jersey', formData.jersey?.trim());
			data.append('profile_photo', formData.profile_photo);
			data.append('text_notification', formData.text_notification);
			data.append('email_notification', formData.email_notification);
			data.append('registered_through', REGISTERED_THROUGH);
			data.append('captcha', captcha);
			if (selectedFile && selectedFile.name) {
				data.append("file", selectedFile, selectedFile.name);
			}
			data.append('registered_for', formData.registered_for);
			await axiosApi.post(`${config.apiUrl}/auth/register`, data)
				.then(async (res) => {
					if (res.data.token) {
						leadData.user_id = res.data.data.id;
						localStorage.setItem('token', res.data.token);
						await axiosApi.get(`${config.apiUrl}/user/me`)
							.then((res) => {
								dispatch(setUser(res.data.data));
							});
						if (invitePlayerId) {
							hasInvitation(res.data.data.id);
						}
						if (EVENT_SIGNUP_PROP || (formData.registered_for === REGISTERING_FOR.EVENT)) {
							createLead();
						}
					}
					props.closeSignupModal(false);
				})
		}
	}

	const hasInvitation = (id) => {
		axiosApi.get(`${config.apiUrl}/misc/invite-player/${invitePlayerId}`)
			.then((res) => {
				handleInvitation(res.data.data, formData.email, id);
			}, (err) => {
				console.error(err);
			})
	}

	const handleInvitation = (data, email, id) => {
		if (data && !data.status) {
			axiosApi.post(`${config.apiUrl}/misc/add-player/${data.id}`, {
				team_id: data.team_id,
				user_id: id,
				email: email,
				policies: true,
				covid_policies: true,
				registered_through: REGISTERED_THROUGH,
			}).then((res) => {
				console.log(res);
			})
		}
	}

	const handleFormChange = (e) => {
		setFormData((previousFormData) => ({
			...previousFormData,
			[e.target.name]: e.target.value,
		}))
	}

	/**
	 * 
	 * Input for Lead Data 
	 */
	const handleLeadDataChange = (e) => {
		setLeadData((prev) => ({
			...prev,
			[e.target.name]: e.target.value
		}))
	}

	const handleProfileChange = (e) => {
		if (e.target && e.target.files && e.target.files[0]) {
			var file = e.target.files[0];
			setSelectedFile(file);
			setFormData((previousFormData) => ({
				...previousFormData,
				profile_photo: e.target.files[0].name,
				upload_image: <img src={`${URL.createObjectURL(e.target.files[0])}`} alt='' />,
			}))
		}
	}

	const handleCheckBox = (e) => {
		setFormData((previousFormData) => ({
			...previousFormData,
			[e.target.name]: !formData[e.target.name],
		}))
	}

	const nextButtonHandler = (e) => {
		setFormSubmitted(true);
		if (handleValidation()) {
			setFormSubmitted(false);
			setTabCount(tabCount + 1)
		}
	}

	const handleValidation = (e) => {
		let errArray = {};
		let formIsValid = true;
		if (validateRequiredFields(formData)) {
			if (!formData.email?.trim()) {
				formIsValid = false;
				errArray.email = messages && messages.emptyEmail;
			}
			if (tabCount === 1 && !formData.password) {
				formIsValid = false;
				errArray.password = messages && messages.emptyPassword;
			}
			if (+tabCount === 1 && !formData.confirmPassword && (formData.registered_for === REGISTERING_FOR.SPORT)) {
				formIsValid = false;
				errArray.confirmPassword = messages && messages.emptyConfirmPassword;
			}
			if ((ticketBooking || +tabCount === 2) && !formData.first_name?.trim()) {
				formIsValid = false;
				errArray.first_name = messages && messages.firstName;
			}
			if ((ticketBooking || tabCount === 2) && !formData.last_name?.trim()) {
				formIsValid = false;
				errArray.last_name = messages && messages.lastName;
			}
			if (tabCount === 2 && !formData.birth_date && (formData.registered_for === REGISTERING_FOR.SPORT)) {
				formIsValid = false;
				errArray.birth_date = messages && messages.birthDate;
			}
			console.log(" ~ ticketBooking ~ ", ticketBooking);
			console.log(" ~ tabCount ~ ", tabCount);
			console.log(" ~ isValidPhoneNumber ~ ", isValidPhoneNumber(`+1${formData.cell_phone}`));
			if ((ticketBooking || tabCount === 2) && !formData.cell_phone) {
				formIsValid = false;
				errArray.cell_phone = messages && messages.cellPhone;
			} else if ((!ticketBooking && tabCount === 2) && formData.cell_phone && !isValidPhoneNumber(`+1${formData.cell_phone}`)) {
				formIsValid = false;
				errArray.cell_phone = messages && messages.cellPhoneInvalid;
			}
			
			if (tabCount === 3 && !formData.disclaimer && formData.registered_for === REGISTERING_FOR.SPORT) {
				formIsValid = false;
				errArray.disclaimer = messages && messages.disclaimer;
			}
		}
		if (tabCount === 1 && formData.email && !validateEmail(formData.email)) {
			setEmailExist(false);
			errArray.email = messages && messages.invalidEmail;
			formIsValid = false;
		}
		if (formData.password && (formData.password).length < 6) {
			errArray.password = messages && messages.passwordLength;
			formIsValid = false;
		}
		if (+tabCount === 1 && formData.confirmPassword && formData.confirmPassword !== formData.password) {
			formIsValid = false;
			errArray.confirmPassword = messages && messages.confirmPassword;
		}
		if (tabCount === 1 && !formData.registered_for) {
			formIsValid = false;
			errArray.registered_for = messages && messages.emptySelect;
		}
		if ((ticketBooking || tabCount === 2) && formData.cell_phone && formData.cell_phone.replace(/\D/g, '').length < 10) {
			formIsValid = false;
			errArray.cell_phone = messages && messages.phoneLength;
		}
		if (tabCount === 2 && !isPhoneValidated) {
			formIsValid = false;
			errArray.phone_validation = messages && messages.phoneValidation;
		}
		if (tabCount === 3 && selectedFile && !selectedFile.name.match(/\.(jpg|jpeg|png|gif|svg)$/)) {
			errArray.image = messages && messages.notAllowedImg;
			formIsValid = false;
		}
		if (tabCount === 3 && selectedFile && selectedFile.name.match(/\.(jpg|jpeg|png|gif|svg)$/) && selectedFile.size > 5 * 1024 * 1024) {
			errArray.image = messages && messages.imgLimit;
			formIsValid = false;
		}
		/** Lead validation */
		if (tabCount === 1 && !leadData.title && (EVENT_SIGNUP_PROP || (formData.registered_for === REGISTERING_FOR.EVENT))) {
			errArray.title = messages && messages.emptyField;
			formIsValid = false;
		}
		if (tabCount === 1 && leadData.budget === '' && (EVENT_SIGNUP_PROP || (formData.registered_for === REGISTERING_FOR.EVENT))) {
			errArray.budget = messages && messages.emptyField;
			formIsValid = false;
		}
		if (tabCount === 1 && leadData.budget !== '' && leadData.budget <= 0 && (EVENT_SIGNUP_PROP || (formData.registered_for === REGISTERING_FOR.EVENT))) {
			errArray.budget = messages && messages.budgetGreaterThanZero;
			formIsValid = false;
		}
		if (tabCount === 1 && !leadData.start_date_time && (EVENT_SIGNUP_PROP || (formData.registered_for === REGISTERING_FOR.EVENT))) {
			setEventExists(false);
			errArray.start_date_time = messages && messages.emptyField;
			formIsValid = false;
		}
		if (tabCount === 1 && !leadData.end_date_time && (EVENT_SIGNUP_PROP || (formData.registered_for === REGISTERING_FOR.EVENT))) {
			setEventExists(false);
			errArray.end_date_time = messages && messages.emptyField;
			formIsValid = false;
		}
		if (tabCount === 1 && leadData.start_date_time && leadData.end_date_time && (moment(leadData.start_date_time).format() >= moment(leadData.end_date_time).format()) && (EVENT_SIGNUP_PROP || (formData.registered_for === REGISTERING_FOR.EVENT))) {
			setEventExists(false);
			formIsValid = false;
			errArray.start_date_time = messages && messages.eventEndTimeErr;
		}
		if (tabCount === 1 && !leadData.type && (EVENT_SIGNUP_PROP || (formData.registered_for === REGISTERING_FOR.EVENT))) {
			errArray.type = messages && messages.emptySelect;
			formIsValid = false;
		}
		if (tabCount === 1 && !leadData.occasion && (EVENT_SIGNUP_PROP || (formData.registered_for === REGISTERING_FOR.EVENT))) {
			errArray.occasion = messages && messages.emptySelect;
			formIsValid = false;
		}
		if (emailExists) {
			formIsValid = false;
			errArray.email = messages && messages.email_taken;
		}
		if (eventExists) {
			formIsValid = false;
			errArray.start_date_time = messages && messages.eventExixts;
		}
		if (tabCount === 3 && (props && props.registeredFor !== REGISTERING_FOR.EVENT) && !captcha) {
			formIsValid = false;
			errArray.captcha = messages && messages.captchaSelect;
		}
		if ((ticketBooking || tabCount === 2) && (ticketBooking || EVENT_SIGNUP_PROP || (formData.registered_for === REGISTERING_FOR.EVENT)) && !captcha) {
			formIsValid = false;
			errArray.captcha = messages && messages.captchaSelect;
		}
		setErrors(errArray);
		return formIsValid;
	}

	const getPlace = (place) => {
		place?.address_components?.forEach(element => {
			if (element?.types.includes('postal_code')) {
				setFormData((prev) => ({
					...prev,
					zip: element.long_name,
				}))
			}
			if (element?.types.includes('administrative_area_level_1')) {
				setState(element.long_name);
			}
			if (element?.types.includes('administrative_area_level_2')) {
				setFormData((prev) => ({
					...prev,
					city: element.long_name,
				}))
			}
		});
		if (place.addressVal) {
			setFormData((prev) => ({
				...prev,
				address: place.addressVal,
			}))
		} else {
			setFormData((prev) => ({
				...prev,
				address: place,
			}))
		}
	}

	const getSecondaryAddress = (place) => {
		if (place.addressVal) {
			setFormData((prev) => ({
				...prev,
				secondary_address: place.addressVal,
			}));
		} else {
			setFormData((prev) => ({
				...prev,
				secondary_address: place,
			}));
		}
	}

	const createLead = () => {
		axiosApi.post(`${config.apiUrl}/leads/create`, leadData)
			.then((res) => {
				console.log(res?.data?.data);
			})
	}

	const getDateRange = (dateCallback) => {
		const start = dateCallback.start;
		const end = dateCallback.end;
		setLeadData((prev) => ({
			...prev,
			start_date_time: start,
			end_date_time: end,
		}))
	}

	const closeCalendar = (cb) => {
		setOpenCalendar(cb);
	}
	return (
		<>
			<form onSubmit={handleSubmit} encType="multipart/form-data">
				<div className={`login-popup${showDisclaimer ? ' termConditions-popup' : ''}`}>
					<div className="model-popup-box">
						<div className="modal-content">
							<div className="dismiss-popup">
								<div className="close" style={{ cursor: 'pointer' }} onClick={closeSignupModal}><img src={cross} alt="cross-sign" /></div>
							</div>
							<div className="model-popup-inner">
								<div className="pop-up-logo">
									<img src={main} alt="popup-logo" />
								</div>
								{!showDisclaimer ? <>
									<div className="breadcrumb-block">
										<ul>
											<li className={'active'} style={{ cursor: 'pointer' }}><span className='link' onClick={() => { setTabCount(1) }}>Login Info</span></li>
											<li className={tabCount === 2 || tabCount === 3 ? 'active' : ''} style={{ cursor: 'pointer' }}><span className='link' onClick={() => {
												tabCount === 1 ? nextButtonHandler() :
													setTabCount(2);
											}}>User Info</span></li>
											{(formData.registered_for === REGISTERING_FOR.SPORT) &&
												<li className={tabCount === 3 ? 'active' : ''} style={{ cursor: 'pointer' }}><span className='link' onClick={() => { nextButtonHandler() }}>Leagues Info</span></li>
											}
										</ul>
									</div>

									{tabCount === 1 && <>
										<div className="fleid-block-outer">
											<div className="fleid-block-outer">
												<div className="cs-row">
													{props && !props.registeredFor && !ticketBooking && <div className="c-12 half-blk z-ind-3">
														<div className="fleids-block">
															<CustomSelect
																required={true}
																label={'Registration For'}
																data={[
																	{
																		id: 'sport',
																		name: `League's Participation`
																	},
																	{
																		id: 'event',
																		name: 'Organising Events'
																	}
																]}
																placeholder={formData.registered_for ? REGISTERED_FOR[formData.registered_for] : 'Select'}
																onSelect={(e) => e && handleFormChange({
																	target: {
																		name: 'registered_for',
																		value: e.id,
																	}
																})}
																selector={'name'}
															/>
															<ErrorInput formSubmitted={formSubmitted} error={errors['registered_for']} />
														</div>
													</div>}
												</div>
											</div>
											{(EVENT_SIGNUP_PROP || (formData.registered_for === REGISTERING_FOR.EVENT)) && <div className='cs-row'>
												<div className="c-6">
													<div className="fleids-block">
														<label>Event Name <span className="required">*</span></label>
														<input type="text" className="form-control" name='title' value={leadData['title']} onChange={(e) => { handleLeadDataChange(e) }} autoComplete='off' />
														<ErrorInput formSubmitted={formSubmitted} error={errors['title']} />
													</div>
												</div>
												<div className="c-6 half-blk">
													<div className="fleids-block z-ind-2">
														<CustomSelect
															required={true}
															label={'Type'}
															data={[
																{
																	id: 'public',
																	name: `Public`
																},
																{
																	id: 'private',
																	name: 'Private'
																}
															]}
															placeholder={leadData.type ? EVENT_TYPE[leadData.type] : 'Select'}
															onSelect={(e) => e && handleLeadDataChange({
																target: {
																	name: 'type',
																	value: e.id,
																}
															})}
															selector={'name'}
														/>
														<ErrorInput formSubmitted={formSubmitted} error={errors['type']} />
													</div>
												</div>
												<div className="c-6">
													<div className="fleids-block">
														<div className="field-action">
															<img src={calIcon} alt="" />
														</div>
														<label>Start Date <span className="required">*</span></label>
														<DateTimePicker
															className="form-control"
															minDate={new Date()}
															selected={leadData?.start_date_time}
															onChange={(value) => {
																handleLeadDataChange({
																	target: {
																		value,
																		name: 'start_date_time',
																	}
																})
															}}
															showTimeSelect
															dateFormat="MMMM d, yyyy h:mm aa"
															timeIntervals={30}
														/>
														<ErrorInput formSubmitted={formSubmitted || eventExists} error={errors['start_date_time']} />
													</div>
												</div>
												<div className="c-6">
													<div className="fleids-block">
														<div className="field-action">
															<img src={calIcon} alt="" />
														</div>
														<label>End Date <span className="required">*</span></label>
														<DateTimePicker
															className="form-control"
															minDate={new Date()}
															selected={leadData?.end_date_time}
															onChange={(value) => {
																handleLeadDataChange({
																	target: {
																		value,
																		name: 'end_date_time',
																	}
																})
															}}
															showTimeSelect
															dateFormat="MMMM d, yyyy h:mm aa"
															timeIntervals={30}
														/>
														<ErrorInput formSubmitted={formSubmitted} error={errors['end_date_time']} />
													</div>
												</div>
												<div className="c-6 half-blk">
													<div className="fleids-block">
														<CustomSelect
															required={true}
															label={'Occasion'}
															data={EVENT_OCCASION}
															placeholder={leadData.occasion ? leadData.occasion : 'Select'}
															onSelect={(e) => e && handleLeadDataChange({
																target: {
																	name: 'occasion',
																	value: e.name,
																}
															})}
															selector={'name'}
														/>
														<ErrorInput formSubmitted={formSubmitted} error={errors['occasion']} />
													</div>
												</div>
												<div className="c-6">
													<div className="fleids-block">
														<label>Budget <span className="required">*</span></label>
														<NumberFormat
															placeholder=" "
															className="form-control"
															onValueChange={(e) => handleLeadDataChange({ target: { name: 'budget', value: e.floatValue } })}
															value={leadData?.budget}
															decimalScale={2}
															fixedDecimalScale={true}
															allowNegative={false}
															thousandsGroupStyle={['thousand']}
															thousandSeparator={true}
															disabled={false}
															prefix='$'
															name="budget"
															autoComplete="off"
														/>
														<ErrorInput formSubmitted={formSubmitted} error={errors['budget']} />
													</div>
												</div>
											</div>}
											<div className="fleids-block email-verification-block">
												<label>Email Address <span className="required">*</span></label>
												<div className="email-verification-wrapper">
													<input type="email" className="form-control" name='email' value={formData['email']} onChange={(e) => { handleFormChange(e) }} autoComplete='off' />
													{checkingEmail && <div className="spinner"><MiniLoader /></div>}
												</div>
												{(formSubmitted || errors["email"] === messages.email_taken) && <span className="error">{errors["email"]}</span>}
											</div>
											<div className="fleids-block">
												<label>Password <span className="required">*</span></label>
												<input type="password" className="form-control" name='password' value={formData['password']} onChange={(e) => { handleFormChange(e) }} autoComplete='off' />
												<ErrorInput formSubmitted={formSubmitted} error={errors['password']} />
											</div>
											{(formData.registered_for === REGISTERING_FOR.SPORT || formData.registered_for === REGISTERING_FOR.EVENT_TICKET) &&
												<div className="fleids-block">
													<label>Confirm Password <span className="required">*</span></label>
													<input type="password" className="form-control" name='confirmPassword' value={formData['confirmPassword']} onChange={(e) => { handleFormChange(e) }} autoComplete='off' />
													<ErrorInput formSubmitted={formSubmitted} error={errors['confirmPassword']} />
												</div>
											}
										</div>

										{
											ticketBooking &&
											<div className="cs-row">
												<div className="c-8">
													<div className="fleids-block">
														<label>First Name <span className="required">*</span></label>
														<input type="text" className="form-control" name='first_name' value={formData['first_name']} onChange={(e) => { handleFormChange(e) }} autoComplete='off' />
														<ErrorInput formSubmitted={formSubmitted} error={errors['first_name']} />
													</div>
												</div>
												<div className="c-4">
													<div className="fleids-block">
														<label>Middle Initial</label>
														<input type="text" className="form-control" name='middle_name' value={formData['middle_name']} onChange={(e) => { handleFormChange(e) }} autoComplete='off' />
													</div>
												</div>
												<div className="c-8">
													<div className="fleids-block">
														<label>Last Name <span className="required">*</span></label>
														<input type="text" className="form-control" name='last_name' value={formData['last_name']} onChange={(e) => { handleFormChange(e) }} autoComplete='off' />
														<ErrorInput formSubmitted={formSubmitted} error={errors['last_name']} />
													</div>
												</div>
												<div className="c-4">
													<div className="fleids-block">
														<label>Gender <span className="required">*</span></label>
														<div className="custom-select">
															<select className="form-control" name='gender' value={formData['gender']} onChange={(e) => { handleFormChange(e) }}>
																<option value="male">Male</option>
																<option value="female">Female</option>
															</select>
														</div>
													</div>
												</div>
												<div className="c-12">
													<div className="fleids-block">
														<label>Cell Phone <span className="required">*</span></label>
														<InputMask className="form-control" mask={phoneNumberMask} name="cell_phone" value={formData['cell_phone']} onChange={(e) => { handleFormChange(e) }} autoComplete={'off'} ></InputMask>
														<ErrorInput formSubmitted={formSubmitted} error={errors['cell_phone']} />
													</div>
												</div>
												<div className="fleids-block">
													<ReCAPTCHA sitekey={captchaSitekey} onChange={onChange} />
													<ErrorInput formSubmitted={formSubmitted} error={errors['captcha']} />
												</div>
												<div className="login-filed-btn user-info-btn">
													<button className=" btn btn-fluid btn-primary" type='submit'>Create Account</button>
												</div>
												
											</div>
										}

										{
											!ticketBooking &&
											<>
												<div className="btn-block action-modal">
													<button type='button' onClick={() => { nextButtonHandler() }} className="btn btn-fluid btn-primary">Next</button>
												</div>
												<div className="already-login">
													<span>Already have an account? <Link onMouseDown={() => { props.openLogin(); closeSignupModal(); }}>Login</Link></span>
												</div>
											</>
										}
									</>}

									{tabCount === 2 && <>
										<div className="fleid-block-outer">
											<div className="cs-row">
												<div className="c-8">
													<div className="fleids-block">
														<label>First Name <span className="required">*</span></label>
														<input type="text" className="form-control" name='first_name' value={formData['first_name']} onChange={(e) => { handleFormChange(e) }} autoComplete='off' />
														<ErrorInput formSubmitted={formSubmitted} error={errors['first_name']} />
													</div>
												</div>
												<div className="c-4">
													<div className="fleids-block">
														<label>Middle Initial</label>
														<input type="text" className="form-control" name='middle_name' value={formData['middle_name']} onChange={(e) => { handleFormChange(e) }} autoComplete='off' />
													</div>
												</div>
												<div className="c-8">
													<div className="fleids-block">
														<label>Last Name <span className="required">*</span></label>
														<input type="text" className="form-control" name='last_name' value={formData['last_name']} onChange={(e) => { handleFormChange(e) }} autoComplete='off' />
														<ErrorInput formSubmitted={formSubmitted} error={errors['last_name']} />
													</div>
												</div>
												<div className="c-4">
													<div className="fleids-block">
														<label>Gender <span className="required">*</span></label>
														<div className="custom-select">
															<select className="form-control" name='gender' value={formData['gender']} onChange={(e) => { handleFormChange(e) }}>
																<option value="male">Male</option>
																<option value="female">Female</option>
															</select>
														</div>
													</div>
												</div>
												{(formData.registered_for === REGISTERING_FOR.SPORT) &&
													<div className="c-12">
														<div className="fleids-block">
															<label>Birthday <span className="required">*</span></label>
															<DatePicker className="form-control" maxDate={new Date()} value={formData['birth_date']} onChange={(value) => {
																handleFormChange({
																	target: {
																		value,
																		name: 'birth_date',
																	}
																})
															}} />
															<ErrorInput formSubmitted={formSubmitted} error={errors['birth_date']} />
														</div>
													</div>
												}
												<div className="c-12">
													<div className="fleids-block">
														<label>Cell Phone <span className="required">*</span></label>
														<InputMask className="form-control" mask={phoneNumberMask} name="cell_phone" value={formData['cell_phone']} onChange={(e) => { handleFormChange(e) }} autoComplete={'off'} ></InputMask>
														<ErrorInput formSubmitted={formSubmitted} error={errors['cell_phone']} />
													</div>
												</div>
												{(formData.registered_for === REGISTERING_FOR.SPORT) &&
													<><div className="c-12">
														<div className="fleids-block">
															<label>Address</label>
															<input type="text" className="form-control" name='address' value={formData['address']} onChange={(e) => { handleFormChange(e) }} autoComplete='off' />
														</div>
													</div>
														<div className="c-12">
															<div className="fleids-block">
																<label>Secondary Address</label>
																<input type="text" className="form-control" name='secondary_address' value={formData['secondary_address']} onChange={(e) => { handleFormChange(e) }} autoComplete='off' />
															</div>
														</div>
														<div className="c-4 half-blk">
															<div className="fleids-block">
																<label>City</label>
																<input type="text" className="form-control" name='city' value={formData['city']} onChange={(e) => { handleFormChange(e) }} autoComplete='off' />
															</div>
														</div>
														<div className="c-4 half-blk">
															<div className="fleids-block">
																<CustomSelect
																	required={false}
																	label={'State'}
																	data={stateList}
																	placeholder={formData.state_name ? formData.state_name : 'Select State'}
																	onSelect={(e) => e && handleFormChange({
																		target: {
																			name: 'state_id',
																			value: formData.state_id ? formData.state_id : e.id,
																		}
																	})}
																	selector={'name'}
																/>
															</div>
														</div>
														<div className="c-4 half-blk">
															<div className="fleids-block">
																<label>Zip </label>
																<input type="text" className="form-control" name='zip' value={formData['zip']} onChange={(e) => { handleFormChange(e) }} autoComplete='off' />
															</div>
														</div></>
												}
											</div>
										</div>
										<div className="feature-check-block">
											<div className="checkbox-control">
												<label className="custom-checkbox">
													<input type="checkbox" checked={isPhoneValidated} name='phone_validation' onChange={(e) => setIsPhonevalidated(e.target.checked)} />
													<span>I have validated my cell phone number and it is correct.</span>
												</label>
												<ErrorInput formSubmitted={formSubmitted} error={errors['phone_validation']} />
											</div>
											{(EVENT_SIGNUP_PROP || (formData.registered_for === REGISTERING_FOR.EVENT)) &&
												<div className="fleids-block recapcha-box">
													<ReCAPTCHA sitekey={captchaSitekey} onChange={onChange} />
													<ErrorInput formSubmitted={formSubmitted} error={errors['captcha']} />
												</div>
											}
										</div>
										<div className="login-filed-btn user-info-btn">
											<button type='button' onClick={() => { setTabCount(tabCount - 1) }} className=" btn btn-gray">Back</button>
											<button type='button' onClick={(e) => { EVENT_SIGNUP_PROP ? handleSubmit(e) : formData.registered_for === REGISTERING_FOR.EVENT ? handleSubmit(e) : nextButtonHandler() }} className=" btn btn-primary">{EVENT_SIGNUP_PROP ? 'Create Account' : formData.registered_for === REGISTERING_FOR.EVENT ? 'Create Account' : 'Next'}</button>
										</div>
									</>}
									{tabCount === 3 && props && props.registeredFor !== REGISTERING_FOR.EVENT && <>
										<div className="fleid-block-outer">
											<div className="cs-row">
												<div className="c-8">
													{formData.registered_for === REGISTERING_FOR.SPORT && <div className="fleids-block">
														<label>Shirt Size</label>
														<div className="custom-select">
															<select className="form-control" name='shirt_size' value={formData['shirt_size']} onChange={(e) => { handleFormChange(e) }}>
																<option value='null'>Select Shirt Size</option>
																<option value='S'>S</option>
																<option value='M'>M</option>
																<option value='L'>L</option>
																<option value='XL'>XL</option>
																<option value='XXL'>XXL</option>
															</select>
														</div>
													</div>}
												</div>
												<div className="c-12">
													<div className="fleids-block">
														<label>Profile Photo</label>
														<div className="fild-upload-outer">
															<div className="upload-img" onMouseEnter={() => setShowChangeImage(true)} onMouseLeave={() => setShowChangeImage(false)}>
																<input id='profile_pic' type="file" name='profile_photo' onChange={(e) => { handleProfileChange(e) }} accept="image/*" />
																<span className="error">{formSubmitted && errors["email"]}</span>
																{!formData.upload_image ? <div className="attachment-blk"><figure>
																	{img}
																</figure>
																	<p>Upload A Photo</p>
																</div>
																	: <div className={`uploaded-Imgblk${showChangeImage ? ' active' : ''} `}>
																		{(showChangeImage) &&
																			<div>
																				<label for='profile_pic'>
																					<img src={upload} alt="upload" />
																				</label>
																			</div>}
																		{formData.upload_image}</div>}
																<ErrorInput formSubmitted={formSubmitted} error={errors['profile_photo']} />
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="feature-check-block">
												<label className="custom-checkbox">
													<input type="checkbox" name='text_notification' checked={formData['text_notification']} onChange={(e) => { handleCheckBox(e) }} />
													<span>Receive text messages alerts, updates and announcements <img src={info} alt="info" /></span>
												</label>
												<label className="custom-checkbox">
													<input type="checkbox" name='email_notification' checked={formData['email_notification']} onChange={(e) => { handleCheckBox(e) }} />
													<span>Receive email news, updates and announcements</span>
												</label>
												{formData.registered_for === REGISTERING_FOR.SPORT && <div className="checkbox-control">
													<label className="custom-checkbox">
														<input type="checkbox" name='disclaimer' checked={formData['disclaimer']} onChange={(e) => { handleCheckBox(e) }} />
														<span>I have read and fully understand the <span onClick={() => setShowDisclaimer(true)} target="_blank" rel="noreferrer noopener"><span className='link'>Player Disclaimer</span></span></span>
													</label>
													<ErrorInput formSubmitted={formSubmitted} error={errors['disclaimer']} />
												</div>}
											</div>
											<div className="fleids-block">
												<ReCAPTCHA sitekey={captchaSitekey} onChange={onChange} />
												<ErrorInput formSubmitted={formSubmitted} error={errors['captcha']} />
											</div>
										</div>
										<div className="login-filed-btn user-info-btn">
											<button type='button' onClick={() => { setTabCount(tabCount - 1) }} className=" btn btn-gray">Back</button>
											<button className=" btn" type='submit'>Create Account</button>
										</div>
									</>}
								</> : <>
									<div className="termConditions-blk">
										<h3>Player Disclaimer</h3>
										<SimpleBarReact className="scroll-wrapper">
											<p dangerouslySetInnerHTML={{ __html: disclaimerData.disclaimer }}></p>

										</SimpleBarReact>
										<button className="btn" type="button" onClick={(e) => { e.preventDefault(); setCaptcha(''); setShowDisclaimer(false); }}>
											Back
										</button>
									</div>
								</>}
							</div>
						</div>
					</div>
				</div>
			</form>
			<Popup className="ns-popup full-cal-popup" open={openCalendar} closeOnDocumentClick={false}>
				<FullCalendarPopup dateRange={getDateRange} onClose={closeCalendar} />
			</Popup>
		</>
	);
}