import React from "react"
import { Route } from 'react-router';
import "../Account.css";
import MyTeams from "./MyTeams";
import TeamDetails from "./TeamDetails";

export default function TeamsLayout(props) {
    return <>
        <Route exact={true} path={`${props.match.path}/teams`}>
            <MyTeams {...props} />
        </Route>
        <Route exact={true} path={`${props.match.path}/teams/:id`}>
            <TeamDetails {...props} />
        </Route>
    </>
}
