import React, { useEffect, useState } from "react";
import "./Account.css";
import { useHistory, useLocation } from "react-router-dom";
import myTeamIcon from "../../assets/icons/myTeam.svg";
import eventsIcon from "../../assets/icons/myEvents.svg";
import myBookingsIcon from "../../assets/icons/my-bookings.svg";
import profileIcon from "../../assets/icons/profile.svg";
import teamInvitesIcon from '../../assets/icons/teamInvites.svg'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import beachBallIcon from "../../assets/icons/beach-ball.png";
import { useDispatch } from 'react-redux';
import { setUser } from '../../store/reducer/LoggedInUserDetails';
import bellicon from '../../assets/icons/bell.svg';
const menuItems = [
  {
    label: 'Upcoming Matches',
    icon: beachBallIcon,
    url: 'upcoming-matches',
  },
  {
    label: 'My Teams',
    icon: myTeamIcon,
    url: 'teams'
  },
  {
    label: 'Team Invites',
    icon: teamInvitesIcon,
    url: 'team-invites'
  },
  {
    label: 'My Classifieds',
    icon: teamInvitesIcon,
    url: 'my-classifieds',
  },
  {
    label: 'League History',
    icon: beachBallIcon,
    url: 'league-history'
  },
  {
    label: 'My Profile',
    icon: profileIcon,
    url: 'profile'
  },
  {
    label: 'My Events',
    icon: eventsIcon,
    url: 'events'
  },
  {
    label: 'My Bookings',
    icon: myBookingsIcon,
    url: 'bookings'
  },
  // {
  //   label: 'My Events',
  //   icon: eventsIcon,
  //   url: 'events'
  // },
]

export default function AccountMenuSidebar(props) {
  const dispatch = useDispatch();
  const search = useLocation();
  const [menu, setMenu] = useState(menuItems)
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(-1);
  const history = useHistory();
  const [hide, setHide] = useState(false);

  const logout = () => {
    localStorage.clear();
    dispatch(setUser(null));
    history.push("/");
  };

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname && location.pathname.indexOf('/my-account') !== -1) {
        setHide(false);
      } else {
        setHide(true);
        if (window.innerWidth && window.innerWidth > 992) {
          setSelectedMenuIndex(0);
        } else {
          setSelectedMenuIndex(-1);
        }
      }
    })
  }, [history]);

  useEffect(() => {
    if (history?.location?.pathname && history?.location?.pathname.indexOf('/my-account') !== -1) {
      setHide(false);
    } else {
      setHide(true);
    }
    if (window.innerWidth && window.innerWidth > 992) {
      setSelectedMenuIndex(0);
    }
    // eslint-disable-next-line
  }, [])
  return (
    <>
      <section className={`gen-sidebar${props?.open ? ' open' : hide ? ' hide-menu' : ''}`}>
        <div className="gen-sidebar-container">
          <div className="gen-sb-header">
            <h3>My Account</h3>
            <span className="close" onClick={() => props?.close(false)}>
              &times;
            </span>
          </div>
          <div className="gen-sb-body">
            <ul className="gen-sb-nav-links">
              {
                menu.map((m, index) => (
                  <li key={`account-sidebar-${index}`} onClick={() => {
                    setSelectedMenuIndex(index);
                    history.push(`/my-account/${m.url}`);
                    if (props && props.close) {
                      props?.close(false);
                    } 
                  }} className={`sb-nav-link${(search?.pathname.split('/')).includes(m.url) ? ' active' : ''}`}>
                    <div className="sb-icon">
                      <img src={m.icon} alt="" />
                    </div>
                    <p className={`sb-text ${props.isNewDoc && m.url === 'events' ? 'notify-dot' : ''}`}>
                      {m.label}  
                      {props.isNewDoc && m.url === 'events' ? <img className="bell-icon" src={bellicon} alt='' /> : ''}  
                    </p>
                    {/* can also add class dot-pri to make dot pri color */}
                  </li>
                ))
              }
            </ul>
          </div>
          <div className="gen-sidebar-footer">
            <button onClick={() => {logout(); props?.close && props?.close(false);}} className="btn">Sign Out</button>
          </div>
        </div>
      </section>
      <div className={`sidebar-overlay ${props?.open ? ' open' : ''}`} onClick={() => props?.close(false)}></div>
    </>
  );
}
