import Popup from "reactjs-popup";
import cross from '../assets/icons/cross-icon.svg';
import checked from '../assets/images/success-icon.png';

export default function WarningDialogue(props) {
  const paymentType = 'payment_success'
  return (
    <>
      <Popup closeOnDocumentClick={props.backdrop || false} open={props.isOpen}>
        <div className={`login-popup modal warning-popup ${props?.type === paymentType ? 'payment-success' : ''}`}>
          <div className="model-popup-box">
            <div className="modal-content">
              <div className="dismiss-popup">
                {props && props.type  && <div className="close" onClick={() => props.onClose && props.onClose(false)}><img src={cross} alt="cross-sign" /></div>}
              </div>
              <div className="model-popup-inner">
                <div className="modal-header">
                  {
                    props?.type === paymentType ? 
                      <div>
                        <figure> <img src={checked} alt="success" /></figure>
                        <h2>{props.header}</h2>
                      </div>
                    : 
                    <h2>{props.header}</h2>
                  }
                </div>
                <div className="modal-body">
                  <div className="warning-message-field">
                    <p>{props.description}</p>
                  </div>
                </div>
                {props?.type !== 'session' && <div className={`modal-footer d-flex ${props.success ? 'success' : ''}`}>
                  {
                    !props.success ?
                      <>
                        <button type="button" className="btn-gray" onClick={() => props.onClose && props.onClose(false)}>Cancel</button>
                        <button className="btn" onClick={() => props.action && props.action(props.data ? props.data : null)}>Confirm</button>
                      </>
                      :
                      <button type="button" className="btn-gray" onClick={() => props.onClose && props.onClose(false)}>Ok</button>
                  }

                </div>}
                {props?.type === 'session' &&
                  <div className="modal-footer d-flex session-out">
                    <button type="button" className="btn-gray" onClick={() => props.action && props.action(props.data ? props.data : null)}>OK</button>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </Popup>
    </>
  );
}