import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: false,
  status: 'idle',
};

export const loaderSlice = createSlice({
  name: 'loading',
  initialState,

  reducers: {
    setLoader: (state, action) => {
        state.value = action.payload;
    },
  },
});

export const { setLoader } = loaderSlice.actions;

export const loader = (state) => state.loading.value;

export default loaderSlice.reducer;