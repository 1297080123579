import moment from "moment";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Popup from "reactjs-popup";
import { config } from "../../../config";
import axiosApi from "../../../core/interceptor/interceptor";
import { capitaLizeString } from "../../../utils/Utils";
import WarningDialogue from "../../../utils/WarningDialogue";
import '../../league/League.css';
import CreateClassified from "./AddClassifieds";

function Classifieds() {
    const [openWarningPopup, setOpenWarningPopup] = useState(false);
    const [popupContent, setPopupContent] = useState({});
    const [create, setCreate] = useState(false);
    const [classifieds, setClassifieds] = useState([]);
    useEffect(() => {
        getClassifieds();
    }, []);

    const closeCreateClassified = () => {
        setCreate(false);
        getClassifieds();
    }

    const getClassifieds = () => {
        axiosApi.get(`${config.apiUrl}/classifieds`)
            .then((res) => {
                if (res.data.data) {
                    setClassifieds([...res.data.data]);
                }
            })
    }

    const deleteClassified = (id) => {
        setOpenWarningPopup(true);
        setPopupContent({
            header: 'Delete Classified?',
            description: 'Are you sure, you want to delete this classified?',
            action: () => {
                axiosApi.delete(`${config.apiUrl}/classifieds/${id}`)
                    .then(() => {
                        setOpenWarningPopup(false);
                        getClassifieds();
                    }).catch(() => {
                        setOpenWarningPopup(false);
                    })
            }
        });
    }

    return <>
        <div className="acc-top-bar with-btn">
            <div className="ns-page-title"><h3>CLASSIFIEDS</h3></div>
            <div className="breadcrumbs">
                <button className="btn" onClick={(e) => {
                    e.preventDefault();
                    setCreate(true);
                }}>Add Classified</button>
            </div>
        </div>
        <div className="acc-main-container">
            <div className="event-list-page with-new-cols classfied-cards">
                <div className="ev-row-cards">
                    {
                        Array.isArray(classifieds) && classifieds.length
                            ? classifieds.map((classified) => (<div className="ev-row-card">
                                <div className="ev-info-side">
                                    <div className="ev-text">
                                        <h3>Title</h3>
                                        <p>{classified.title || 'N/A'}</p>
                                    </div>
                                    <div className="ev-more-info">
                                        <p className="class-card-desc">{classified.description || 'N/A'}</p>
                                        <h3>Description</h3>
                                    </div>
                                    <div className="ev-more-info sm-col">
                                        <p>{moment(classified.created_at).format("D/M/YYYY")}</p>
                                        <h3>Posted</h3>
                                    </div>
                                    <div className="ev-more-info sm-col">
                                        <p>{capitaLizeString(classified?.status) || 'N/A'}</p>
                                        <h3>Status</h3>
                                    </div>
                                </div>
                                <div className="ev-card-right">
                                    <div className="ev-card-actions">
                                        <button className="btn btn-gray" onClick={(e) => {
                                            e.preventDefault();
                                            deleteClassified(classified.id);
                                        }}>
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>))
                            : <div className="noRecord-found">
                                <p>No Record Found</p>
                            </div>}
                </div>
            </div>
            <WarningDialogue
                isOpen={openWarningPopup}
                header={popupContent.header}
                description={popupContent.description}
                action={popupContent.action}
                onClose={() => setOpenWarningPopup(false)}
                closeOnDocumentClick={true}
            />
            <Popup className="createClassified-popup ns-popup" open={create} onClose={closeCreateClassified} closeOnDocumentClick={true}>
                <CreateClassified closeCreateClassified={() => closeCreateClassified()} onClose={() => { setCreate(o => !o) }}></CreateClassified>
            </Popup>
        </div>
    </>
}
export default withRouter(Classifieds);