import React, { useEffect, useState } from "react"
import { useHistory } from 'react-router-dom';
import { config } from "../../../../config";
import axiosApi from "../../../../core/interceptor/interceptor";

export default function TeamInvites({ match }) {
    const [invites, setInvites] = useState([]);
    const history = useHistory();
    useEffect(() => {
        axiosApi.get(`${config.apiUrl}/team/invitations/invitation_through`)
            .then((res) => {
                if (res.data && res.data.data && res.data.data.length) {
                    const invites = res.data.data.map((invitation, index) => (<div className="ev-row-card team-inv-card">
                        <div className="ev-info-side">
                            <div className="ev-text">
                                <h3>{invitation.team.name}</h3>
                                <p>League: {invitation.team.league.name}</p>
                                <p>Members: {invitation.team.players.length}</p>
                            </div>
                        </div>
                        <div className="ev-card-right">
                            <div className="ev-text">
                                <p></p>
                            </div>
                            <div className="ev-card-actions">
                                <button className="btn btn-primary" onClick={(e) => history.push(`${match.path}/team-invites/${invitation.id}`)}>
                                    View Invite
                                </button>
                            </div>
                        </div>
                    </div>));
                    setInvites(invites);
                }
            }, (err) => {
                console.error(err);
            })
            // eslint-disable-next-line
    }, []);

    return <>
        <div className="acc-top-bar with-btn">
            <div className="ns-page-title"><h3>TEAM INVITES</h3></div>
        </div>
        <div className="acc-main-container">
            <div className="event-list-page">
                <div className={`ev-row-cards`}>
                    {
                        Array.isArray(invites) && invites.length
                            ? invites
                            : <div className="noRecord-found">
                                <p>No Record Found</p>
                            </div>
                    }
                </div>
            </div>
        </div>
    </>
}
