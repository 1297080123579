import React, { useState, useEffect, useMemo } from "react";
import { config } from "../../../config";
import axiosApi from "../../../core/interceptor/interceptor";
import { messages } from "../../../messages"
import ErrorInput from "../../common/ErrorInput";
import { useDispatch, useSelector } from 'react-redux';
import { setUser, userDetails } from '../../../store/reducer/LoggedInUserDetails';
import CustomSelect from "../../../utils/CustomSelect";
import uploadImg from "../../../assets/images/upload-img.png";
import "./ModifyAccount.css";
import { maskingToPlainNumber, phoneNumberMask, validateEmail } from "../../../utils/Utils";
import InputMask from 'react-input-mask';
import { MiniLoader } from "../../../utils/Loader";
import _ from 'lodash';
import { isValidPhoneNumber } from "libphonenumber-js";

export default function ModifyAccount() {

	const dispatch = useDispatch();
	const user = useSelector(userDetails);
	const [userDetail, setuserDetail] = useState({
		first_name: user?.first_name,
		last_name: user?.last_name,
		middle_name: user?.middle_name,
		email: user?.email,
		cell_phone: user?.cell_phone,
		profile_photo: user?.profile_photo,
		shirt_size: user?.shirt_size,
		address: user?.address,
		city: user?.city,
		state_id: user?.state_id,
		zip: user?.zip,
		email_notification: user?.email_notification,
		text_notification: user?.text_notification,
		state_name: '',
		id: user?.id,
	});

	const [uploadImage, setUploadImage] = useState(userDetail && userDetail.profile_photo && <img src={userDetail.is_old ? userDetail.profile_photo : `${config.api}/images/profile/${userDetail.profile_photo}`} alt={`${userDetail.profile_photo}`} />);
	const [selectedFile, setSelectedFile] = useState('');
	const [formSubmitted, setFormSubmitted] = useState(false);
	const [errors, setErrors] = useState({
		first_name: '', last_name: '', email: '', shirt_size: '', zip: ''
	});
	const [stateList, setStateList] = useState('');
	const [showChangeImage, setShowChangeImage] = useState(false);
	const [checkingEmail, setCheckingEmail] = useState(false);
	const [emailExists, setEmailExist] = useState(false);

	const debouncedCheckEmailExistance = useMemo(
		() => _.debounce((email, id) => checkEmail(email, id), 1500),
		[],
	);
	const [state, setState] = useState('');

	useEffect(() => {
		setuserDetail({
			first_name: user?.first_name,
			last_name: user?.last_name,
			middle_name: user?.middle_name,
			email: user?.email,
			cell_phone: user?.cell_phone,
			profile_photo: user?.profile_photo,
			shirt_size: user?.shirt_size,
			address: user?.address,
			city: user?.city,
			state_id: user?.state_id,
			zip: user?.zip,
			email_notification: user?.email_notification,
			text_notification: user?.text_notification,
			state_name: '',
			id: user?.id,
		});
		setUploadImage(user?.profile_photo && <img src={user?.is_old ? user?.profile_photo : `${config.api}/images/profile/${user?.profile_photo}`} alt={`${user?.profile_photo}`} />);
		getStateList();
		// eslint-disable-next-line
	}, [user]);

	useEffect(() => {
		if (validateEmail((userDetail?.email || '').trim())) {
			debouncedCheckEmailExistance((userDetail?.email || '').trim(), userDetail?.id);
		}
		//eslint-disable-next-line
	}, [userDetail?.email]);

	useEffect(() => {
		handleValidation();
		//eslint-disable-next-line
	}, [userDetail, emailExists])


	useEffect(() => {
		const stateVal = stateList && stateList.filter(val => val.name.toLowerCase() === state.toLowerCase());
		setuserDetail((prev) => ({
			...prev,
			state_id: stateVal && stateVal.length && stateVal[0].id,
			state_name: state,
		}))
		// eslint-disable-next-line
	}, [state])

	const getStateList = () => {
		axiosApi.get(`${config.apiUrl}/misc/states`)
			.then((res) => {
				if (res && res.data && res.data.data) {
					setStateList(res.data.data);
					const stateName = res.data.data.find(el => el?.id === user?.state_id);
					setuserDetail((prev) => ({
						...prev,
						state_name: stateName?.name
					}))
				}
			}, (err) => {
				console.error(err);
			})
	}
	const handleFormChange = (e) => {
		setuserDetail((previousFormData) => ({
			...previousFormData,
			[e.target.name]: e.target.value,
		}))
	}

	const handleProfileChange = (e) => {
		if (e.target && e.target.files && e.target.files[0]) {
			var file = e.target.files[0];
			setSelectedFile(file);
			setuserDetail((previousFormData) => ({
				...previousFormData,
				profile_photo: e.target.files[0].name,
			}))
			setUploadImage(<img src={`${URL.createObjectURL(e.target.files[0])}`} alt='' />);
		}
	}

	const handleCheckBox = (e) => {
		setuserDetail((previousFormData) => ({
			...previousFormData,
			[e.target.name]: !userDetail[e.target.name],
		}))
	}

	const handleSubmit = () => {
		setFormSubmitted(true);
		if (handleValidation()) {
			let data = new FormData();
			data.append('email', userDetail.email?.trim());
			data.append('first_name', userDetail.first_name?.trim());
			data.append('middle_name', userDetail.middle_name?.trim());
			data.append('last_name', userDetail.last_name?.trim());
			data.append('cell_phone', maskingToPlainNumber(userDetail.cell_phone));
			data.append('address', userDetail.address?.trim());
			data.append('city', userDetail.city?.trim());
			if (userDetail.state_id) {
				data.append('state_id', userDetail.state_id);
			}
			data.append('zip', userDetail.zip?.trim());
			data.append('shirt_size', userDetail.shirt_size);
			if (userDetail.profile_photo !== null && userDetail.profile_photo !== '') {
				data.append('profile_photo', userDetail.profile_photo);
			}
			data.append('text_notification', userDetail.text_notification);
			data.append('email_notification', userDetail.email_notification);
			if (selectedFile && selectedFile.name) {
				data.append("file", selectedFile, selectedFile.name);
			}
			axiosApi.put(`${config.apiUrl}/user/me`, data)
				.then((res) => {
					axiosApi.get(`${config.apiUrl}/user/me`)
						.then((res) => {
							dispatch(setUser(res.data.data));
						})
				})
		}
	}

	const handleValidation = (e) => {
		let errArray = {};
		let formIsValid = true;

		if (!userDetail['email']?.trim()) {
			formIsValid = false;
			errArray['email'] = messages && messages.emptyEmail;
		} else {
			if (!validateEmail(userDetail['email'])) {
				formIsValid = false;
				errArray['email'] = messages && messages.invalidEmail;
			} else if (emailExists) {
				formIsValid = false;
				errArray['email'] = messages?.email_taken;
			}
		}
		if (!userDetail['first_name']?.trim()) {
			formIsValid = false;
			errArray['first_name'] = messages && messages.firstName;
		}
		if (!userDetail['last_name']?.trim()) {
			formIsValid = false;
			errArray['last_name'] = messages && messages.lastName;
		}
		if (!userDetail['cell_phone']) {
			formIsValid = false;
			errArray['cell_phone'] = messages && messages.cellPhone;
		} else if ((userDetail['cell_phone'] || '').trim() && !isValidPhoneNumber(`+1${userDetail['cell_phone']}`)) {
			formIsValid = false;
			errArray['cell_phone'] = messages && messages.cellPhoneInvalid;
		}
		if (userDetail.cell_phone && userDetail.cell_phone.replace(/\D/g, '').length < 10) {
			formIsValid = false;
			errArray['cell_phone'] = messages && messages.phoneLength;
		}
		if (selectedFile && !selectedFile.name.match(/\.(jpg|jpeg|png|gif|svg)$/)) {
			errArray["profile_photo"] = messages && messages.notAllowedImg;
			formIsValid = false;
		}
		if (selectedFile && selectedFile.name.match(/\.(jpg|jpeg|png|gif|svg)$/) && selectedFile.size > 10 * 1024 * 1024) {
			errArray["profile_photo"] = messages && messages.imgLimit;
			formIsValid = false;
		}
		setErrors(errArray);
		return formIsValid;
	}

	const getPlace = (place) => {
		place?.address_components?.forEach(element => {
			if (element?.types.includes('postal_code')) {
				setuserDetail((prev) => ({
					...prev,
					zip: element.long_name,
				}))
			}
			if (element?.types.includes('administrative_area_level_2')) {
				setuserDetail((prev) => ({
					...prev,
					city: element.long_name,
				}))
			}
			if (element?.types.includes('administrative_area_level_1')) {
				setState(element.long_name);
			}
		});
		if (place.addressVal) {
			setuserDetail((prev) => ({
				...prev,
				address: place.addressVal,
			}))
		} else {
			setuserDetail((prev) => ({
				...prev,
				address: place,
			}))
		}
	}

	const checkEmail = (email, id = null) => {
		setCheckingEmail(true);
		axiosApi.post(`${config.apiUrl}/misc/check-email-existance`, { email, id })
			.then((res) => {
				setEmailExist(!!res.data.data);
				setCheckingEmail(false);
			})
			.catch((err) => {
				console.error(err);
				setCheckingEmail(false);
			});
	}

	return <>
		<div className="acc-top-bar with-btn">
			<div className="ns-page-title common-header-ui">
				<h3>MY PROFILE</h3>
				<div className="breadcrumbs">
					<button className="btn"onClick={() => handleSubmit()}>Save</button>
				</div>
			</div>
		</div>
		<section className="account-setting-block" style={{paddingTop: '0px'}}>
			<div className="my-account-inner">
				<div className="matches-updates">
					<form className="rs-form" encType="multipart/form-data" autoComplete='off'>
						<div className="about-setting">
							<div className="about-setting-inner">
								<p>Profile Photo</p>
							</div>
							<div className="player-info">
								<div className="img-blk">{uploadImage}</div>
								<div className="upload-img" onMouseEnter={() => setShowChangeImage(true)} onMouseLeave={() => setShowChangeImage(false)}>
									<input id="profile_photo" type="file" name='profile_photo' onChange={(e) => { handleProfileChange(e) }} accept="image/*" />
									{
										(showChangeImage || !uploadImage) &&
										<label for="profile_photo">
											<img src={uploadImg} alt='profile_photo' />
										</label>
									}
								</div>
								<ErrorInput formSubmitted={formSubmitted} error={errors['profile_photo']} />
							</div>
						</div>
						<div className="fleid-block-outer">
							<div className="cs-row">
								<div className="c-4 cs-6">
									<div className="fleids-block">
										<label>First Name <span className="required">*</span></label>
										<input className="form-control" type="text" name="first_name" value={userDetail?.first_name} onChange={(e) => handleFormChange(e)} autoComplete='off' />
										<ErrorInput formSubmitted={formSubmitted} error={errors['first_name']} />
									</div>
								</div>
								<div className="c-4 cs-6">
									<div className="fleids-block">
										<label>Middle Name</label>
										<input className="form-control" type="text" name="middle_name" value={userDetail?.middle_name} onChange={(e) => handleFormChange(e)} />
									</div>
								</div>
								<div className="c-4 cs-6">
									<div className="fleids-block">
										<label>Last Name <span className="required">*</span></label>
										<input className="form-control" type="text" name="last_name" value={userDetail?.last_name} onChange={(e) => handleFormChange(e)} />
										<ErrorInput formSubmitted={formSubmitted} error={errors['last_name']} />
									</div>
								</div>
								<div className="c-4 cs-6">
									<div className="fleids-block email-verification-block">
										<label>Email Address <span className="required">*</span></label>
										<div className="email-verification-wrapper">
											<input className="form-control" type="email" name="email" value={userDetail?.email} onChange={(e) => handleFormChange(e)} />
											{checkingEmail && <div className="spinner"><MiniLoader /></div>}
										</div>
										<ErrorInput formSubmitted={emailExists || formSubmitted} error={errors['email']} />
									</div>
								</div>
								<div className="c-4 cs-6">
									<div className="fleids-block">
										<label>Phone Number <span className="required">*</span></label>
										<InputMask className="form-control" mask={phoneNumberMask} name="cell_phone" value={userDetail?.cell_phone} onChange={(e) => { handleFormChange(e) }} ></InputMask>
										<ErrorInput formSubmitted={formSubmitted} error={errors['cell_phone']} />
									</div>
								</div>
								<div className="c-4 cs-6">
									<div className="fleids-block">
										<label>Shirt Size</label>
										<div className="custom-select">
											<select className="form-control" name='shirt_size' value={userDetail?.shirt_size} onChange={(e) => { handleFormChange(e) }}>
												<option value='null'>Select Shirt Size</option>
												<option value='S'>S</option>
												<option value='M'>M</option>
												<option value='L'>L</option>
												<option value='XL'>XL</option>
												<option value='XXL'>XXL</option>
											</select>
										</div>
									</div>
								</div>
								<div className="c-12 cs-6">
									<div className="fleids-block">
										<label>Address</label>
										<input className="form-control" type="text" name="address" value={userDetail?.address} onChange={(e) => handleFormChange(e)} />
									</div>
								</div>
								<div className="c-4 cs-6">
									<div className="fleids-block">
										<label>City</label>
										<input className="form-control" type="text" name="city" value={userDetail?.city} onChange={(e) => handleFormChange(e)} />
									</div>
								</div>
								<div className="c-4 cs-6">
									<CustomSelect
										required={false}
										label={'State'}
										data={stateList}
										placeholder={userDetail.state_name ? userDetail.state_name : 'Select State'}
										onSelect={(e) => e && handleFormChange({
											target: {
												name: 'state_id',
												value: e.id,
											}
										})}
										selector={'name'}
									/>
								</div>
								<div className="c-4 cs-6">
									<div className="fleids-block">
										<label>Zip</label>
										<input className="form-control" type="text" name="zip" value={userDetail?.zip} onChange={(e) => handleFormChange(e)} />
									</div>
								</div>
								<div className="c-4 cs-6">
									<div className="feature-check-block account-checked">
										<label className="custom-checkbox">
											<input type="checkbox" name='email_notification' checked={userDetail?.email_notification} onChange={(e) => { handleCheckBox(e) }} />
											<span>Email Updates</span>
										</label>
									</div>
								</div>
								<div className="c-4 cs-6">
									<div className="feature-check-block account-checked">
										<label className="custom-checkbox">
											<input type="checkbox" name='text_notification' checked={userDetail?.text_notification} onChange={(e) => { handleCheckBox(e) }} />
											<span>Text Updates</span>
										</label>
									</div>
								</div>
								{/* <div className="login-filed-btn user-info-btn update-btn">
									<button type="button" onClick={() => handleSubmit()} className=" btn">Save</button>
								</div> */}
							</div>
						</div>
					</form>
				</div>
			</div>
		</section>
	</>
}