import React, { useEffect, useState } from "react";
import "./League.css";
import { Link, Route, useHistory, withRouter, useParams } from "react-router-dom";
import axiosApi from "../../core/interceptor/interceptor";
import {
  config,
} from "../../config";
import { toTitleCase } from "../../utils/Utils";
import { useSelector } from "react-redux";
import beachBallIcon from "../../assets/icons/beach-ball.png";
import LeagueList from './list/list'
import gamesBtnIcon from "../../assets/icons/games-btn-img.svg";
import { sportsListDetails } from "../../store/reducer/SportsList";

function League(props) {
  // const id = useParams().id;
  const params = useParams()

  const [id, setId] = useState(0);
  const [sports, setSports] = useState([]);
  const [type, setType] = useState('');
  const loader = useSelector((state) => state.loader.value);
  const history = useHistory();
  const [toggleClass, setToggleClass] = useState(false);
  const sportsList = useSelector(sportsListDetails);

  useEffect(() => {
    if (sportsList && props.match.params.type !== type ) {
      
      setSports(sportsList[props.match.params.type]);
      
      if (sportsList[props.match.params.type].length) {
        let index = sportsList[props.match.params.type].findIndex(x => +x.id === +props.match.params.id);
        index = index === -1 ? 0 : index;
        setId(sportsList[props.match.params.type][index]['id']);
      } else {
        setId(0);
      }
      setType(props.match.params.type);
    }
  }, [props, sportsList])

  useEffect(() => {
    if (id > 0) {
      history.push(`/league/team/${id}/${type}`);
    }
  }, [id])

  const handleSportSideBarToggle = (cb) => {
    setToggleClass(cb);
  }

  /******** Sports with Open Leagues ********/
  const getSportsLeagues = () => {
    axiosApi
      .get(`${config.apiUrl}/leagues/sports/open-leagues`)
      .then((response) => {
        if (response && response.data && response.data.data) {
          const data = response.data.data;
          if (data.length) {
            props?.match?.params?.id && data.find(x => +x.id === +props?.match?.params?.id) ? setId(props?.match?.params?.id) : setId(data[0].id);
          }
          setSports(data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleSportClick = (id) => {
    setId(id);
    setToggleClass(false)
  }

  return (
    <>
      <main className="wrapper-main">
      <div className={`games-sidebar-overlay ${toggleClass ? 'show': ''}`} onClick={()=> setToggleClass(false)}></div>
        <section className={`sidebar-section ${toggleClass ? 'show-games-section' : ''}`}>
        <div className="games-filter-btn" onClick={()=> setToggleClass((prev) => !prev)}>
            <img src={gamesBtnIcon} alt="" />
          </div>
          <div className="sidebar-contents">
            <div className="sidebar-header">
              <h2>Available Sports</h2>
              <span className="close-btn" onClick={()=> setToggleClass(false)}>&times;</span>
            </div>
            <div className="sidebar-body">
              <ul className="sidebar-items">
                {
                  sports.length ?
                    sports.map((sport, i) => {
                      return <li className={`sidebar-item ${+id === +sport?.id ? 'active' : ''}`} onClick={() => handleSportClick(sport?.id)} key={i}>
                        {/* <div className="sidebar-item-icon">
                          <img className={sport.title === 'Kickball' && sport?.icon ? `cs-kick-img` : ''} src={sport?.icon ? `${config.api}/${config.imageFolder}/sports/${sport.icon}` : beachBallIcon} alt="" />
                        </div> */}
                        <div className="sidebar-item-text">
                          <h3 className="sidebar-item-title">{toTitleCase(sport?.title)}</h3>
                          {/* <p className="sidebar-item-more-info">{sport?.leagueCount || 0} <span>open Registration{(sport?.leagueCount || 0) > 1 ? 's' : ''}</span></p> */}
                        </div>
                      </li>
                    })
                    :
                    !loader && <div className="noRecord-found no-leagues-found">
                      <p>No Leagues Available</p>
                    </div>
                }
              </ul>
            </div>
          </div>
        </section>
        <Route exact={true} path={`${props.match.path}`}>
          <LeagueList  showSportList={handleSportSideBarToggle}/>
        </Route>
      </main>
    </>
  );
}
export default withRouter(League);