import React, { useEffect, useState } from 'react';
import './header.css';
import { Link, NavLink, useLocation, useHistory } from 'react-router-dom';
import logo from '../../../assets/images/logo.svg';
import { Popup } from 'reactjs-popup';
import Login from '../login/Login';
import Signup from '../signup/signup';
import { useSelector, useDispatch } from 'react-redux';
import { userDetails } from '../../../store/reducer/LoggedInUserDetails';
import { config, Disclaimers, REGISTERED_THROUGH } from '../../../config';
import "./header.css";
import defaultImage from "../../../assets/images/user-default-image.png";
import axiosApi from '../../../core/interceptor/interceptor';
import { setSports } from '../../../store/reducer/SportsList';
import { isMobile } from "react-device-detect";

export default function Header(props) {
  const dispatch = useDispatch();
  const search = useLocation().search;
  const history = useHistory();
  const path = useLocation().pathname;
  const [login, setLoginOpen] = useState(new URLSearchParams(search).get('login') ? new URLSearchParams(search).get('login') : false);
  const [signup, setSignupOpen] = useState(new URLSearchParams(search).get('signup') ? new URLSearchParams(search).get('signup') : false);
  const [toggle, setToggle] = useState(false);
  const [notificationBanner, setNotificationBanner] = useState(null);
  const [toggleNavClass, setToggleNavClass] = useState(false);
  const loader = useSelector((state) => state.loader.value);
  const [activeTeam, setActiveTeam] = useState(false);
  const [sportList, setSportList] = useState([]);

  /**** Close Login Modal ****/
  const closeLoginModal = (resp = false) => {
    setLoginOpen(false);
  };

  /**** Close Signup Modal ****/
  const closeSignupModal = (resp = false) => {
    setSignupOpen(false);
  };

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const pathChangeEmit = (path) => {
    props.pathChange(path);
  }

  useEffect(() => {
    getNotificationBanner();
    const interval = setInterval(() => {
      getNotificationBanner();
    }, 3600000);

    getSportsIds();
    return () => {
      clearInterval(interval);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname.includes('/league/team/')) {
        setActiveTeam(true);
      } else {
        setActiveTeam(false);
      }
      // console.log(`You changed the page to: ${location.pathname}`) 
    })
  }, [history])


  const getSportsIds = () => {
    axiosApi.get(`${config.apiUrl}/misc/get-sport-id`)
      .then((res) => {
        if (res.data.data) {
          dispatch(setSports(res.data.data));
          setSportList(res.data.data);
        }
      })
  }

  const getNotificationBanner = () => {
    axiosApi.get(`${config.apiUrl}/misc/disclaimer/${REGISTERED_THROUGH}/${Disclaimers.notification_banner}`)
      .then((res) => {
        if (res && res.data && res.data.data) {
          res.data.data.disclaimer = res.data.data.disclaimer ? res.data.data.disclaimer.replace(/\n/g, '') : '';
          if (res.data.data.disclaimer) {
            props.isHeaderNotification(true);
          } else {
            props.isHeaderNotification(false);
          }
          setNotificationBanner(res.data.data.disclaimer);
        }
      })
  }

  const loggedInUserDetails = useSelector(userDetails);

  return (
    <>
      <header className={`active ${notificationBanner && 'show-notification'} ${path === '/events' ? '' : path !== '/' ? 'active' : ''} ${toggle && 'nav-active'}`}>
        <div className="top-notification">
          {
            notificationBanner &&
            <marquee behavior="scroll" direction="left" scrollamount="7"><p dangerouslySetInnerHTML={{ __html: notificationBanner }}></p></marquee>
          }
        </div>
        <div className="container-fluid">
          <div className="navbar-brand">
            <Link to="/" onClick={() => {
              pathChangeEmit('/');
            }
            }>
              <img src={logo} alt="Mulberry" />
            </Link>
          </div>
          <div className="navbar-nav">
            <nav>
              <ul>
                <li>
                  <NavLink exact={true} activeClassName="active" to='/' onClick={() => {
                    setToggle(false);
                    pathChangeEmit('/')
                  }}>Home</NavLink>
                </li>
                {/* <li>
                  <NavLink exact={true} activeClassName="active" to='' onClick={() => {
                    setToggle(false);
                    pathChangeEmit('/')
                  }}>Menu</NavLink>
                </li> */}
                <li className={`has-child ${toggleNavClass ? 'show-sub' : ''}`} onClick={() => setToggleNavClass(prev => !prev)}>
                  <span exact={true} to=''><p>Leagues</p></span>
                  <ul className='sub-nav-menu'>
                  { sportList?.active && sportList?.active.length > 0 ? (
                      <li>
                        {
                          isMobile ? (
                            <NavLink className={`sub-nav-link`} to={`/league/sports/active`} onClick={() => setToggle(false)}>
                              Active Leagues
                            </NavLink>
                          ) : (
                            <NavLink className={`sub-nav-link`} to={`/league/team/${sportList?.active[0].id}/active`} onClick={() => setToggle(false)}>Active Leagues</NavLink>
                          )
                        }
                      </li>
                      
                      ) : (
                        <li>
                          <NavLink className={`sub-nav-link`} to={`/league/team/0/active`} onClick={() => setToggle(false)}>
                            Active Leagues
                          </NavLink>
                        </li>
                      )}
                    {
                      sportList?.archived && sportList?.archived.length > 0 &&
                      (
                        <li>
                          {isMobile ? (
                            <NavLink className={`sub-nav-link`} to={`/league/sports/archived`} onClick={() => setToggle(false)}>
                            Archived Leagues
                            </NavLink>
                          ) : (
                            <NavLink className={`sub-nav-link`} to={`/league/team/${sportList?.archived[0].id}/archived`} onClick={() => setToggle(false)}>
                              Archived Leagues
                            </NavLink>
                          )}
                        </li>
                      )
                    }

                    {
                      sportList?.sign_up && sportList?.sign_up.length > 0 ?
                        (
                          <li>
                            {
                              isMobile ? (
                                <NavLink className={`sub-nav-link`} to={`/league/sports/sign_up`} onClick={() => setToggle(false)}>
                            Registration
                            </NavLink>
                              ) : (
                                <NavLink className={`sub-nav-link`} to={`/league/team/${sportList?.sign_up[0].id}/sign_up`} onClick={() => setToggle(false)}>
                                  Registration
                                </NavLink>
                              )
                            }
                          </li>
                        )
                        :
                        <li><NavLink className={`sub-nav-link`} to={`/league/team/0/sign_up`} onClick={() => setToggle(false)}>Registration</NavLink></li>
                    }
                  </ul>
                </li>
                <li>
                  <NavLink exact={true} activeClassName="active" to='/events' onClick={() => {
                    setToggle(false);
                    pathChangeEmit('/events')
                  }}>Events</NavLink>
                </li>
                <li>
                  <NavLink exact={true} activeClassName="active" to='/contact' onClick={() => {
                    setToggle(false);
                    pathChangeEmit('/contact')
                  }}>Contact</NavLink>
                </li>
              </ul>
            </nav>
            {(!loggedInUserDetails && !loader) ? <div className="btn-block">
              <button className="btn btn-secondary" onClick={() => setLoginOpen(o => !o)}>Login</button>
              <button className="btn" onClick={() => setSignupOpen(o => !o)}>Sign Up</button>
            </div> :
              (
                <div className="login-profile">
                  <div className="login-prsofile-inner" style={{
                    width: loggedInUserDetails ? 'auto' : 310,
                  }}>
                    <div className="login-profile-name">
                      {
                        loggedInUserDetails &&
                        <>
                          <span>{
                            function () {
                              const hr = new Date().getHours();
                              if (hr >= 0 && hr < 12) {
                                return 'Good Morning';
                              } else if (hr >= 12 && hr <= 17) {
                                return 'Good Afternoon';
                              } else {
                                return 'Good Evening';
                              }
                            }()
                          }, {loggedInUserDetails ? loggedInUserDetails.first_name : 'N/A'}</span>
                          <Link to='/my-account'>My Account</Link>
                        </>
                      }
                    </div>
                    {
                      loggedInUserDetails &&
                      <div className="login-person">
                        {
                          loggedInUserDetails?.profile_photo
                            ? <img src={`${config.api}/${config.imageFolder}/profile/${loggedInUserDetails?.profile_photo}`} alt={loggedInUserDetails?.profile_photo} />
                            : <img src={defaultImage} alt='upload' />
                        }
                      </div>
                    }
                  </div>
                </div>
              )
            }
          </div>
          <div className="navbar-right">
            <div className="login-profile" onClick={() => props.openSidebar()} >
              <div className="login-prsofile-inner">
                <div className='mob-user-info'>
                  {
                    loggedInUserDetails &&
                    <>
                      <div className='welcome-user'>
                        <span className='mob-hide'>{
                          function () {
                            const hr = new Date().getHours();
                            if (hr >= 0 && hr < 12) {
                              return 'Good Morning';
                            } else if (hr >= 12 && hr <= 17) {
                              return 'Good Afternoon';
                            } else {
                              return 'Good Evening';
                            }
                          }()
                        },</span>
                        <span className="mobile-msg">Hi, </span>
                        {loggedInUserDetails ? loggedInUserDetails.first_name : 'N/A'}
                      </div>
                      <span className='bl-color' onClick={() => props.openMenu()}> My Account </span>
                      {/* <Link to='/my-account' onClick={() => pathChangeEmit('/my-account')}>My Account</Link> */}
                    </>
                  }
                </div>
                {
                  loggedInUserDetails &&
                  <div className="login-person">
                    <span onClick={() => props.openMenu()}>
                      {
                        loggedInUserDetails?.profile_photo
                          ? <img src={`${config.api}/${config.imageFolder}/profile/${loggedInUserDetails?.profile_photo}`} alt={loggedInUserDetails?.profile_photo} />
                          : <img src={defaultImage} alt='upload' />
                      }
                    </span>
                  </div>
                }
              </div>
            </div>
            <button className="nav-hamburger" onClick={() => handleToggle()}>
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
      </header>
      <Popup open={login} onClose={closeLoginModal} closeOnDocumentClick={true}>
        <Login closeLoginModal={() => closeLoginModal()} login={true} openSignUp={() => setSignupOpen(true)}></Login>
      </Popup>
      <Popup open={signup} onClose={closeSignupModal} closeOnDocumentClick={false}>
        <Signup closeSignupModal={() => closeSignupModal()} openLogin={() => setLoginOpen(true)}></Signup>
      </Popup>
    </>
  );
}