import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { config } from "../../../config";
import axiosApi from "../../../core/interceptor/interceptor";
import { messages } from "../../../messages";
import { validateRequiredFields } from "../../../utils/Utils";
import ErrorInput from "../../common/ErrorInput";
import "./ResetPassword.css";
import { REGISTERED_THROUGH } from "../../../config";
import { withRouter } from 'react-router-dom';

function ResetPassword(props) {

    const [details, setDetails] = useState({
        password: '',
        confirmPassword: '',
        token: ''
    });
    const[formSubmitted, setFormSubmitted] = useState(false);
    details.token = useParams().token;
    const[errors, setErrors] = useState({
        password: '',
        confirmPassword: ''
    })
    const history = useHistory();

    useEffect(() => {
        validateToken();
        //eslint-disable-next-line
    },[]);

    useEffect(() => {
        handleValidation();
        //eslint-disable-next-line
    }, [details.password , details.confirmPassword]);


    const validateToken = () => {
        axiosApi.put(`${config.apiUrl}/auth/validate-token/${details.token}`)
        .then((res)=>{
            console.log(res);
        }, (err) => {
            setTimeout(() => {
                history.push('/');
            }, 2000);
        })
        
    }
    const handleValidation = () => {
        const errArray = {};
        let isFormValid = true;
        if (validateRequiredFields(details)) {
            if (!details.password) {
                errArray.password = messages && messages.emptyPassword;
                isFormValid = false;
            }
        setErrors(errArray);
        }
        if (details.password && details.password.length < 6) {
            errArray.password = messages && messages.passwordLength;
            isFormValid = false;
          }
        if (!details.confirmPassword || (details.confirmPassword && details.confirmPassword !== details.password)) {
            isFormValid = false;
            errArray.confirmPassword = messages && messages.confirmPassword;    
        }
        setErrors(errArray);
        return isFormValid;
    }

    const handleSubmit = () => {
        setFormSubmitted(true);
        if(handleValidation()){
            axiosApi.post(`${config.apiUrl}/auth/reset-password`,{
                password: details.password,
                confirm_password: details.confirmPassword,
                token: details.token,
                registered_through: REGISTERED_THROUGH
            }).then((res) => {
               history.push('/', {status: 'login'});
            })
        }
    }
    return<>
        <section className="resetPassword-outer">
            <div className="cs-container">
                <div className="heading-blk">
                    <h2>Reset Password</h2>
                </div>
                <div className="cs-row">
                    <div className="c-12">
                        <div className="input-blk">
                            <label>Password</label>
                            <input className="form-control" type="password" name='password' onChange={(e) => setDetails(prev => ({ ...prev, password: e.target.value }))}/>
                            <ErrorInput formSubmitted={formSubmitted} error={errors.password} />
                        </div>
                        <div className="input-blk">
                            <label>Confirm Password</label>
                            <input className="form-control" type="password" name='confirmPassword' onChange={(e) => setDetails(prev => ({ ...prev, confirmPassword: e.target.value }))}/>
                            <ErrorInput formSubmitted={formSubmitted} error={errors.confirmPassword} />
                        </div>
                        <button className="btn" onClick={()=> handleSubmit()}>Submit</button>
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default withRouter(ResetPassword);
