import React, { useState, useEffect } from 'react';
import './Login.css';
import cross from '../../../assets/icons/cross-icon.svg';
import main from '../../../assets/images/mulberry-logo.svg';
import axiosApi from '../../../core/interceptor/interceptor';
import { messages } from '../../../messages';
import { config } from '../../../config';
import ErrorInput from '../../common/ErrorInput';
import { validateEmail, validateRequiredFields } from '../../../utils/Utils';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../store/reducer/LoggedInUserDetails';
import { REGISTERED_THROUGH } from '../../../config';
import { Link } from 'react-router-dom';

export default function Login(props) {
  const [loginDetails, setLoginDetails] = useState({ email: '', password: '', });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [forgetPassword, setForgetPassword] = useState(false);
  const [errors, setErrors] = useState({ email: '', password: '' });
  const dispatch = useDispatch();

  useEffect(() => {
    handleValidation();
    //eslint-disable-next-line
  }, [loginDetails['email'], loginDetails['password']]);

  useEffect(() => {
    if(props.loginEmail) {
      setLoginDetails({email: props.loginEmail});
    }
  }, [props?.loginEmail]);

  const handleValidation = () => {
    const errArray = {};
    let isFormValid = true;
    if (validateRequiredFields(loginDetails)) {
      if (!loginDetails.email?.trim()) {
        errArray.email = messages && messages.emptyEmail;
        isFormValid = false;
      }
      if (!forgetPassword && !loginDetails.password) {
        errArray.password = messages && messages.emptyPassword;
        isFormValid = false;
      }
      setErrors(errArray);
    }
    // if (loginDetails.password && loginDetails.password.length < 6) {
    //   errArray.password = messages && messages.passwordLength;
    //   isFormValid = false;
    // }
    if (loginDetails.email && !validateEmail(loginDetails.email?.trim())) {
      errArray.email = messages && messages.invalidEmail;
      isFormValid = false;
    }
    setErrors(errArray);
    return isFormValid;
  }

  const handleFormChange = (e) => {
    setLoginDetails((previousFormData) => ({
      ...previousFormData,
      [e.target.name]: e.target.value,
    }))
  }

  const loginHandler = () => {
    setFormSubmitted(true);
    if (handleValidation()) {
      axiosApi.post(`${config.apiUrl}/auth/login`, {
        email: loginDetails['email']?.trim(),
        password: loginDetails['password'],
        registered_through: REGISTERED_THROUGH
      }).then((res) => {
        localStorage.setItem('token', res.data.token);
        axiosApi.get(`${config.apiUrl}/user/me`)
          .then((res) => {
            dispatch(setUser(res.data.data));
          })
        props.closeLoginModal(true);
      }, (err) => {
        console.error(err);
      });
    }
  }

  const forgetPasswordHandler = () => {
    
    setFormSubmitted(true);
    if (handleValidation()) {
      axiosApi.post(`${config.apiUrl}/auth/forgot-password`, {
        email: loginDetails['email'],
        registered_through: REGISTERED_THROUGH,
      })
        .then((res) => {
          setForgetPassword(false);
          props.closeLoginModal(false);
        })
    }
  }

  const closeLoginModal = (resp = false) => {
    if (props.closeLoginModal) {
      props.closeLoginModal(resp || false);
    }
  }

  return (
    <>
      <div className={`login-popup${forgetPassword ? ' forgot-popup' : ''}`}>
        <div className="model-popup-box">
          <div className="modal-content">
            <div className="dismiss-popup">
              <div className="close" style={{ cursor: 'pointer' }} onClick={() => closeLoginModal(false)}><img src={cross} alt="cross-sign" /></div>
            </div>
            <div className="model-popup-inner">
              <div className="pop-up-logo loginLogo">
                <img src={main} alt="popup-logo" />
              </div>
              <div className="field-block-outer">
                <div className="field-block">
                  <label>Email</label>
                  <input type="email" value={loginDetails.email} className="form-control" name='email' onChange={(e) => handleFormChange(e)} autoComplete='off' />
                  <ErrorInput formSubmitted={formSubmitted} error={errors['email']} />
                </div>
                {!forgetPassword && <>
                  <div className="field-block">
                    <label>Password</label>
                    <input type="password" className="form-control" name='password' onChange={(e) => handleFormChange(e)} autoComplete='off' />
                    <ErrorInput formSubmitted={formSubmitted} error={errors['password']} />
                  </div>
                  <span className="forget-pass">
                    <span className='link' onClick={() => {
                      setForgetPassword(true);
                      setFormSubmitted(false);
                    }}>Forgot Password?</span>
                  </span></>}
              </div>
              <div className="login-filed-btn">
                {!forgetPassword && <button type="button" className="btn btn-gray" onMouseDown={() => { closeLoginModal(); props.openSignUp(); }}>Sign up</button>}
                {!forgetPassword && <button className="btn btn-primary" onMouseDown={() => { loginHandler() }}>Login</button>}
                {forgetPassword && <button type="button" className="btn btn-primary forgot-btn" onMouseDown={() => { forgetPasswordHandler() }}>Forgot Password</button>}
              </div>
              {forgetPassword && <div className="already-login">
                 <span>Back to <Link onClick={() => {
                  setForgetPassword(false);
                  setFormSubmitted(false)
                }}>Login</Link>?</span>
              </div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}