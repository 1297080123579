import React, { useEffect, useState, useMemo } from "react";
import { config, REGISTERED_THROUGH } from "../../../config";
import axiosApi from "../../../core/interceptor/interceptor";
import { messages } from "../../../messages";
import ErrorInput from "../../common/ErrorInput";
import "./Team.css";
import visa from "../../../assets/images/visa-img.png";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setUser,
  userDetails,
} from "../../../store/reducer/LoggedInUserDetails";
import Popup from "reactjs-popup";
import Checkout from "../Checkout/Checkout";
import { toast } from "react-toast";
import Policy from "../../common/Policy/Policy";
import _ from "lodash";
import { PaymentTeamCreate } from "../../../utils/Utils";
import { useHistory, withRouter } from "react-router";
import backArrowGreen from "../../../assets/icons/backArrowGreen.svg";
import moment from 'moment';

function Team(props) {
  const sportId = useParams().sportId;
  const dispatch = useDispatch();
  const [checkout, setCheckoutOpen] = useState(false);
  const loggedInUserDetails = useSelector(userDetails);
  const leagueId = useParams().id;
  const [leagueData, setLeagueData] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [policy, setPolicy] = useState({ open: false, type: "" });
  const [checkingName, setCheckingName] = useState(false);
  const [duplicateName, setDuplicateName] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    preferred_time: "early",
    custom_option: null,
    additional_request: ''
  });
  const [errors, setErrors] = useState({
    name: "",
    policies_team_register: "",
    email_phone_validation: "",
  });
  const [policyData, setPolicyData] = useState({
    policies: false,
    covid_policies: false,
  });
  const [emailPhoneValidation, setEmailPhoneValidation] = useState(false);
  const [selectedTypePayment, setSelectedTypePayment] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (leagueId) {
      axiosApi
        .get(`${config.apiUrl}/leagues/league-details/${leagueId}`)
        .then((response) => {
          if (response && response.data && response.data.data) {
            setLeagueData(response.data.data);
            const leage_data =
              response.data.data &&
              Array.isArray(response.data.data.teams) &&
              response.data.data.teams.length >= response.data.data.team_size;
            if (leage_data) {
              setSelectedTypePayment(PaymentTeamCreate.save_card);
            }
          }
        });
      handleValidation();
    }
    //eslint-disable-next-line
  }, [leagueId]);

  useEffect(() => {
    handleValidation();
    //eslint-disable-next-line
  }, [
    policyData.policies,
    policyData.covid_policies,
    formData.name,
    emailPhoneValidation,
  ]);

  useEffect(() => {
    getUserDetails();
    //eslint-disable-next-line
  }, []);

  const handleFormChange = (e) => {
    setFormData((previousFormData) => ({
      ...previousFormData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleValidation = () => {
    let errArray = {};
    let formIsValid = true;
    if (!formData["name"]?.trim()) {
      formIsValid = false;
      errArray["name"] = messages && messages.teamName;
    } else if(formData['name'].length > 150) {
      formIsValid = false;
      errArray['name'] = messages && messages.maxlength;
    }
    if (!policyData.policies || !policyData.covid_policies) {
      formIsValid = false;
      errArray["policies_team_register"] = messages && messages.selectPolicies;
    }

    if (duplicateName) {
      // formIsValid = false;
      errArray["name"] = messages && messages.duplicatename;
    }
    if (!emailPhoneValidation) {
      formIsValid = false;
      errArray["email_phone_validation"] =
        messages && messages.emailPhoneValidation;
    }
    setErrors(errArray);
    return formIsValid;
  };

  const handleCheckBox = (e) => {
    setPolicyData((previousPolicyData) => ({
      ...previousPolicyData,
      [e.target.name]: !policyData[e.target.name],
    }));
  };

  const openCheckoutModal = (payment_type) => {
    // Set flag for add card only
    if (payment_type) {
      setFormData((previousFormData) => ({
        ...previousFormData,
        payment_type,
      }));
    }

    setFormSubmitted(true);
    if (handleValidation()) {
      if (!policyData.policies) {
        return toast.error("Accept policies");
      }

      if (!policyData.covid_policies) {
        return toast.error("Accept covid policies");
      }

      if (policyData.policies && policyData.covid_policies) {
        setCheckoutOpen(true);
      }
    }
  };

  const getUserDetails = () => {
    if (localStorage.getItem("token")) {
      axiosApi.get(`${config.apiUrl}/user/me`).then((res) => {
        dispatch(setUser(res.data.data));
      });
    }
  };

  const debouncedCheckEmailExistance = useMemo(
    () => _.debounce((name, id) => checkDuplicateTeamName(name, id), 600),
    // eslint-disable-next-line
    []
  );

  // Check duplicate team name
  const checkDuplicateTeamName = (name, league_id) => {
    if (league_id) {
      const postData = {
        league_id: league_id,
        name: name,
      };
      if (name) {
        setCheckingName(true);
        axiosApi
          .post(`${config.apiUrl}/misc/check-team-duplicate`, postData)
          .then((res) => {
            setCheckingName(false);
            if (res.data && res.data.data) {
              setDuplicateName(true);
              setTimeout(() => {
                const errArray = [];
                errArray["name"] = messages && messages.duplicatename;
                setErrors(errArray);
                setFormSubmitted(true);
              }, 200);
            } else {
              setDuplicateName(false);
              setTimeout(() => {
                const errArray = [];
                errArray["name"] = "";
                setErrors(errArray);
                setFormSubmitted(false);
              }, 200);
            }
          })
          .catch((error) => {
            setDuplicateName(false);
          });
      }
    }
  };

  useEffect(() => {
    if ((formData.name || "").trim()) {
      if (leagueData && leagueData.id) {
        debouncedCheckEmailExistance(formData.name, leagueData.id);
      }
    }
    // eslint-disable-next-line
  }, [formData.name, leagueData]);

  const saveTeam = () => {
    setFormSubmitted(true);
    if (handleValidation()) {
      if (policyData.policies && policyData.covid_policies) {
        axiosApi
          .post(
            `${config.apiUrl}/leagues/create-checkout-session/${leagueData.id}`,
            {
              ...formData,
              registered_through: REGISTERED_THROUGH,
              email: loggedInUserDetails?.email,
              payment_type: selectedTypePayment,
              current_date: moment().format('MM-DD-YYYY')
            }
          )
          .then((response) => {
            history.push(`/my-account/teams/${response.data.data.id}`);
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            setFormSubmitted(false);
          });
      }
    }
  };

  return (
    <>
      <section className="main-side no-sidebar">
        <div className="main-side-container">
          <div className="main-top-headings pb-0">
            <div className="league-back-link">
              <Link
                to={
                  props?.location?.state?.from === "list"
                    ? `/league/${sportId}`
                    : `/league/league-details/${leagueId}/${sportId}`
                }
              >
                <img src={backArrowGreen} alt="" />
                Back to League Info
              </Link>
            </div>
          </div>
          <div className="main-side-body">
            <div class="league-detail-header"><h2 class="league-detail-heading">Team Registration for <span>
              <span>{leagueData?.name}</span>  - <span>{
              leagueData && Array.isArray(leagueData.league_locations) && leagueData.league_locations.length ?
              leagueData.league_locations.map((loc, idx) => {
                if (loc.location) {
                  return loc.location.title + (idx < leagueData.league_locations.length - 1 ? ', ' : '');
                }
                return '';
                }) : ''
              }</span>
            </span></h2></div>

            <div className="league-rules reg-checkout">
              <div className="league-rules-header">
                <h2>Team Information</h2>
              </div>
              <div className="league-rules-body team-info-body">
                <div className="caption-team-row">
                  <div className="caption-info-item">
                    <strong>Caption Name: </strong>{" "}
                    {loggedInUserDetails?.full_name}
                  </div>
                  <div className="caption-info-item">
                    <strong>Caption Email: </strong> {loggedInUserDetails?.email}
                  </div>
                  <div className="caption-info-item">
                    <strong>Caption Phone: </strong>{" "}
                    {loggedInUserDetails?.cell_phone}
                  </div>
                </div>
                <div className="team-info-fields">
                  <div className="input-blk">
                    <label>Team Name</label>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      value={formData["name"]}
                      onChange={(e) => handleFormChange(e)}
                    ></input>
                    <ErrorInput
                      formSubmitted={formSubmitted}
                      error={errors["name"]}
                    />
                  </div>
                  <div className="input-blk">
                    <label>Preffered Play Time:</label>
                    <div className="custom-select">
                      <select
                        className="form-control"
                        name="preferred_time"
                        value={formData["preferred_time"]}
                        onChange={(e) => {
                          handleFormChange(e);
                        }}
                      >
                        <option value="early">Early</option>
                        <option value="late">Late</option>
                        <option value="balanced">Balanced</option>
                      </select>
                    </div>
                  </div>
                  <div className="input-blk cs-new-textarea">
                    <label htmlFor="request">Additional Requests</label>
                    <textarea className="form-control" id="request" name="additional_request" onChange={(e) => handleFormChange(e)} />
                  </div>
                </div>

                <div className="cs-fee-info">
                  {/* <div className="input-blk">
										<label>Custom Team Option:</label>
										<input className="form-control" type='text' name='custom_option' value={formData['custom_option']} onChange={(e) => handleFormChange(e)} />
									</div> */}
                  <div className="cs-fee-info-item">
                    <strong>League Fees Due: </strong> <p>$
                      {leagueData?.reg_fees || "0.00"}</p>
                  </div>
                  <div className="cs-fee-info-item">
                    <strong>Refundable Forfeit Fee Due: </strong> <p>$
                      {leagueData?.for_feit_fees || "0.00"}</p>
                  </div>
                  <div className="cs-fee-info-item">
                    <strong>Stripe Fee Due: </strong>
                    <p>
                      $
                      {leagueData
                        ? (
                          (+leagueData?.reg_fees +
                            +leagueData?.for_feit_fees +
                            0.3) /
                          (1 - 0.029) -
                          (+leagueData?.reg_fees + +leagueData?.for_feit_fees)
                        ).toFixed(2)
                        : "0.00"}
                    </p>
                  </div>
                  <div className="cs-fee-info-item total-fee">
                    <strong>Total Fee Due: </strong>
                    <p>
                      $
                      {leagueData
                        ? (
                          (+leagueData?.reg_fees +
                            +leagueData?.for_feit_fees +
                            0.3) /
                          (1 - 0.029)
                        ).toFixed(2)
                        : "0.00"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="league-rules reg-checkout">
              <div className="league-rules-body">
                <p>
                  <strong>We Accept</strong>
                </p>
                <div className="img-payment-cards">
                  <img src={visa} alt="Payments" />
                </div>
                <div className="understand-blk">
                  <div className="checkbox-policy-blk">
                    <label className="custom-checkbox">
                      <input
                        type="checkbox"
                        name="policies"
                        value={policyData.policies}
                        onChange={(e) => handleCheckBox(e)}
                      />
                      <span>I have read and fully understand the</span>
                    </label>
                    <span
                      className="link"
                      onClick={() =>
                        setPolicy({
                          open: true,
                          type: "cancelation",
                        })
                      }
                    >
                      Cancellation and Registration Policies
                    </span>
                  </div>
                  <div className="checkbox-policy-blk">
                    <label className="custom-checkbox">
                      <input
                        type="checkbox"
                        name="covid_policies"
                        value={policyData.covid_policies}
                        onChange={(e) => handleCheckBox(e)}
                      />
                      <span>I have read and fully understand the</span>
                    </label>
                    <span
                      className="link"
                      onClick={() =>
                        setPolicy({
                          open: true,
                          type: "covid19",
                        })
                      }
                    >
                      Covid-19 Policies
                    </span>
                    <ErrorInput
                      formSubmitted={formSubmitted}
                      error={errors["policies_team_register"]}
                    />
                  </div>
                  <div className="checkbox-policy-blk">
                    <label className="custom-checkbox">
                      <input
                        type="checkbox"
                        name="covid_policies"
                        onChange={(e) =>
                          setEmailPhoneValidation(e.target.checked)
                        }
                      />
                      <span>
                        I have validated my Email address, Contact number and it
                        is correct.
                      </span>
                    </label>
                    <ErrorInput
                      formSubmitted={formSubmitted}
                      error={errors["email_phone_validation"]}
                    />
                  </div>
                </div>
                {leagueData &&
                  Array.isArray(leagueData.teams) && leagueData?.teams?.length && leagueData.teams.filter(x => x.status === 'active').length >= leagueData.team_size ? (
                  <>
                    <div className="radio-btn-box">
                      <label className="radio-container">
                        <input
                          type="radio"
                          name="radio"
                          value={PaymentTeamCreate.save_card}
                          checked={
                            selectedTypePayment === PaymentTeamCreate.save_card
                          }
                          onChange={() =>
                            setSelectedTypePayment(PaymentTeamCreate.save_card)
                          }
                        />
                        <span className="checkmark"></span>
                        Pay by Card
                      </label>
                      <label className="radio-container">
                        <input
                          type="radio"
                          name="radio"
                          value={PaymentTeamCreate.cheque_cash}
                          checked={
                            selectedTypePayment ===
                            PaymentTeamCreate.cheque_cash
                          }
                          onChange={() =>
                            setSelectedTypePayment(
                              PaymentTeamCreate.cheque_cash
                            )
                          }
                        />
                        <span className="checkmark"></span>
                        Pay by Cash / Check
                      </label>
                    </div>
                    <div className="paypal-blk">
                      <button
                        className="btn paypal-btn"
                        onClick={() => {
                          if (selectedTypePayment) {
                            if (
                              selectedTypePayment ===
                              PaymentTeamCreate.save_card
                            ) {
                              openCheckoutModal(selectedTypePayment);
                            } else {
                              saveTeam();
                            }
                          } else {
                            openCheckoutModal(null);
                          }
                        }}
                      >
                        Save
                      </button>
                    </div>
                    {
                      selectedTypePayment === PaymentTeamCreate.save_card &&
                      <div className="special-line">
                        Your card will only be charged if you are added to the league.
                      </div>
                    }
                  </>
                ) : (
                  <div className="paypal-blk">
                    <span>Checkout With Stripe</span>
                    <button
                      className="btn paypal-btn"
                      onClick={() => openCheckoutModal(null)}
                    >
                      Pay Now
                    </button>
                    <span className="secure">
                      Secure checkout provided by stripe - no signup required
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="note-info">
              <p className="note-info-heading">
                <strong>
                  Important Information
                </strong>
              </p>
              {leagueData &&
                Array.isArray(leagueData.teams) &&
                leagueData.teams.length >= leagueData.team_size ? (
                <>
                  <p>
                    Since all leagues are completely occupied, you will be
                    added to the wait list after completing this
                    registration.
                  </p>
                  <p>
                    The admin will review your registration and will take a
                    decision on its approval.
                  </p>
                  <p>
                    During the time your registration is not approved, no
                    charges will be deducted from your card. After the admin
                    approves your registration then only the amount is
                    deducted from your saved card details.
                  </p>
                </>
              ) : (
                <p>
                  After completing registration, please verify that you have
                  registered for the correct league. You'll be able to invite
                  members through the "My Teams" section of your profile.
                </p>
              )}
            </div>
          </div>
        </div>
        <Popup
          open={checkout || policy?.open}
          onClose={() => {
            setCheckoutOpen(false);
            setPolicy({ open: false, type: "" });
          }}
          closeOnDocumentClick="false"
          className={`stripe-popup${!checkout ? "" : " stripePayment"}`}
        >
          {checkout ? (
            <Checkout
              closeModal={() => {
                setCheckoutOpen(false);
              }}
              leagueData={leagueData}
              teamData={formData}
            />
          ) : (
            <Policy
              type={policy?.type}
              closeModal={() => setPolicy({ open: false, type: "" })}
            />
          )}
        </Popup>
      </section>
    </>
  );
}

export default withRouter(Team);
