import "./footer.css";
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import logo from "../../../assets/images/mulberry-logo.png";
import moment from 'moment';

export default function Footer(props) {
  const search = useLocation();
  const [midFooter, setMidFooter] = useState(true);
  const pathChangeEmit = (path) => {
    props.pathChange(path);
  }
  const currentYear = moment().year();
  useEffect(() => {
    const path = search.pathname.split('/');
    if (path.includes('my-account') || path.includes('league')) {
      setMidFooter(false);
    } else {
      setMidFooter(true);
    }
  }, [search.pathname])
  return (
    <footer className={`footer-main ${midFooter ? 'pl-0' : ''}`}>
      <div className="container">
        <div className="footer-logo">
          <img src={logo} alt="" />
        </div>
        <div className="nav-footer">
          <ul>
            <li>
              <Link to='/league' onClick={ () => pathChangeEmit('/league')}>Leagues</Link>
            </li>
            {/* <li>
              <Link to='/' onClick={ () => pathChangeEmit('/')}>Menu</Link>
            </li> */}
            <li>
              <Link to='/' onClick={ () => pathChangeEmit('/')}>Home</Link>
            </li>
            <li>
              <Link to='/' onClick={ () => pathChangeEmit('/')}>Events</Link>
            </li>
            <li>
              <Link to='/contact' onClick={ () => pathChangeEmit('/contact')}>Contact</Link>
            </li>
          </ul>
        </div>
        <div className="copyright-block">
          &copy; {currentYear} Mulberry's Cleveland 
          <p>All Rights Reserved</p>
        </div>
      </div>
    </footer>
  );
}