import React, { useEffect, useState } from 'react';
import axiosApi from '../../core/interceptor/interceptor';
import { config } from '../../config';
import './today-schedule.css';
import Collapsible from 'react-collapsible';
import noRecordImage from '../../assets/images/no-record.png';
import allIcon from '../../assets/icons/all-icon.svg';
import beachFootballIcon from '../../assets/icons/beach-football-icon.svg';
import volleyballIcon from '../../assets/icons/volleyball-icon.svg';
import basketballIcon from '../../assets/icons/basketball-icon.svg';
import tennisIcon from '../../assets/icons/tennis-icon.svg';
import golfIcon from '../../assets/icons/golf-icon.svg';
import touchFootball from '../../assets/icons/touch-football.svg';
import baseballIcon from '../../assets/icons/baseball-icon.svg';
import dodgeballIcon from '../../assets/icons/dodgeball-icon.svg';
import bowlingIcon from '../../assets/icons/bowling-icon.svg';
import bocciIcon from '../../assets/icons/bocci-icon.svg';
import kickballIcon from '../../assets/icons/kickball-icon.svg';
import soccerIcon from '../../assets/icons/soccer-icon.svg';
import arenaFootballIcon from '../../assets/icons/arena-football.svg';
import barGamesIcon from '../../assets/icons/bar-games.svg';
import bubbleBallIcon from '../../assets/icons/bubble-ball.svg';
import dartsIcon from '../../assets/icons/darts.svg';
import euchreIcon from '../../assets/icons/euchre.svg';
import flagFootballIcon from '../../assets/icons/flag-football.svg';
import floorHockeyIcon from '../../assets/icons/floor-hockey.svg';
import kanJamIcon from '../../assets/icons/kan-jam.svg';
import olympaidIcon from '../../assets/icons/olympaid.svg';
import softballIcon from '../../assets/icons/softball.svg';
import spikeBallIcon from '../../assets/icons/spike-ball.svg';
import ultimateFrisbeeIcon from '../../assets/icons/ultimate-frisbee.svg';
import washerTossIcon from '../../assets/icons/washer-toss.svg';
import whiffleBallIcon from '../../assets/icons/whiffle-ball.svg';
import yogaIcon from '../../assets/icons/yoga.svg';

const gameIcons = {
    'All': allIcon,
    "bocci": bocciIcon,
    "Arena Football": arenaFootballIcon,
    "Arena Kickball": kickballIcon,
    "Men's Flag Football": flagFootballIcon,
    "Bubble Ball": bubbleBallIcon,
    "Triple  Fun!": volleyballIcon,
    "Kan Jam": kanJamIcon,
    "WasherToss/Cornhole/LadderToss": washerTossIcon,
    "5 on 5 Beach Soccer": soccerIcon,
    "5 on 5 Beach Football": beachFootballIcon,
    "Spike Ball": spikeBallIcon,
    "Bar Games": barGamesIcon,
    "Beach Volleyball": volleyballIcon,
    "Bowling": bowlingIcon,
    "Tennis": tennisIcon,
    "Euchre": euchreIcon,
    "Flag Football": flagFootballIcon,
    "Floor Hockey": floorHockeyIcon,
    "Golf": golfIcon,
    "Soccer": soccerIcon,
    "Softball": softballIcon,
    "Touch Football": touchFootball,
    "Fantasy Football": touchFootball,
    "Basketball": basketballIcon,
    "Indoor Volleyball": volleyballIcon,
    "Kickball": kickballIcon,
    "Dodgeball": dodgeballIcon,
    "Whiffle Ball": whiffleBallIcon,
    "Darts": dartsIcon,
    "Cornhole": washerTossIcon,
    "Ultimate Frisbee": ultimateFrisbeeIcon,
    "Olympiad": olympaidIcon,
    "Yoga": yogaIcon,
}

const TodaySchedule = () => {
    const [todaySchedule, setTodaySchedule] = useState([]);
    const [selectedTab, setSelectedTab] = useState('All');
    const [selectedSchedule, setSelectedSchedule] = useState({});

    const dateOptions = { month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };

    const checkIsTimeGreater = (givenDateStr, givenTimeStr) => {
        const currentDate = new Date();
        const userCurrentDateTime = new Date(currentDate.toLocaleString('en-US', { timeZone: 'America/New_York' }));
        const givenDate = new Date(`${givenDateStr}T${givenTimeStr}`);
        const givenDateTime = new Date(givenDate.toLocaleString('en-US'));

        return givenDateTime > userCurrentDateTime;
    }

    useEffect(() => {
        setSelectedSchedule(() => {
            return todaySchedule.find(game => selectedTab === game.title)
        })
    }, [selectedTab])


    useEffect(() => {
        axiosApi.get(`${config.apiUrl}/today-schedule`).then((res) => {
            setTodaySchedule(res.data.data);
            if (res.data.data?.[0]) {
                setSelectedSchedule(res.data.data[0]);
            }
        }).catch(err => {
            console.log('Error while getting todays schedule, error ==>> ', err.message || 'unknown error')
        });
    }, []);

    const [openSection, setOpenSection] = useState(null);

    const handleCollapsibleClick = (index) => {
        if (openSection === index) {
            setOpenSection(null);
        } else {
            setOpenSection(index);
        }
    };

    return (

        <div className={`today-schedule-wrapper ${selectedSchedule?.leagues?.length ? '' : 'no-record'}`}>
            <div className="cs-container">
                <div className="today-schedule">
                    <h1>Today's Schedule</h1>
                    {todaySchedule.length ? <div className="game-schedule-list">
                        <ul>
                            {todaySchedule.map((game, i) => {
                                return (
                                    <li key={i} className={selectedTab === game.title ? "active" : ""} onClick={() => setSelectedTab(game.title)}>
                                        <a href="#" title={game.title}>
                                            <span className="live-game-badge">{game.totalGames}</span>
                                            <figure className={game.title === 'All' ? "all-icon" : ''}>
                                                <img src={gameIcons[game.title]} alt={game.title} />
                                            </figure>
                                            <p>{game.title}</p>
                                        </a>
                                    </li>
                                )
                            })}
                        </ul>
                    </div> : ''}
                    {selectedSchedule?.leagues?.length ?
                        selectedSchedule?.leagues?.map((league, i) => {
                            return (
                                <div key={i} className="schedule-card-info">
                                    <Collapsible
                                        trigger={
                                            <div className="schedule-card-head">
                                                <h3>
                                                    <img src={gameIcons[league.sportTitle]} alt={league.sportTitle} />
                                                    {league.name}
                                                </h3>
                                                <div className="open-arrow"><span></span></div>
                                            </div>
                                        }
                                        open={openSection === i}
                                        onOpening={() => handleCollapsibleClick(i)}
                                        onClosing={() => setOpenSection(null)}
                                    >
                                        <>
                                            <div className="schedule-card-time">
                                                {new Date(league.date).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
                                            </div>
                                            {league?.games?.length ?
                                                <ul className="cardschedule-detail">
                                                    {league?.games?.map((match, i) => {
                                                        return (
                                                            <li key={i} className={`${checkIsTimeGreater(match.date, match.time) ? 'bg-green' : ''}`}>
                                                                <p><b>{`${match?.team1?.name} vs ${match?.team2?.name}`}</b></p>
                                                                <p>Location: <b>{`${match.field.title}, ${match.location.title}`}</b></p>
                                                                <p>Date & Time: {new Date(`${match.date}T${match.time}`).toLocaleString('en-US', dateOptions)}</p>
                                                            </li>
                                                        )
                                                    })}
                                                </ul> :
                                                <div className='no-record-found'>
                                                    <img src={noRecordImage} alt={league.sportTitle} />
                                                    <p>No Match Found</p>
                                                </div>
                                            }
                                        </>
                                    </Collapsible>
                                </div>
                            )
                        }) :
                        <div className='no-record-found'>
                            <img src={noRecordImage} alt={'No Record Found'} />
                            <p>No Match Found</p>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default TodaySchedule;
