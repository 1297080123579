import React, { useEffect, useState, useMemo, useCallback } from "react";
// import event from '../../assets/images/EventsImage.jpg';
import "./events.css";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import banner from '../../assets/images/image.jpg';
import bannerCurve from '../../assets/images/banner-curve.svg';
import evBanner from '../../assets/images/ev-banner.png';
import cardImg from '../../assets/images/card-img.png';
import ArrowLeft from '../../assets/images/ArrowLeft.png';
import ArrowRight from '../../assets/images/ArrowRight.png';
import ArrowLeftCircle from '../../assets/images/arrow-new-left.svg';
import ArrowRightCircle from '../../assets/images/arrow-new-right.svg';
import noEventsImage from '../../assets/images/no-events-found.png';
import { config } from '../../config';
import axiosApi from "../../core/interceptor/interceptor";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Popup } from 'reactjs-popup';
import { useSelector } from "react-redux";
import { userDetails } from "../../store/reducer/LoggedInUserDetails";
import Login from "../common/login/Login";
import Signup from "../common/signup/signup";
import moment from "moment";
import { formatNumberToCurrency } from "../../utils/Utils";
import { useHistory, withRouter } from "react-router-dom";
import AddLeadPopup from "../account/Leads/AddLeadPopup";
import FullCalendarPopup from "../common/FullCalendar/FullCalendarPopup";

function Events() {
  const SlickArrowLeft = ({ ...props }) => (
    <div {...props} className={"arrow-left"}>
      <img src={ArrowLeft} alt="" />
    </div>
  );
  const RoundArrowRight = ({ ...props }) => (
    <div {...props} className={"arrow-right main-ev-arrows"}>
      <img src={ArrowRightCircle} alt="" />
    </div>
  );
  const RoundArrowLeft = ({ ...props }) => (
    <div {...props} className={"arrow-left main-ev-arrows"}>
      <img src={ArrowLeftCircle} alt="" />
    </div>
  );
  const SlickArrowRight = ({ ...props }) => (
    <div {...props} className={"arrow-right"}>
      <img src={ArrowRight} alt="" />
    </div>
  );
  const SlickArrowLeftBanner = ({ ...props }) => (<div onClick={() => props.onClick()} className={"arrow-left banner-arrow"}>
    <img src={ArrowLeft} alt="" />
  </div>);
  const SlickArrowRightBanner = ({ ...props }) => (
    <div onClick={() => props.onClick()} className={"arrow-right banner-arrow"}>
      <img src={ArrowRight} alt="" />
    </div>
  );
  const isLoggedIn = useSelector(userDetails);
  const history = useHistory();
  const [homeSlider, setHomeSlider] = useState([]);
  const [headline, setHeadline] = useState('');
  const [venues, setVenues] = useState([]);
  const [inclusions, setInclusions] = useState([]);
  const [inclusionHeadline, setInclusionHeadline] = useState('');
  const [background, setBackground] = useState('');
  const [login, setLogIn] = useState(false);
  const [signup, setSignUp] = useState(false);
  const [publicEvents, setPublicEvents] = useState([]);
  const [eventStatus, setEventStatus] = useState('upcoming');
  const [openLeadPopup, setOpenLeadPopup] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [currentEventCount, setCurrentEventCount] = useState(null);
  const [upcomingEventCount, setUpcomingEventCount] = useState(null);

  const getHomeSlider = () => {
    axiosApi.get(`${config.apiUrl}/events/home-slider`)
      .then((res) => {
        setHomeSlider(res?.data?.data);
      })
  }
  const getHeadline = () => {
    axiosApi.get(`${config.apiUrl}/events/headline`)
      .then((res) => {
        setHeadline(res?.data?.data);
      })
  }

  const getInclusionHeadline = () => {
    axiosApi.get(`${config.apiUrl}/events/inclusion-headline`)
      .then((res) => {
        setInclusionHeadline(res?.data?.data);
      })
  }

  const getVenues = () => {
    axiosApi.get(`${config.apiUrl}/events/venues`)
      .then((res) => {
        setVenues(res?.data?.data);
      })
  }

  const getBackground = () => {
    axiosApi.get(`${config.apiUrl}/events/background`)
      .then((res) => {
        setBackground(res?.data?.data);
      })
  }

  const getInclusions = () => {
    axiosApi.get(`${config.apiUrl}/events/inclusions`)
      .then((res) => {
        setInclusions(res?.data?.data);
      })
  }

  const getPublicEvents = (status) => {
    axiosApi.get(`${config.apiUrl}/events/public/${status}`)
      .then((res) => {
        if (res?.data?.data) {
          setPublicEvents(res.data.data);
          setCurrentEventCount((prev) => res.data?.currentEventCount);
          setUpcomingEventCount((prev) => res.data?.upcomingEventsCount);
        }
      })
  }

  const eventSettings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    waitForAnimate: false,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        }
      }
    ]
  };

  const getSetting = (events) => {
    let eventSetting = eventSettings;
    if (events.length) {
      switch (events.length) {
        case 1:
          eventSetting.slidesToShow = 1;
          break;
        case 2:
          eventSetting.slidesToShow = 2;
          break;
        case 3:
          eventSetting.slidesToShow = 3;
          break;
        default:
          eventSetting.slidesToShow = 3;
      }
    }
    return eventSetting;
  }
  const eventSetting1 = {
    dots: false,
    infinite: false,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    waitForAnimate: false,
    prevArrow: <RoundArrowLeft />,
    nextArrow: <RoundArrowRight />,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          // arrows: false,
        }
      },
      {
        breakpoint: 585,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // arrows: false,
        }
      }
    ]

  }
  const eventSetting = useMemo(() => getSetting(publicEvents), [publicEvents]);
  const inclusionSetting = useMemo(() => getSetting(inclusions), [inclusions]);

  const settingsForBannerSlider = {
    dots: false,
    infinite: true,
    arrows: true,
    autoplay: true,
    // lazyLoad: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <SlickArrowLeftBanner />,
    nextArrow: <SlickArrowRightBanner />,
    waitForAnimate: false,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          arrows: false,
        }
      }
    ]
  };

  useEffect(() => {
    getHomeSlider();
    getHeadline();
    getVenues();
    getInclusions();
    getInclusionHeadline();
    getBackground();
    getPublicEvents('upcoming');
  }, [])

  const handlePlanning = () => {
    if (!venues.length) {
      if (isLoggedIn) {
        setOpenLeadPopup(true);
      } else {
        setLogIn(true);
      }
    } else {
      setOpenCalendar(true);
    }
  }

  const closePopup = (cb) => {
    setOpenLeadPopup(cb);
  }

  const homeSliders = useMemo(() => {
    return <div className="crousal">
      {
        homeSlider && homeSlider.length > 0 ?
          <Slider {...settingsForBannerSlider}>
            {
              homeSlider.map((el, i) => {
                return <img src={`${config.api}/${config.imageFolder}/events/${el.image}`} key={i} alt="banner" />
              })
            }
          </Slider>
          :
          <><img src={banner} alt="banner" /></>
      }
    </div>
  }, [homeSlider]);

  const bookNowButton = (start, end, id) => {
    const date = moment(new Date()).format('MM/DD/YYYY');
    return <>
      {date >= moment(start).format('MM/DD/YYYY') && date <= moment(end).format('MM/DD/YYYY') ?
        <div className="slider-card-action">
          <button onClick={() => history.push({ pathname: `/events/event-details/${id}`, state: { booking: true } })} className="btn">BOOK NOW</button>
        </div>
        :
        <div className="slider-card-action">
          <button onClick={() => history.push({ pathname: `/events/event-details/${id}`, state: { booking: false } })} className="btn">DETAILS</button>
        </div>
      }
    </>
  }

  const publicEventsSlider = useMemo(() => {
    return <>
      {
        publicEvents && publicEvents.length > 0 ? <>
          <Slider {...eventSetting1} className="upcoming-match-slider">
            {
              publicEvents.map((pubEvent, i) => {
                return <div className="slider-card" key={i}>
                  <div className="slider-card-img">
                    {new Date(pubEvent.booking_start_date) > new Date() && <span className="booking-start-date">Booking Start Date: {moment(pubEvent.booking_start_date).format('MMM DD, YYYY')}</span>}
                    {
                      !pubEvent.image ?
                        <img src={cardImg} alt="" />
                        :
                        <img src={`${config.api}/${config.imageFolder}/events/${pubEvent.image}`} alt="" />
                    }
                  </div>
                  <div className="slider-card-body">
                    <h4 className="slider-card-title">
                      {pubEvent?.title}
                    </h4>
                    <p className="slider-card-date">
                      <span>{moment(pubEvent?.start_date_time).format('ll hh:mm A')}</span> - <span>{moment(pubEvent?.end_date_time).format('ll hh:mm A')}</span>
                    </p>
                    {
                      pubEvent.paid_type === 'paid' ?
                        <h1 className="slider-card-price">
                          {formatNumberToCurrency(pubEvent?.ticket_price)}
                        </h1>
                        :
                        <h1 className="slider-card-price">
                          Free
                        </h1>
                    }
                    {bookNowButton(pubEvent.booking_start_date, pubEvent.booking_deadline, pubEvent?.id)}
                  </div>
                </div>
              })
            }
          </Slider>
        </>
          :
          <>
            <div className="upcoming-match-slider no-events-found">
              <div className="slider-card" >
                <div className="slider-card-img">
                  <img src={noEventsImage} alt="" />
                </div>
              </div>
            </div>
          </>
      }
    </>
  }, [publicEvents])

  const inclusionSlider = useMemo(() => {
    return <>
      {
        inclusions && inclusions.length > 0 &&
        <>
          <Slider {...eventSetting1} className="upcoming-match-slider">
            {
              inclusions.map((el, i) => {
                return <div className="ev-included-card" key={i}>
                  <div className="inc-card-img">
                    <img src={`${config.api}/${config.imageFolder}/events/${el.image}`} alt="" />
                  </div>
                  <p className="inc-card-title">
                    {el.name}
                  </p>
                </div>
              })
            }
          </Slider>
        </>
      }
    </>
  }, [inclusions]);

  const getDateRange = (dateCallback) => {
    setSelectedDate(dateCallback);
    if (isLoggedIn) {
      setOpenLeadPopup(true);
    } else {
      setLogIn(true);
    }
  }
  const closeCalendar = (cb) => {
    setOpenCalendar(cb);
  }
  return (
    <>
      <section className="hero-section slider-hero hero-slider-rs">
        <div className="banner-img">
          {homeSliders}
          <img className="banner-curve" src={bannerCurve} alt="curve" />
        </div>
      </section>
      <section className="events-decription">
        <div className="container">
          <div className="text-container">
            <h2>{headline?.headline}</h2>
            <h4></h4>
            <p dangerouslySetInnerHTML={{ __html: headline?.text_content }}></p>
            {/* <p>Located on the vibrant waterfront of North Miami Beach, Florida, our event center is just moments from the area's best boating., sightseeing,.
              shopping, dining and nightlife.</p> */}
            <span onClick={() => handlePlanning()} className="btn btn-lg">Start planning</span>
          </div>
        </div>
      </section>
      {/* <section className="events-slider">
        <div className="container">
          <div className="ev-slider">

          </div>
      </section> */}
      {(currentEventCount || upcomingEventCount) && <section className="events-slider">
        <div className="container">
          <div className="ev-slider">
            <div className="ns-tabs">
              <ul>
                <li onClick={() => { getPublicEvents('upcoming'); setEventStatus('upcoming') }} className={`${eventStatus === 'upcoming' ? 'active-tab' : ''}`} >upcoming Events</li>
                <li onClick={() => { getPublicEvents('current'); setEventStatus('current') }} className={`${eventStatus === 'current' ? 'active-tab' : ''}`}>Current Events</li>
              </ul>
            </div>
            <div className="ev-silder-container" >
              {publicEventsSlider}
            </div>
          </div>
        </div>
      </section>}
      {
        venues && venues.length > 0 &&
        <section className="event-venues">
          <div className="container">
            <h2 className="ev-sec-title">
              ENJOY OUR EVENT VENUES
            </h2>
            <div className="event-venue-cards">
              {venues && venues.length > 0 ?
                venues.map((el, i) => {
                  return <div className="event-venue-card" key={i}>
                    <div className="ev-card-img">
                      <img src={`${config.api}/${config.imageFolder}/events/${el.image}`} alt="" />
                    </div>
                    <div className="ev-card-desc">
                      <h3>{el?.name}</h3>
                      <p>{el?.description}</p>
                    </div>
                  </div>
                })
                : ''
              }
            </div>
          </div>
        </section>
      }
      <section className="ev-banner" style={{ '--imgasc': background && background.length ? `url(${config.api}/${config.imageFolder}/events/${background[0]?.image})` : `url(${evBanner})` }}>
      </section>
      {inclusions && inclusions.length > 0 && <section className="ev-included">
        <div className="container">
          <div className="ev-included-header">
            <h2 className="ev-sec-title">
              {inclusionHeadline?.headline}
            </h2>
            <p dangerouslySetInnerHTML={{ __html: inclusionHeadline?.text_content }}></p>
          </div>
          <div className="slider-card">
            {inclusionSlider}
          </div>
        </div>
      </section>}
      <Popup className="ns-popup" open={openLeadPopup} closeOnDocumentClick={false}>
        <AddLeadPopup dateRange={selectedDate} close={closePopup} />
      </Popup>
      <Popup
        open={login}
        onClose={() => {
          setLogIn(false);
        }}
        closeOnDocumentClick={true}
      >
        <Login
          closeLoginModal={(resp) => {
            if (resp) {
              setOpenLeadPopup(true);
            }
            setLogIn(false);
          }}
          login={true}
          openSignUp={() => setSignUp(true)}
        ></Login>
      </Popup>
      <Popup
        open={signup}
        onClose={() => {
          setSignUp(false);
        }}
        closeOnDocumentClick={false}
      >
        <Signup
          closeSignupModal={(resp) => {
            setSignUp(false)
          }}
          openLogin={() => setLogIn(true)}
          registeredFor={'event'}
        ></Signup>
      </Popup>
      <Popup className="ns-popup full-cal-popup" open={openCalendar} closeOnDocumentClick={false}>
        <FullCalendarPopup dateRange={getDateRange} onClose={closeCalendar} venues={venues} />
      </Popup>
    </>
  );
}
export default withRouter(Events);