import moment from "moment";
import React, { useEffect, useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { config } from "../../../config";
import axiosApi from "../../../core/interceptor/interceptor";
import { useSelector } from "react-redux";
import { userDetails } from "../../../store/reducer/LoggedInUserDetails";
import '../../league/League.css';
import { EVENT_OCCASION, EVENT_TYPE } from "../../../utils/Utils";
import Select from "react-select";
import DateTimePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { messages } from "../../../messages";
import NumberFormat from 'react-number-format';
import FullCalendarPopup from "../../common/FullCalendar/FullCalendarPopup";
import Popup from "reactjs-popup";
import _ from 'lodash';

function AddLeadPopup(props) {
  const userDetail = useSelector(userDetails);
  const [venues, setVenues] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    start_date_time: '',
    end_date_time: '',
    type: '',
    occasion: '',
    venue_id: null,
    venue_name: '',
    budget: '',
    no_of_guest: '',
    status: 'open',
    ticket_price: null,
    paid_type: 'free',
    captured_date: new Date(),
    user_id: userDetail?.id || 0
  });
  const [eventExists, setEventExists] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);

  useEffect(() => {
    if (userDetail) {
      setFormData((prev) => ({ ...prev, user_id: userDetail.id }))
    }
  }, [userDetail])
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errors, setErrors] = useState({
    title: '',
    start_date_time: '',
    end_date_time: '',
    type: '',
    occasion: '',
  });

  const debounceCheckEventExistence = useMemo(
    () => _.debounce((start, end) => checkEvent(start, end), 1500),
    [],
  )
  const checkEvent = (start, end) => {
    axiosApi.post(`${config.apiUrl}/misc/check-event-existence`, { start: moment(start).format(), end: moment(end).format() })
      .then((res) => {
        setEventExists(res?.data?.data);
      })
      .catch((err) => {

      })
  }
  useEffect(() => {
    if (formData.start_date_time && formData.end_date_time && formData.venue_id) {
      debounceCheckEventExistence(formData.start_date_time, formData.end_date_time);
    }
    //eslint-disable-next-line
  }, [formData.start_date_time, formData.end_date_time]);

  const handleFormChange = (e) => {
    if (e && e.target && e.target.name) {
      if (e.target.name === 'venue_id') {
        setFormData((prev) => ({
          ...prev,
          venue_name: e.target.value?.name,
          [e.target.name]: e.target.value?.id
        }))
      } else if (e.target.name === 'ticket_price') {
        setFormData((prev) => ({
          ...prev,
          ticket_price: e.target.value,
          paid_type: e.target.value > 0 ? 'paid' : 'free'
        }))
      } else {
        setFormData((prev) => ({
          ...prev,
          [e.target.name]: e.target.value
        }))
      }
    }
  }

  const handleValidation = () => {
    let errArray = {};
    let formIsValid = true;
    if (!formData.title) {
      errArray.title = messages && messages.emptyField;
      formIsValid = false;
    }
    if (!formData.start_date_time) {
      errArray.start_date_time = messages && messages.emptyField;
      formIsValid = false;
    }
    if (!formData.end_date_time) {
      errArray.end_date_time = messages && messages.emptyField;
      formIsValid = false;
    }
    if (!formData.occasion) {
      errArray.occasion = messages && messages.emptySelect;
      formIsValid = false;
    }
    if (!formData.type) {
      errArray.type = messages && messages.emptySelect;
      formIsValid = false;
    }
    if (!formData.budget) {
      errArray.budget = messages && messages.emptyField;
      formIsValid = false;
    }
    if (!formData.no_of_guest) {
      errArray.no_of_guest = messages && messages.emptyField;
      formIsValid = false;
    }
    // if (!(+formData.venue_id) > 0) {
    //   errArray.venue_id = messages && messages.emptySelect;
    //   formIsValid = false;
    // }
    if (formData.start_date_time && formData.end_date_time && (moment(formData.start_date_time).format() >= moment(formData.end_date_time).format())) {
      formIsValid = false;
      errArray.start_date_time = messages && messages.eventEndTimeErr;
    }
    if (eventExists) {
      formIsValid = false;
      errArray.start_date_time = messages && messages.eventExixts;
    }
    setErrors(errArray);
    return formIsValid;
  }

  const handleSubmit = () => {
    setFormSubmitted(true);
    if (handleValidation()) {
      axiosApi.post(`${config.apiUrl}/leads/create`, formData)
        .then((res) => {
          props.close(false);
          if (props && props.refresh) {
            props.refresh(true);
          }
        })
        .catch((res) => {

        })
    }
  }

  useEffect(() => {
    handleValidation();
    // eslint-disable-next-line
  }, [formData, eventExists]);

  useEffect(() => {
    getVenues();
  }, [])

  const getVenues = () => {
    axiosApi.get(`${config.apiUrl}/events/venues`)
      .then((res) => {
        if (res && res.data && res.data.data) {
          setVenues(res.data.data);
        }
      })
  }

  const getDateRange = (dateCallback) => {
    const start = dateCallback.start;
    const end = dateCallback.end;
    setFormData((prev) => ({
      ...prev,
      start_date_time: start,
      end_date_time: end,
    }))
  }

  const closeCalendar = (cb) => {
    setOpenCalendar(cb);
  }

  return <>
    <div>
      <div className="ns-popup-container personal-details-popup">
        <div className="ns-popup-header">
          <div className="ns-tabs full-w-tabs">
            <ul>
              <li className="active-tab">Event Details</li>
            </ul>
          </div>
        </div>
        <div className="ns-popup-body" id="more-info">
          <h3>ADD A BIT MORE INFORMATION</h3>
          <p>We will be in touch shortly.</p>
          <div className="field-block-outer cs-row">
            <div className="field-block cs-col-4 tab-half-fluid mob-fluid">
              <label>Name</label>
              <input type="text" className="form-control" onChange={(e) => handleFormChange(e)} name="title" autoComplete="off" />
              <span className="error">{formSubmitted && errors['title']}</span>
            </div>
            <div className="select-block cs-col-4 tab-half-fluid mob-fluid">
              <label>Type</label>
              <Select
                className="new-select"
                classNamePrefix="cs-select"
                value={formData?.type ? formData?.type : ''}
                placeholder={formData?.type ? EVENT_TYPE[formData?.type] : 'Select...'}
                onChange={(el) => el && handleFormChange({ target: { name: 'type', value: el.id } })}
                selector='name'
                options={[
                  {
                    id: 'public',
                    name: 'Public',
                  },
                  {
                    id: 'private',
                    name: 'Private'
                  }
                ]}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
              />
              <span className="error">{formSubmitted && errors['type']}</span>
            </div>
            <div className="select-block cs-col-4 tab-half-fluid mob-fluid">
              <label>Budget</label>
              <NumberFormat
                placeholder=" "
                className="form-control"
                onValueChange={(e) => handleFormChange({ target: { name: 'budget', value: e.floatValue } })}
                value={formData?.budget}
                decimalScale={2}
                fixedDecimalScale={true}
                allowNegative={false}
                thousandsGroupStyle={['thousand']}
                thousandSeparator={true}
                prefix='$'
                name="total"
                autoComplete="off"
              />
              <span className="error">{formSubmitted && errors['budget']}</span>
            </div>
            <div className="field-block cs-col-4 tab-half-fluid mob-fluid">
              <label>Event start date</label>
              <DateTimePicker
                className="form-control"
                minDate={new Date()}
                selected={formData?.start_date_time}
                onChange={(value) => {
                  handleFormChange({
                    target: {
                      value,
                      name: 'start_date_time',
                    }
                  })
                }}
                showTimeSelect
                dateFormat="MMMM d, yyyy h:mm aa"
                timeIntervals={30}
              />
              <span className="error">{(formSubmitted || eventExists) && errors['start_date_time']}</span>
            </div>
            <div className="field-block cs-col-4 tab-half-fluid mob-fluid">
              <label>Event end date</label>
              <DateTimePicker
                className="form-control"
                minDate={new Date()}
                selected={formData?.end_date_time}
                onChange={(value) => {
                  handleFormChange({
                    target: {
                      value,
                      name: 'end_date_time',
                    }
                  })
                }}
                showTimeSelect
                dateFormat="MMMM d, yyyy h:mm aa"
                timeIntervals={30}
              />
              <span className="error">{formSubmitted && errors['end_date_time']}</span>
            </div>
            <div className="select-block cs-col-4 tab-half-fluid mob-fluid">
              <label>Occasion</label>
              <Select
                className="new-select"
                classNamePrefix="cs-select"
                value={formData?.occasion ? formData?.occasion : ''}
                placeholder={formData?.occasion ? formData?.occasion : 'Select...'}
                onChange={(value) => value && handleFormChange({
                  target: {
                    name: 'occasion',
                    value: value.name
                  }
                })}
                selector='name'
                options={EVENT_OCCASION}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
              />
              <span className="error">{formSubmitted && errors['occasion']}</span>
            </div>
            <div className="field-block cs-col-4 tab-half-fluid mob-fluid">
              <label>No of guest</label>
              <input type="number" className="form-control" onChange={(e) => handleFormChange(e)} name="no_of_guest" autoComplete="off" />
              <span className="error">{formSubmitted && errors['no_of_guest']}</span>
            </div>
            <div className="select-block cs-col-4 tab-half-fluid mob-fluid">
              <label>Venue</label>
              <Select
                className="new-select"
                classNamePrefix="cs-select"
                value={formData?.venue_id ? formData?.venue_id : null}
                placeholder={formData?.venue_name ? formData?.venue_name : 'Select...'}
                onChange={(el) => el && handleFormChange({ target: { name: 'venue_id', value: el } })}
                selector='name'
                options={venues}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
              />
              {/* <span className="error">{formSubmitted && errors['type']}</span> */}
            </div>
            {formData.type === 'public' &&
              <div className="field-block cs-col-4 tab-half-fluid mob-fluid">
                <label>Entry fee</label>
                <NumberFormat
                  placeholder=" "
                  className="form-control"
                  onValueChange={(e) => { handleFormChange({ target: { name: 'ticket_price', value: e.value } }); }}
                  value={formData?.ticket_price}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  allowNegative={false}
                  thousandsGroupStyle={['thousand']}
                  thousandSeparator={true}
                  prefix='$'
                  name="ticket_price"
                  autoComplete="off"
                />
                <span className="error"></span>
              </div>
            }
          </div>
          <div className="ns-popup-action justify-right">
            <button onClick={() => props.close(false)} className="btn btn-sec">Close</button>
            <button onClick={() => handleSubmit()} className="btn">submit</button>
          </div>
        </div>
      </div>
    </div>
    <Popup className="ns-popup full-cal-popup" open={openCalendar} closeOnDocumentClick={false}>
      <FullCalendarPopup dateRange={getDateRange} onClose={closeCalendar} />
    </Popup>
  </>
}
export default withRouter(AddLeadPopup);