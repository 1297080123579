import React from "react";
import { withRouter, useParams, useHistory } from "react-router-dom";
import backArrowGreen from "../../../assets/icons/backArrowGreen.svg";
import { toTitleCase } from "../../../utils/Utils";
import PlayOffBracket from "../../league/Brackets/PlayOffBrackets";

function PlayOffMatches(props) {
  const id = useParams().id;
  const history = useHistory();
  return (
    <>
      <main className="wrapper-main">
        <section className="main-side no-sidebar">
          <div className="main-side-container">
            <div className="main-top-headings">
              <div className="league-back-link">
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    const url = "/my-account/upcoming-matches/";
                    history.push(url, {
                      from: props.location.state?.from,
                    });
                  }}
                >
                  <img src={backArrowGreen} alt="" />
                  Back
                </a>
              </div>
            </div>
            <div className="main-side-body">
              <div className="league-detail-header">
                <h2 className="league-detail-heading">
                  {`${"Playoff Schedule"} - ${toTitleCase(
                    props.location.state?.data
                  )}`}
                </h2>
              </div>
              <div className="leagueRules-Block bracket leadue-details-brackets mt-0">
                <div>
                  <PlayOffBracket noHeading={true} leagueId={id} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
export default withRouter(PlayOffMatches);
