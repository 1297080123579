import { configureStore } from '@reduxjs/toolkit';
import loaderReducer from './reducer/LoaderReducer';
import loggedInUserDetailsSlice from './reducer/LoggedInUserDetails';
import sportsListDetails from './reducer/SportsList';

export const store = configureStore({
  reducer: {
    loader: loaderReducer,
    user: loggedInUserDetailsSlice,
    sports: sportsListDetails,
  },
});