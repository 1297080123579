import logo from '../assets/images/logo-footer.png';

export function LazyLoader() {
    return (
        <div className="loading-wrapper">
            <img src={logo} alt="logo" />
            <span>Loading...</span>
        </div>
    );
}

export function RouterView({ routes }) {
    // return (
    //     Array.isArray(routes) && routes.map((route) => (
    //         <Route exact={`${route.exact}`} path={route.path} component={route.component} />
    //     ))
    // );
}

export const phoneNumberMask = '999-999-9999';

export function maskingToPlainNumber(number) {
    if (!number) {
        return '';
    }
    number = (number).toString().replace(/\D/g, '');
    return number;
}

export function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + (phoneNumberString || '').toString()).replace(/[- )(\D]/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return match[1] + '-' + match[2] + '-' + match[3]
    }
    return null
}

export function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function validateRequiredFields(fieldsObject) {
    const keys = Object.keys(fieldsObject);
    return !!keys.length && keys.some(key => !(fieldsObject[key] || '').toString().trim());
}

export function toTitleCase(str) {
    return str ? str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    ) : '';
}

export const SHIRT_SIZE = {
    S: 'Small',
    M: 'Medium',
    L: 'Large',
    XL: 'Extra Large',
    XXL: 'Double Extra Large',
}

export const PaymentTeamCreate = {
  save_card: 'card',
  cheque_cash: 'cheque'
}

export function capitaLizeString(str) {
  var i, frags = str.split('_');
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
}

export const REGISTERING_FOR = {
  EVENT: 'event',
  SPORT: 'sport',
  EVENT_TICKET: 'event-ticket'
}
export function formatNumberToCurrency(num) {
	if (!num) {
		return;
	}
	const result = new Intl.NumberFormat('en-US', {
		style: 'currency',
  	currency: 'USD',
	}).format(num);
	return result;
}
export const EVENT_OCCASION = [
  {id: 1, name: 'Concert'}, 
  {id: 2, name: 'Conference'}, 
  {id: 3, name: 'Dinner'}, 
  {id: 4, name: 'Festival'}, 
  {id: 5, name: 'Meeting'}, 
  {id: 6, name: 'Party'}, 
  {id: 7, name: 'Retreat'}, 
  {id: 8, name: 'Wedding'},
  {id: 9, name: 'Other'}
];
export const EVENT_TYPE = {
    public: 'Public',
    private: 'Private'
}
export const EVENT_STATUS = {
    open: 'Open',
    rejected: 'Rejected',
    in_progress: 'In Progress',
    approved: 'Approved',
    active: 'Active',
    complete: 'Complete',
    delete: 'Delete',
}
export const EVENT_PAID_TYPE = {
    free: 'Free',
    paid: 'Paid'
}
