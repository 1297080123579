import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, withRouter, useParams, useHistory } from "react-router-dom";
import { config } from "../../../config";
import axiosApi from "../../../core/interceptor/interceptor";
import '../../league/League.css';
import { EVENT_PAID_TYPE, EVENT_TYPE, formatNumberToCurrency } from "../../../utils/Utils";
import coverPic from '../../../assets/images/cover-pic2.jpg';
import WarningDialogue from "../../../utils/WarningDialogue";
import Popup from "reactjs-popup";
import Payment from "../../events/Payment/Payment";
import bellicon from '../../../assets/icons/bell.svg';
import checked from '../../../assets/icons/checked.svg';
import uploadDocIcon from '../../../assets/icons/upload-doc.svg';
import refund from '../../../assets/icons/refunded.svg';

function LeadDetail(props) {
  const history = useHistory();
  const [openPayment, setOpenPayment] = useState(false);
  const [paymentAmounts, setPaymentAmounts] = useState({
    total: 0,
    stripe: 0,
    fee: 0
  });
  const [invoice, setInvoice] = useState(false);
  const coverBgImg = {
    backgroundImage: "url(" + coverPic + ")",
  };
  const id = useParams().id;
  const [lead, setLead] = useState('');
  const [tab, setTab] = useState('details');
  const [openWarningPopup, setOpenWarningPopup] = useState(false);
  const [popupContent, setPopupContent] = useState({});
  const [newDoc, setNewDoc] = useState({
    doc: false,
    invoice: false
  });

  const getLeadDetail = (id, controller, type) => {
    axiosApi.get(`${config.apiUrl}/${controller}/${type}/${id}`)
      .then((res) => {
        if (res && res.data && res.data.data) {
          const resp = res.data.data;
          setLead(resp);
          if (resp.leads_documents && resp.leads_documents.length) {
            const isSeen = resp.leads_documents.some(element => element.is_seen === 0);
            setNewDoc((prev) => ({
              ...prev,
              doc: isSeen
            }));
          }
          if (resp.event_invoices && resp.event_invoices.length) {
            const isSeen = resp.event_invoices.some(element => element.is_seen === 0);
            setNewDoc((prev) => ({
              ...prev,
              invoice: isSeen
            }));
          }
        } else {
          history.push("/my-account");
        }
      })
  }

  useEffect(() => {
    getData(id);
    // eslint-disable-next-line
  }, [id]);

  const getData = (id) => {
    if (id) {
      if (props && props.location && props.location.state && props.location.state?.lead_type === 'event') {
        getLeadDetail(id, 'events', 'event');
      } else {
        getLeadDetail(id, 'leads', 'lead');
      }
    }
  }
  const onFileChange = (e) => {
    if (e.target.files && e.target.files.length) {
      let data = new FormData();
      data.append('name', e.target.files[0].name);
      if (props && props.location && props.location.state && props.location.state?.lead_type === 'event') {
        data.append('event_id', id);
      } else {
        data.append('lead_id', id);
      }
      data.append('created_by', 'user');
      data.append('lead_type', 'lead');
      data.append('file', e.target.files[0]);
      data.append('is_seen', 1);
      data.append('type', 'local');

      axiosApi.post(`${config.apiUrl}/leads/doc`, data)
        .then((res) => {
          if (res && res.data && res.data.data) {
            if (lead && lead.leads_documents) {
              const temp = [...lead.leads_documents];
              temp.push(res?.data?.data);
              setLead((pr) => ({
                ...pr,
                leads_documents: temp
              }));

            }
          }
        })
    }
  }

  const sendDocToAdmin = (doc) => {
    setOpenWarningPopup(true);
    setPopupContent({
      header: 'Confirm',
      description: 'Are you sure you want to send this document?',
      icon: '',
      type: 'confirm',
      action: () => {
        const data = {};
        data.doc_name = doc?.doc_name;
        data.lead_type = props && props.location && props.location.state && props.location.state?.lead_type === 'event' ? 'event' : 'lead';
        data.type = doc?.type;
        axiosApi.post(`${config.apiUrl}/leads/send-doc/${doc.id}`, data)
          .then((res) => {
            if (res) {
              if (props && props.location && props.location.state && props.location.state?.lead_type === 'event') {
                getLeadDetail(id, 'events', 'event');
              } else {
                getLeadDetail(id, 'leads', 'lead');
              }
              setOpenWarningPopup(false);
            }
          })
          .catch((err) => {
            setOpenWarningPopup(false);
          })
      }
    });
  }

  const showSuccessMessage = (message) => {
    getData(id);
  }

  const paynow = (invoice) => {
    setOpenPayment(true);
    setInvoice(invoice);
    setPaymentAmounts({
      total: invoice.total
    })
  }

  useEffect(async () => {
    if (tab === 'documents') {
      if (lead && lead.leads_documents && lead.leads_documents.length) {
        const ids = lead.leads_documents.filter(val => val.is_seen === 0).map(e => e.id);
        if (ids && ids.length) {
          await axiosApi.post(`${config.apiUrl}/events/read-notification/${tab}`, ids)
            .then((res) => {
              lead.leads_documents.forEach(element => {
                element.is_seen = 1;
              });
            })
        }
      }
      setNewDoc((prev) => ({
        ...prev,
        doc: false,
      }))
    }
    if (tab === 'invoices') {
      if (lead && lead.event_invoices && lead.event_invoices.length) {
        const ids = lead.event_invoices.filter(val => val.is_seen === 0).map(e => e.id);
        if (ids && ids.length) {
          await axiosApi.post(`${config.apiUrl}/events/read-notification/${tab}`, ids)
            .then((res) => {
              lead.event_invoices.forEach(element => {
                element.is_seen = 1;
              });
            })
        }
      }
      setNewDoc((prev) => ({
        ...prev,
        invoice: false,
      }))
    }
    // eslint-disable-next-line
  }, [tab])

  return <>
    <section>
      <div className="acc-top-bar">
        <div className="ns-page-title">
          <h2>{lead?.title ? lead?.title : 'N/A'}</h2>
        </div>
        <div className="breadcrumbs">
          <ul>
            <li><Link to={`/my-account/events`}>My Events</Link></li>
            <li>{lead?.title ? lead?.title : 'N/A'}</li>
          </ul>
        </div>
      </div>
      <div className="acc-main-container">
        <div className="fundraiser-page">
          <div className="main-top-tabs with-btn">
            <ul>
              <li onClick={() => setTab('details')} className={`${tab === 'details' ? 'active' : ''}`}>Event Details</li>
              <li onClick={() => setTab('documents')} className={`${tab === 'documents' ? 'active' : ''}${newDoc.doc ? ' notify-dot' : ''}`}>Documents {(newDoc.doc) ? <img className="bell-icon" src={bellicon} alt='' /> : ''}</li>
              <li onClick={() => setTab('invoices')} className={`${tab === 'invoices' ? 'active' : ''}${newDoc.invoice ? ' notify-dot' : ''}`} >Invoices {(newDoc.invoice) ? <img className="bell-icon" src={bellicon} alt='' /> : ''}</li>
            </ul>
            {tab === 'documents' && <label className="btn upload-btn"><img src={uploadDocIcon} alt="" />Upload doc<input onChange={(e) => onFileChange(e)} type="file"></input></label>}
          </div>
          {tab === 'details' && <><div className="cover-pic-section" style={coverBgImg}>
            <div className="cover-left-side">
              <h3>{lead?.title ? lead?.title : 'N/A'}</h3>
              <p>{lead?.start_date_time && lead?.end_date_time ? `${moment(lead?.start_date_time).format('ll, h:mm a')} - ${moment(lead?.end_date_time).format('ll, h:mm a')}` : 'N/A'}</p>
            </div>
            {lead && lead?.ticket_price && lead?.type === 'public' ?
              <div className="cover-right-side">
                <h1>{lead?.ticket_price ? formatNumberToCurrency(lead?.ticket_price) : '$0.00'}</h1>
              </div>
              :
              ''
            }
          </div>
            <div className="cover-desc">
              <p dangerouslySetInnerHTML={{ __html: lead?.detail }}>
              </p>
            </div>
            <div className="event-info-cards">
              <div className="grid-col g-3 l-border">
                <div className="event-info-card">
                  <span>Event Type</span>
                  <h2>{lead?.type ? EVENT_TYPE[lead?.type] : 'N/A'}</h2>
                </div>
                <div className="event-info-card">
                  <span>Occasion</span>
                  <h2>{lead?.occasion ? lead?.occasion : 'N/A'}</h2>
                </div>
                <div className="event-info-card">
                  <span>Budget</span>
                  <h2>{lead?.budget ? formatNumberToCurrency(lead?.budget) : '$0.00'}</h2>
                </div>
                {/* <div className="event-info-card">
                <span>No of Guests/Tickets</span>
                <h2>{lead?.no_of_guest ? lead?.no_of_guest : '0'}</h2>
              </div> */}
              </div>

              <div className="grid-col g-3">
                <div className="event-info-card">
                  <span>Venue</span>
                  <h2>{lead && lead?.event_venue ? lead?.event_venue?.name : 'N/A'}</h2>
                </div>
                <div className="event-info-card">
                  <span>No of Guests/Tickets</span>
                  <h2>{lead?.no_of_guest ? lead?.no_of_guest : '0'}</h2>
                </div>
                <div className="event-info-card">
                  <span>Paid Type</span>
                  <h2>{lead?.paid_type ? EVENT_PAID_TYPE[lead?.paid_type] : 'N/A'}</h2>
                </div>
              </div>

              <div className="grid-col g-3">
                {/* <div className="event-info-card">
                  <span>Contact Person Name</span>
                  <h2>{lead?.client_name ? lead?.client_name : 'N/A'}</h2>
                </div>
                <div className="event-info-card">
                  <span>Email</span>
                  <h2>{lead?.client_email ? lead?.client_email : 'N/A'}</h2>
                </div>
                <div className="event-info-card">
                  <span>Phone</span>
                  <h2>{lead?.client_phone ? lead?.client_phone : 'N/A'}</h2>
                </div> */}
                {/* <div className="event-info-card">
                  <span>Company</span>
                  <h2>Lorem ipsum available</h2>
                </div> */}
              </div>
            </div></>}

          {/**
           * 
           * Document Section
           * 
           */}
          {
            tab === 'documents' &&
            <>
              <div className="event-list-page">
                <div className="ev-row-cards ev-row-style ev-doc-cards">
                  {
                    Array.isArray(lead.leads_documents) && lead.leads_documents.length ?
                      lead.leads_documents.map((element, i) => {
                        const docUrl = element.type === 'local' ? `${config.api}/${config.imageFolder}/${element && element.event_id ? 'events' : 'leads'}/${element.doc_name}` : `${config.api}/doc/event-documents/${element.doc_name}`
                        return <div className="ev-row-card" key={i}>
                          <div className="ev-info-side">
                            <div className={`ev-text`}>
                              <h3 className={`${!element.is_seen ? 'notify-dot' : ''}`}>{element?.doc_name}</h3>
                              {/* <p>{moment(element?.sent_date).format('ll')}</p> */}
                            </div>

                          </div>
                          <div className="ev-card-right">
                            <div className="ev-price">
                              <p>{element && element.created_by === 'user' ? 'Uploaded on' : 'Received on'}</p>
                              <span>{moment(element && element.created_by === 'user' ? element?.created_at : element?.sent_date).format('ll')}</span>
                            </div>
                            <div className="ev-price">
                              <p>Uploaded by</p>
                              <span>{element && element.created_by === 'user' ? 'Me' : 'Mulberry'}</span>
                            </div>
                            <div className="ev-card-actions">
                              <span onClick={() => window.open(docUrl)} className="btn">
                                View Doc
                              </span>
                              {element && element.created_by === 'user' &&
                                <span onClick={() => sendDocToAdmin(element)} className="btn">
                                  Send to Mulberry
                                </span>
                              }
                            </div>
                          </div>
                        </div>
                      })
                      :
                      <div className="noRecord-found">
                        <p>No Record Found</p>
                      </div>
                  }
                </div>
              </div>
            </>
          }
          {/**
           * 
           * Invoices Section
           * 
           */}
          {
            tab === 'invoices' &&
            <>
              <div className="event-list-page">
                <div className="ev-row-cards ev-row-style ev-invoices-cards">
                  {
                    Array.isArray(lead.event_invoices) && lead.event_invoices.length ?
                      lead.event_invoices.map((element, i) => {
                        return <div className="ev-row-card" key={i}>
                          <div className="ev-info-side">
                            {/* <div className="ev-img">
                              <img src={coverPic} alt="" />
                            </div> */}
                            <div className={`ev-text`}>
                              <h3 className={`${!element.is_seen ? 'notify-dot' : ''}`}> {element?.name}</h3>
                              {/* <p>{moment(element?.sent_date).format('ll')}</p> */}
                            </div>
                          </div>
                          <div className="ev-card-right">
                            <div className="ev-price">
                              <p>Invoice Number </p>
                              <span>{element?.invoice_number}</span>
                            </div>
                            <div className="ev-price">
                              <p>Received on</p>
                              <span>{moment(element?.sent_date).format('ll')}</span>
                            </div>
                            <div className="ev-price">
                              <p>Due Date</p>
                              <span>{moment(element?.due_date).format('ll')}</span>
                            </div>
                            <div className="ev-price">
                              <p>Total amount</p>
                              <span>{formatNumberToCurrency(element.total)}</span>
                            </div>

                            {
                              element.status === 'paid' && element.paid_date &&
                              <div className="ev-price">
                                <p>Paid On</p>
                                <span>{moment(element.paid_date).format('ll hh:mm A')}</span>
                              </div>
                            }

                            <div className="ev-card-actions">
                              <span onClick={() => window.open(`${config.api}/doc/invoices/${element?.file_name}`)} className="btn">
                                View Invoice
                              </span>

                              {/* {
                                element.status !== 'paid' &&
                                <span className="btn" onClick={() => {
                                  paynow(element)
                                }}>
                                  Pay Now
                                </span>
                              } */}
                              {
                                element.status !== 'refunded' ?
                                  <>
                                    <span className={`btn with-icon pay-btn ${element.status === 'paid' ? 'paid' : ''}`} onClick={() => { if (element.status !== 'paid') { paynow(element) } }}>
                                      <img className="paid-icon" src={checked} title="Paid" alt="" />
                                      {element.status === 'paid' ? 'Paid' : 'Pay Now'}</span>

                                  </>
                                  :
                                  <span className={`btn with-icon pay-btn paid`} >
                                    <img className="paid-icon" src={refund} title="Paid" alt="" />
                                    Refunded
                                  </span>
                              }
                            </div>
                          </div>
                        </div>
                      })
                      :
                      <div className="noRecord-found">
                        <p>No Record Found</p>
                      </div>
                  }
                </div>
              </div>
            </>
          }

          {
            <Popup
              open={openPayment}
              onClose={() => {
                setOpenPayment(false)
              }}
              type={'event'}
              closeOnDocumentClick={false}
              className={`stripe-popup stripePayment`}
            >
              <Payment showSuccessPopup={showSuccessMessage} payment={paymentAmounts} invoice={invoice} type={'invoice'} closeModal={() => setOpenPayment(false)} />
            </Popup>
          }
        </div>
      </div>
    </section>
    <WarningDialogue
      isOpen={openWarningPopup}
      header={popupContent.header}
      description={popupContent.description}
      action={popupContent.action}
      onClose={() => setOpenWarningPopup(false)}
      closeOnDocumentClick={true}
    />
  </>
}
export default withRouter(LeadDetail);