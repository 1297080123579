import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "react-router-dom";
import arrowRight from "../../../assets/icons/arrowRight.svg";
import { HOSTED_BY, config } from "../../../config";
import axiosApi from "../../../core/interceptor/interceptor";
import { capitaLizeString } from "../../../utils/Utils";
import "../Account.css";
export default function MyTeams({ match }) {
    const [myTeams, setMyTeams] = useState([]);
    const [sponsors, setSponsors] = useState([]);
    const [teams, setTeams] = useState([]);
    useEffect(() => {
        axiosApi.get(`${config.apiUrl}/team/Player`).then((res) => {
            setTeams(res.data.data);
        });
    }, []);
    const handleAdClick = (ad) => {
        if (ad && ad.hyper_link) {
            window.open(`${ad.hyper_link}`, '_blank')
        }
    }

    /*** Sponsor Carousel */
    const sponsorCarousel = () => {
        return <>
            {
                sponsors && sponsors.length > 0 &&
                <div className="sponsors-block in-cards">
                    <div className="cs-row align-middle">
                        <div className="c-12">
                            <Carousel
                                autoPlay={true}
                                emulateTouch={false}
                                className="advt-carousel"
                                infiniteLoop={true}
                                interval={3000}
                                showThumbs={false}
                                showArrows={false}
                                showStatus={false}>
                                {
                                    sponsors && sponsors.length &&
                                    sponsors.map((ad, idx) => (
                                        <div key={ad && ad.id ? ad.id : idx} className="slider-sponsor" onClick={() => handleAdClick(ad)}>
                                            <img src={`${config.api}/${config.imageFolder}/sponsors/${ad.image}`} alt="" />
                                        </div>
                                    ))
                                }
                            </Carousel>
                        </div>
                    </div>
                </div>
            }
        </>
    }
    useEffect(() => {
        if (Array.isArray(teams) && teams.length) {
            teams.sort((a, b) => b.league?.season_id - a.league?.season_id || b.league?.id - a.league?.id);
            const teams_ = teams.map((team, index) => (
                <>
                    <div className="league-card um-cards league-um-cards-rs" key={`my-teams-${index}`}>
                        <div className="league-card-header">
                            <h3>{team?.name}</h3>
                        </div>
                        <div className="league-card-body border-bottom-unset">
                            <div className="league-card-items">
                                <div className="league-card-item">
                                    <p className="legue-detail-title">League:</p>
                                    <p className="league-detail-info">{team?.league?.name}</p>
                                </div>
                                <div className="league-card-item">
                                    <p className="legue-detail-title">Members:</p>
                                    <p className="league-detail-info">{team?.players.length}</p>
                                </div>
                                <div className="league-card-item">
                                    <p className="legue-detail-title">Hosted By:</p>
                                    <p className="league-detail-info">{team?.league?.hosted_by === HOSTED_BY ? 'Mulberry' : 'Cleveland'}</p>
                                </div>
                                <div className="league-card-item">
                                    <p className="legue-detail-title">Status:</p>
                                    <p className="league-detail-info">{team?.status ? capitaLizeString(team.status) : 'N/A'}</p>
                                </div>
                            </div>
                        </div>
                        <div className="league-card-actions">
                            <Link className="btn btn-dark-gray" to={{
                                pathname: `${match.url}/teams/${team?.id}`,
                                state: {
                                    from: 'account',
                                }
                            }}>
                                Team Info
                                <img src={arrowRight} alt="" />
                            </Link>
                        </div>
                    </div>
                </>
            ));
            setMyTeams(teams_);
        }
        // eslint-disable-next-line
    }, [teams, sponsors]);

    useEffect(() => {
        axiosApi.get(`${config.apiUrl}/misc/sponsors/mulberry`)
            .then((res) => {
                setSponsors(res?.data?.data);
            })
    }, [])
    return <>
        <div className="acc-top-bar with-btn">
            <div className="ns-page-title"><h3>MY TEAMS</h3></div>
        </div>
        <div className="acc-main-container">
            <div className={`upcoming-matches-page with-ads ${sponsors?.length && !myTeams.length ? 'nr-ad' : ''} `}>
                {myTeams?.length ? <><div className="league-cards">{myTeams}</div> {sponsorCarousel()}</> : ''}
                {!myTeams?.length ? <><div className="noRecord-found">
                    <p>No Record Found</p>

                </div> {sponsorCarousel()}</> : ''}
            </div>
        </div>
    </>
}
