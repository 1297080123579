import React, { useEffect, useState } from 'react';
import SimpleBarReact from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
export default function CustomSelect(props) {
  const unique = 'custom-select-';
  const [optionOpen, setOptionOpen] = useState(false);
  const [data, setData] = useState([]);
  const [label, setLabel] = useState('Label');
  const [placeholder, setPlaceholder] = useState('Select...');
  const [type, setType] = useState(null);
  const [selector, setSelector] = useState('title');
  const [selectedData, setSelectedData] = useState(null);
  const [key, setKey] = useState(unique);

  useEffect(() => {
    setData(props.data || []);
    setLabel(props.label || '');
    setPlaceholder(props.placeholder || 'Select State');
    setType(props.type || null);
    setSelector(props.selector || 'title');
    if (props.uniqueKey) {
      setKey(props.uniqueKey || unique);
    }
     //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setData(props.data || []);
  }, [props.data]);

  useEffect(() => {
    setPlaceholder(props.placeholder);
  }, [props.placeholder]);

  useEffect(() => {
    if (type) {
      props.onSelect(selectedData, type);
    } else {
      props.onSelect(selectedData);
    }
    setOptionOpen(false);
    //eslint-disable-next-line
  }, [placeholder]);

  return (
    <>
      {
        optionOpen &&
        <div onClick={() => setOptionOpen(o => !o)}
          style={
            {
              position: 'fixed',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 0,
            }
          }>
        </div>
      }
      <div className={`${props.className ? props.className + ' ' : ''}input-field`}>
        {label && <label>{label}{props.required ? <sup>*</sup> : ''}</label>}
        <div className="dropdown-blk2 custom-select">
          <div className="dropdown-header" onClick={() => setOptionOpen(o => !o)}>
            <span>{placeholder}</span>
          </div>

          {optionOpen && <ul className={`dropdown-menu${!data.length ? " noRecord" : ""}`}>
            <SimpleBarReact style={{
              maxHeight: props?.noMaxHeight ? 'unset': 120
            }}>
              {
                data.length
                  ?
                  (
                    data.map((d, idx) => {
                      return (
                        <li key={key + idx} onClick={() => {
                          setSelectedData(d);
                          setPlaceholder(d[selector]);
                        }}>
                          {d[selector]}
                        </li>
                      );
                    })
                  )
                  :
                  (
                    <li className="noRecord" onClick={() => setOptionOpen(false)}>
                      No data.
                    </li>
                  )
              }
            </SimpleBarReact>
          </ul>}
        </div>
      </div>
    </>
  );
}