import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: null,
  status: 'idle',
};

export const sportsList = createSlice({
  name: 'sports',
  initialState,
  reducers: {
    setSports: (state, action) => {
        state.value = action.payload;
    },
  },
});

export const { setSports } = sportsList.actions;

export const sportsListDetails = (state) => state.sports.value;

export default sportsList.reducer;