import React, { useMemo, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import * as moment from 'moment';
import { config } from "../../../config";
import ReactTooltip from 'react-tooltip'
import CustomSelect from "../../../utils/CustomSelect";

function FullCalendarPopup(props) {
  const calendar = useRef({});
  const [venue, setVenue] = useState(0);
  const [showVenuePopup, setShowVenuePopup] = useState(true);
  const venues = useMemo(() => props.venues, [props.venues]);
  const [filter, setFilters] = useState({
    type: 'month',
    start: moment().startOf('month').format('YYYY-MM-DD'),
    end: moment().endOf('month').format('YYYY-MM-DD'),
    active: true
  });
  const [selectedDateRange, setSelectedDateRange] = useState('');

  const getCalendarData = (venue) => (fetchInfo, successCallback, failureCallback) => {
    const temp = filter;
    temp.start = moment(fetchInfo.start).format('YYYY-MM-DD');
    temp.end = moment(fetchInfo.end).format('YYYY-MM-DD');
    temp.venue_id = typeof(venue) === 'object' ? venue?.id : venue;
    if (temp.venue_id) {
      fetch(`${config.apiUrl}/misc/calendar/events?${new URLSearchParams(temp)}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token') ? localStorage.getItem('token') : ''}`
        },
      })
        .then(response => response.json())
        .then(data => {
          setFilters(temp);
          successCallback(data.data);
        }).catch((e) => console.log(e));

    } else {
      setFilters(temp);
      successCallback([]);
    }
  }

  const refetchEvents = useMemo(() => {
    return getCalendarData(venue);
    // eslint-disable-next-line
  }, [venue])

  const handleSelectRange = (date) => {
    setSelectedDateRange(date);
  }

  const showTimePopup = (e) => {
    console.log('tset', e);
  }

  const handleEventPositioned = (info) => {
    return <>
      <i data-tip data-for={`event-${info.event.id}`}> {info.timeText} {info?.event?.title} </i>
      <ReactTooltip id={`event-${info.event.id}`} aria-haspopup='true'>
        <ul>
          <li><strong>Title:</strong> {info?.event?.title}</li>
          <li><strong>Start:</strong> {info?.event?.extendedProps?.start_date_time ? moment(info?.event?.extendedProps?.start_date_time).format('MM/DD/YYYY hh:mm A') : ''} </li>
          <li><strong>End:</strong> {info?.event?.extendedProps?.end_date_time ? moment(info?.event?.extendedProps?.end_date_time).format('MM/DD/YYYY hh:mm A') : ''} </li>
          {/* <li><strong>Description:</strong> <p dangerouslySetInnerHTML={{ __html: info?.event?.extendedProps?.detail }}></p> </li> */}
        </ul>
      </ReactTooltip>
    </>
  }

  const onSelectVenue = (e) => {
    console.log(e)
    if (e) {
      setVenue(e);
      setShowVenuePopup(false);
    }
  }

  return <>
    {
      !showVenuePopup ?
        <div className="cs-full-cal cal-avail">
          <div className="head-modal-calender">
            <h2>Check Availability</h2>
            <div className="breadcrumbs with-dropdown">
              <div className="custom-select">
                <CustomSelect
                  required={false}
                  // label={'State'}
                  data={venues}
                  placeholder={venue?.name ? venue.name : 'Select Venue'}
                  onSelect={(e) => e && setVenue(e)}
                  selector={'name'}
                />
              </div>
            </div>
            <span className="dismiss-calender" onClick={() => props.onClose(false)}>&times;</span>
          </div>
          <FullCalendar
            ref={calendar}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay'
            }}
            dateClick={(e) => showTimePopup(e)}
            // editable={true}
            dayMaxEvents={true}
            events={
              refetchEvents
            }
            displayEventEnd={true}
            // selectable={true}
            // select={(date) => handleSelectRange(date)}
            eventContent={handleEventPositioned}
          />
          <div className="footer-modal-calender">
            <div className="footer-cal-items">
              <span className="btn btn-dark-gray" onClick={() => props.onClose(false)}>Cancel</span>
              <span className="btn" onClick={() => { props.dateRange(selectedDateRange); props.onClose(false); }}>Continue</span>
            </div>
          </div>
        </div>
        :
        <div className="venue-list">
          <div className="vl-title">
            <h4>Select Venue</h4>
          <span className="close" onClick={() => {setShowVenuePopup(false); props.onClose(false)}}>&times;</span>
          </div>
          <div className="vl-body">
            <CustomSelect
              required={false}
              data={venues}
              placeholder={venue ? venue.name : 'Select Venue'}
              onSelect={(e) => e && onSelectVenue(e)}
              selector={'name'}
            />
          </div>
        </div>
    }


  </>
}

export default withRouter(FullCalendarPopup);