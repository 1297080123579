import React, { useEffect } from "react";
import { useHistory } from "react-router";

export default function LogInGuard({ Component, pathChange }) {
  const history = useHistory();
  useEffect(() => {
    if (!localStorage.getItem('token')) {
      history.push('/');
    }
    //eslint-disable-next-line
  }, []);

  return <Component pathChange={pathChange}/>;
}