import React from 'react';
import MDSpinner from 'react-md-spinner';

export default function Loader() {
  return <>
    <div className="loader">
      <div className="img-wrapper">
        <MDSpinner size={70} singleColor={'var(--pri)'} />
      </div>
    </div>
  </>
}

export function MiniLoader() {
  return <>
      <div className="img-wrapper">
        <MDSpinner size={20} singleColor={'var(--pri)'} />
      </div>
  </>
}