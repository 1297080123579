import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { config } from "../../../config";
import axiosApi from "../../../core/interceptor/interceptor";
import { useSelector } from "react-redux";
import { userDetails } from "../../../store/reducer/LoggedInUserDetails";
import '../../league/League.css';
import { formatNumberToCurrency } from "../../../utils/Utils";

function MyBookings() {
  const userDetail = useSelector(userDetails);
  const history = useHistory();
  const [bookings, setBookings] = useState([]);

  const getMyBookings = () => {
    axiosApi.get(`${config.apiUrl}/events/booking-list`)
      .then((res) => {
        if (res && res.data && res.data.data) {
          setBookings(res.data.data);
        }
      })
  }

  useEffect(() => {
    console.log(userDetail)
    if (userDetail) {
      getMyBookings();
    }
  }, [userDetail])

  return <>
    <div className="acc-top-bar with-btn">
      <div className="ns-page-title">
        <h3>My Bookings</h3>
      </div>
    </div>
    <div className="acc-main-container">
      <div className="event-list-page with-new-cols my-ev-cards">
        <div className="ev-row-cards">
          {
            Array.isArray(bookings) && bookings.length ?
              bookings.map((booking, i) => {
                return <div className="ev-row-card" key={i}>
                  <div className="ev-info-side">
                    <div className="ev-text">
                      <h3>{booking?.event?.title ? booking?.event?.title : 'N/A'}</h3>
                      <p>{booking?.event?.start_date_time && booking?.event?.end_date_time ? `${moment(booking?.event?.start_date_time).format('ll, hh:mm a')} - ${moment(booking?.event?.end_date_time).format('ll, hh:mm a')}` : 'N/A'}</p>
                    </div>
                    <div className="ev-more-info">
                      <h3>{ booking?.event_tickets && booking?.event_tickets.length }</h3>
                      <p>No Of Tickets</p>
                    </div>
                    
                    <div className="ev-more-info">
                      <h3>{booking?.event?.occasion ? booking?.event?.occasion : 'N/A'}</h3>
                      <p>Occasion</p>
                    </div>
                    
                    <div className="ev-more-info">
                      <h3>{ booking?.total_price ? formatNumberToCurrency(booking?.total_price) : '$0.00' }</h3>
                      
                      <p>Total Price</p>
                    </div>
                  </div>
                  <div className="ev-card-right">
                    <div className="ev-card-actions">
                      <button
                        onClick={() => history.push(`/events/event-details/${booking?.event_id}`)}
                        className="btn"
                      >
                        View Detail
                      </button>
                    </div>
                  </div>
                </div>
              })
              :
              <div className="noRecord-found">
                <p>No Record Found</p>
              </div>
          }
        </div>
      </div>
    </div>
  </>
}
export default withRouter(MyBookings);