import React, { useEffect, useState } from "react";
import axiosApi from "../../../core/interceptor/interceptor";
import { config } from "../../../config";
import "./LeagueDetails.css";
import "../League.css";
import moment from "moment";
import backArrow from "../../../assets/images/sidearrow.svg";
import backArrowGreen from "../../../assets/icons/backArrowGreen.svg";
import rightArrowGreen from "../../../assets/icons/rightArrowGreen.svg";
import rightArrowPri from "../../../assets/icons/arrowRight-pri.svg";
import { Link, withRouter, useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { userDetails } from "../../../store/reducer/LoggedInUserDetails";
import { toTitleCase } from "../../../utils/Utils";
  import Popup from "reactjs-popup";
import Login from "../../common/login/Login";
import Signup from "../../common/signup/signup";
import LeagueDivision from "./LeagueDivision";
import LeagueSchedule from "./LeagueSchedule";
import SimpleBarReact from "simplebar-react";
import WarningDialogue from "../../../utils/WarningDialogue";
import PlayOffBracket from "../Brackets/PlayOffBrackets";

function LeagueDetails(props) {
  const sportId = useParams().sportId;
  const [leagueData, setLeagueData] = useState(null);
  const [login, setLogIn] = useState(false);
  const [signup, setSignUp] = useState(false);
  const isLoggedIn = useSelector(userDetails);
  const history = useHistory();
  const id = useParams().id;

  const [showWarning, setShowWarning] = useState(false);
  useEffect(() => {
    if (id) {
      getLeagues();
    }
    //eslint-disable-next-line
  }, [id]);

  const getLeagues = () => {
    axiosApi
      .get(`${config.apiUrl}/leagues/league-details/${id}`)
      .then((response) => {
        if (response.data && response.data.data) {
          setLeagueData(response.data.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const registerTeam = () => {
    const url = props.location.state?.from === 'indv' ? `/league/individual-registration/${leagueData?.id}` : `/league/team-registration/${leagueData?.id}/${sportId}`;
    history.push(url, {
      from: props.location.state?.from === "indv" ? "indv" : "",
    });
  };

  return (
    <>
      <main className="wrapper-main">
        <section className="main-side no-sidebar">
          <div className="main-side-container">
            <div className="main-top-headings">
              <div className="league-back-link">
                <a href="#" onClick={(e) => {
                  e.preventDefault();
                  const url = props.location.state?.from === 'history' ? '/my-account/league-history/' : props.location.state?.from === 'account' ? '/my-account' : props.location.state?.from === 'indv' ? `/league/team/${leagueData.sport_id}/individual` : props.location.state?.from === 'home' ? '/' : `/league/team/${sportId}/${leagueData?.status === 'active'? 'active' : leagueData?.status === 'complete' ? 'archived' : 'sign_up' }`;
                  history.push(url, {
                    from: props.location.state?.from,
                  });
                }}>
                  <img src={backArrowGreen} alt="" />
                  Back
                </a>
                {leagueData?.status === 'sign_up' && <a href="#" onClick={(e) => {
                  e.preventDefault();
                  if (Array.isArray(leagueData.teams) && leagueData.teams.length >= leagueData.team_size) {
                    return setShowWarning(true);
                  }
                  isLoggedIn ? registerTeam() : setLogIn(true)
                }} className="link-btn pri-link">
                  Register Now
                  <img src={rightArrowPri} alt="" />
                </a>}
              </div>
            </div>
            <div className="main-side-body">
              <div className="league-detail-header">
                <h2 className="league-detail-heading">
                  {`${toTitleCase(leagueData?.sport?.title)} - ${toTitleCase(leagueData?.name)}`}
                </h2>
              </div>
              {['active', 'complete'].includes(leagueData?.status) && <LeagueDivision />}
              {['active', 'complete'].includes(leagueData?.status) && <LeagueSchedule />}
              {
                ['active', 'complete'].includes(leagueData?.status) &&
                <div className="leagueRules-Block bracket leadue-details-brackets">
                  <div>
                    <PlayOffBracket leagueId={id} />
                  </div>
                </div>
              }
              <div className="league-information-sec">
                <div className="league-info-header">
                  <h2>League Information</h2>
                </div>
                <div className="league-info-body">
                  <div className="league-info-item">
                    <span>Day Of Week</span>
                    <p>{
                      leagueData && Array.isArray(leagueData.days_played) ? leagueData.days_played.map((obj, idx) => (
                        obj.day + (idx < leagueData.days_played.length - 1 ? ', ' : '')
                      )) : 'N/A'
                    }</p>
                  </div>
                  <div className="league-info-item">
                    <span>Location</span>
                    <p>{
                      leagueData && Array.isArray(leagueData.league_locations) && leagueData.league_locations.length ?
                        leagueData.league_locations.map((loc, idx) => {
                          if (loc.location) {
                            return loc.location.title + (idx < leagueData.league_locations.length - 1 ? ', ' : '');
                          }
                          return 'N/A';
                        }) : 'N/A'
                    }</p>
                  </div>
                  <div className="league-info-item">
                    <span>Registration Deadline</span>
                    <p>{leagueData && leagueData.reg_deadline ? moment(leagueData.reg_deadline, 'YYYY-MM-DD').format('MM/DD/YYYY') : 'N/A'}</p>
                  </div>
                  <div className="league-info-item">
                    <span>League Start Date</span>
                    <p>{leagueData && leagueData.start_date ? moment(leagueData.start_date, 'YYYY-MM-DD').format('MM/DD/YYYY') : 'N/A'}</p>
                  </div>
                  <div className="league-info-item">
                    <span>Level of Play</span>
                    <p>{leagueData && leagueData.play_level ? toTitleCase(leagueData.play_level) : 'N/A'}</p>
                  </div>
                  <div className="league-info-item">
                    <span>{props.location.state?.from === 'indv' ? 'Individual ' : ''}Registration Fee</span>
                    <p>{
                      leagueData &&
                        (leagueData.reg_fees || leagueData.indv_reg_fees)
                        ? `$${(props.location.state?.from === 'indv' ? leagueData.indv_reg_fees : leagueData.reg_fees) || 0}`
                        : 'N/A'
                    }</p>
                  </div>
                  <div className="league-info-item">
                    <span>Elimination Type</span>
                    <p>{leagueData && leagueData.elimination_type ? leagueData.elimination_type : 'N/A'}</p>
                  </div>
                  <div className="league-info-item">
                    <span>Season Length</span>
                    <p>{leagueData && leagueData.no_of_weeks ? leagueData.no_of_weeks + ` Week${leagueData.no_of_weeks > 1 ? 's' : ''}` : 'N/A'}</p>
                  </div>
                  <div className="league-info-item">
                    <span>Team Composition</span>
                    <p>{
                      (function (data) {
                        let text = '';
                        if (data) {
                          if (leagueData.men_per_team) {
                            text += `${leagueData.men_per_team} Guy${leagueData.men_per_team > 1 ? 's' : ''} `;
                          }
                          if (leagueData.women_per_team) {
                            text += `${leagueData.women_per_team} Girl${leagueData.women_per_team > 1 ? 's' : ''}`;
                          }
                        }
                        if (text) {
                          return text;
                        }
                        return 'N/A';
                      })(leagueData)
                    }</p>
                  </div>

                  <div className="league-info-item league-info-times">
                    <span>Times</span>
                    <p>{leagueData && leagueData.time_slots ? (leagueData.time_slots).split(',').join(', ') : 'N/A'}</p>
                  </div>
                </div>

                <div className="league-rules">
                  <div className="league-rules-header">
                    <h2>
                      League Rules
                    </h2>
                  </div>
                  <div className="league-rules-body">
                    <span className="league-rule-of">{leagueData && leagueData.sport && leagueData.sport.title ? toTitleCase(leagueData.sport.title) : 'N/A'}</span>
                    <div className="league-rules-items">
                      <div className="league-rules-item">
                        {/* <div className="league-rule-name">
                          <h4>{`${toTitleCase(leagueData?.sport?.title)} - ${toTitleCase(leagueData?.name)}`}</h4>
                        </div> */}
                        {/* <ul>
                          <li>
                            <p>Rally Scoring</p>
                            <p>3 Games To 21 Cap 23</p>
                          </li>
                          <li><p>Let Serves</p></li>
                          <li><p>self Referee'd</p></li>
                          <li><p>
                            Overhand Receive - Serves And Driven Balls Only
                            (Doubles Are Legal Carries And Lifts Are Not Legal)
                          </p></li>
                          <li><p>
                            No Dinks (Knuckle, Fist Or Roll Shots Are Legal)
                          </p></li>
                          <li><p>No Sets Over</p></li>
                          <li><p>no Nets</p></li>
                          <li><p>all Disputes Resolved With Replay</p></li>
                          <li><p>Other Rules Follow Usav</p></li>
                        </ul> */}
                        {leagueData && leagueData.sport && leagueData.sport.rules ?
                          <p dangerouslySetInnerHTML={{
                            __html: toTitleCase(leagueData.sport.rules)
                          }} style={{ fontSize: '15px', fontFamily: 'proxima_nova_reg' }} ></p> : <p>N/A</p>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Popup
        open={login}
        onClose={() => {
          setLogIn(false);
        }}
        closeOnDocumentClick={true}
      >
        <Login
          closeLoginModal={(resp) => {
            if (resp) {
              registerTeam();
            }
            setLogIn(false);
          }}
          login={true}
          openSignUp={() => setSignUp(true)}
        ></Login>
      </Popup>
      <Popup
        open={signup}
        onClose={() => {
          setSignUp(false);
        }}
        closeOnDocumentClick={false}
      >
        <Signup
          closeSignupModal={() => setSignUp(false)}
          openLogin={() => setLogIn(true)}
        ></Signup>
      </Popup>
      <WarningDialogue
        isOpen={showWarning}
        header={"Alert"}
        description={
          <>
            <span>Are you sure, you want to register in this league?</span>
            <br />
            <span>You'll be moved to the wait list.</span>
            <br/>
            <i className="wait-list-note"><strong>Note:</strong> Your card will not be charged if left on the wait list.</i>
          </>
        }
        action={() => {
          setShowWarning(false);
          isLoggedIn ? registerTeam() : setLogIn(true);
        }}
        onClose={() => setShowWarning(false)}
        backdrop={true}
      />
    </>
  );
}

export default withRouter(LeagueDetails);
