import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router";
import { config } from "../../../config";
import axiosApi from "../../../core/interceptor/interceptor";
import CustomSelect from "../../../utils/CustomSelect";
import SimpleBarReact from "simplebar-react";
import "./LeagueDetails.css";
import arrowSmRight from "../../../assets/icons/ArrowAngRight.svg";
import arrowSmLeft from "../../../assets/icons/ArrowAngLeft.svg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function LeagueSchedule(props) {
  const id = useParams().id;
  const [scheduleData, setScheduleData] = useState([]);
  const [teams, setTeams] = useState({});
  const [week, setWeek] = useState(1);
  const [fileds, setFields] = useState([]);
  const [locations, setLocations] = useState({});
  const [weekList, setWeekList] = useState();
  const sliderRef = useRef(null);
  const [weekListLabels, setWeekListLabels] = useState();

  useEffect(() => {
    getLeagueDetails();
    // eslint-disable-next-line
  }, []);
  
  useEffect(() => {
    const arr = [];
    if (weekList > 0 && weekListLabels) {
      for (let index = 0; index < weekList; index++) {
        arr.push({ id: index + 1, name: `Week ${index + 1}` });
      }
    }
    setWeekListLabels(arr);
  }, [weekList]);

  useEffect(() => {
    getWeeklyMatchupSchedule();
    // eslint-disable-next-line
  }, [week]);

  function getWeeklyMatchupSchedule() {
    axiosApi
      .get(`${config.apiUrl}/leagues/active/weekly/matchup/${id}/${week}`)
      .then((res) => {
        setScheduleData(res.data.data);
        props?.isSchedule(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }
  const SlickArrowLeft = ({ ...props }) => (
    <div {...props} className={"sl-arrow arrow-left"}>
      <img src={arrowSmLeft} alt="" />
    </div>
  );
  const SlickArrowRight = ({ ...props }) => (
    <div {...props} className={"sl-arrow arrow-right"}>
      <img src={arrowSmRight} alt="" />
    </div>
  );
  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    slidesToShow: scheduleData.length >= 3 ? 3 : scheduleData.length === 2 ? 2 : 1,
    speed: 500,
    slidesToScroll: 1,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: scheduleData.length >= 2 ? 2 : 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  };
  function getLeagueDetails() {
    axiosApi
      .get(`${config.apiUrl}/leagues/time/team/location/field/${id}`)
      .then((res) => {
        setWeekList(res.data.data.no_of_weeks || null);
        if (res.data?.teamData?.length) {
          setTeams(
            res.data.teamData.reduce((prev, curr) => {
              prev[curr.id] = curr.name;
              return prev;
            }, {})
          );
        }
        if (Array.isArray(res.data?.locationFields)) {
          setLocations(
            res.data?.locationFields.reduce((prev, curr) => {
              prev[curr.id] = curr.title;
              return prev;
            }, {})
          );
        }
        if (res.data?.weekInfo && res.data.weekInfo.length) {
          const arr = [];
          for (const iterator of res.data.weekInfo) {
            arr.push({ id: iterator, name: `Week ${iterator}` });
          }
          setWeek(res.data.weekInfo[0]);
          setWeekListLabels(arr);
        } else {
          setWeekList(res.data.data.no_of_weeks || null);
        }
        setFields(res.data?.fields || []);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(0);
    }
  }, [week, sliderRef])

  return (
    <>
      <div className="league-schedule">
        <div className="league-sch-select">
          <h4>League Schedule</h4>
          <div className="drop-down">
            <CustomSelect
              required={false}
              data={weekListLabels}
              placeholder={week > 0 ? `Week ${week}` : "Select Week"}
              onSelect={(e) => {
                e && setWeek(e.id);
              }}
              selector={"name"}
              noMaxHeight={true}
            />
          </div>
        </div>
        <div className="league-sc-slider">
          { scheduleData.length > 0 ?
            <Slider {...settings} ref={sliderRef} className="upcoming-match-slider">
            {
              scheduleData.map((schedule, i) => (
                <div className={`slider-slide-item ${schedule?.bye_week ? 'bye-week-blk' : ''} `}>
                  <div className="ls-slider-header">
                    <div className="ls-header-left">{`${teams[schedule?.team1_id]}`} {`${typeof schedule?.team1_score !== 'object' && !schedule?.bye_week ? '('+schedule?.team1_score+')' : ''}`}</div>
                    <span className="vs-division">VS</span>
                    <div className="ls-header-Right">{`${schedule?.team2_id ? teams[schedule?.team2_id] : schedule?.bye_week ? 'Bye this Week' : ''}`} {`${typeof schedule?.team2_score !== 'object' && !schedule?.bye_week ? '('+schedule?.team2_score+')' : ''}`}</div>
                  </div>
                  <div className="ls-slider-body">
                    <div className="ls-slider-body-item">
                      <div className="ls-date-time">
                        <span>Date & Time</span>
                        <p>{moment(schedule?.date, 'YYYY-MM-DD').format('MM/DD/YYYY')}{schedule?.time ? ', ' +moment(schedule?.time, 'HH:mm:ss').format('hh:mm A') : ''}</p>
                      </div>
                    </div>
                    <div className="ls-slider-body-item border-x">
                      <div className="ls-field">
                        <span>Field#</span>
                        <p>{(function (id) {
                          const field = fileds.find(field => field?.id === id);
                          if (field) {
                            return field.title;
                          }
                          return 'N/A';
                        }(schedule?.field_id))}</p>
                      </div>
                    </div>
                    <div className="ls-slider-body-item">
                      <div className="ls-location">
                        <span>Location</span>
                        <p>{schedule?.location_id ? locations[schedule?.location_id] : 'N/A'}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </Slider>
          : 
            <div>
              No matches scheduled this week, review the next week or scroll down to view the playoff schedule if the regular season has concluded.
            </div>
          }
        </div>
      </div>
    </>
  );
}
