import React, { useEffect, useState } from 'react';
import { Route, withRouter, Redirect } from 'react-router';
import UpcomingMatches from './upcomingMatches/UpcomingMatches';
import './Account.css';
import axiosApi from '../../core/interceptor/interceptor';
import { config } from '../../config';
import LeagueDetails from '../league/LeagueDetails/LeagueDetails';
import Leads from './Leads/Leads';
import LeadDetails from './Leads/LeadDetails';
import TeamsLayout from './myTeams/TeamsLayout';
import AccountMenuSidebar from './Account';
import TeamInvites from './myTeams/TeamInvite/TeamInvites';
import TeamInviteDetails from './myTeams/TeamInvite/TeamInviteDetails';
import Classifieds from './Classifieds/Classifieds';
import ModifyAccount from './modifyAccount/ModifyAccount';
import Bookings from './Bookings/Bookings';
import { useSelector } from "react-redux";
import { userDetails } from "../../store/reducer/LoggedInUserDetails";
import PlayOffMatches from './upcomingMatches/PlayOffMatches';

function AccountLayout(props) {
    const userDetail = useSelector(userDetails);
    const [showNoti, setShowNoti] = useState(false);
    useEffect(() => {
        props?.pathChange('/my-account');
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (userDetail) {
            checkNewNotification(userDetail?.id);
        }
    }, [userDetail]);

    const checkNewNotification = (id) => {
        axiosApi.get(`${config.apiUrl}/events/new-doc/${id}`)
        .then((res) => {
            if (res && res.data && res.data.data) {
                const resp = res.data.data;
                resp.forEach(element => {
                    const docNoti = element.leads_documents.some(doc => (doc.is_seen === 0 && doc.is_sent === 1));
                    const invoiceNoti = element.event_invoices.some(inv => (!inv.is_seen && inv.sent_to_user));
                    if (docNoti || invoiceNoti) {
                        setShowNoti(true);
                        return;
                    }
                });
            }
        })
    }

    const notificationCallback = (cb) => {
        setShowNoti(cb);
    }

    return <>
        <AccountMenuSidebar isNewDoc={showNoti} {...props} />
        <section className={`acc-main-side`}>
            {/* {title[props.location.pathname.split('/').pop()] ? <div className="acc-top-bar with-btn">
                <div class="ns-page-title"><h3>{title[props.location.pathname.split('/').pop()]}</h3></div>
            </div> : ''} */}
            <Route exact={true} path={`${props.match.path}`}>
                <Redirect to={`${props.match.path}/upcoming-matches`} />
            </Route>
            <Route exact={true} path={`${props.match.path}/upcoming-matches`}>
                <UpcomingMatches {...props} />
            </Route>
            <Route path={`${props.match.path}/teams`}>
                <TeamsLayout {...props} />
            </Route>
            <Route path={`${props.match.path}/team-invites`}>
                <Route exact={true} path={`${props.match.path}/team-invites`}>
                    <TeamInvites {...props} />
                </Route>
                <Route exact={true} path={`${props.match.path}/team-invites/:id`}>
                    <TeamInviteDetails {...props} />
                </Route>
            </Route>
            <Route path={`${props.match.path}/my-classifieds`}>
                <Classifieds isHistory={true} {...props} />
            </Route>
            <Route path={`${props.match.path}/league-history`}>
                <UpcomingMatches isHistory={true} {...props} />
            </Route>
            <Route path={`${props.match.path}/profile`}>
                <ModifyAccount {...props} />
            </Route>
            <Route exact={true} path={`${props.match.path}/league-details/:id`}>
                <LeagueDetails />
            </Route>
            <Route exact={true} path={`${props.match.path}/events`}>
                <Leads unRead={notificationCallback} />
            </Route>
            <Route exact={true} path={`${props.match.path}/events/:id`}>
                <LeadDetails />
            </Route>
            <Route exact={true} path={`${props.match.path}/bookings`}>
                <Bookings />
            </Route>
            <Route exact={true} path={`${props.match.path}/playoff-matches/:id`}>
                <PlayOffMatches />
            </Route>
        </section>
    </>
}

export default withRouter(AccountLayout);