import "./home.css";
import React, { useState, useEffect } from "react";
import { Link, useLocation, withRouter } from "react-router-dom";
import banner from "../../assets/images/banner-img.jpg";
import bannerCurve from "../../assets/images/banner-curve.svg";
import pizza from "../../assets/images/pizza.jpg";
import whirl from "../../assets/images/whirl.png";
import star from "../../assets/images/star.png";
import mulberryLogo from "../../assets/images/mulberry-logo.png";
import leaguesImg from "../../assets/images/leagues-img.jpg";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import Signup from "../common/signup/signup";
import Login from "../common/login/Login";
import Popup from "reactjs-popup";
import { Carousel } from "react-responsive-carousel";
import axiosApi from "../../core/interceptor/interceptor";
import { config, Disclaimers, REGISTERED_THROUGH } from "../../config";
import { sportsListDetails } from "../../store/reducer/SportsList";
import { useSelector } from "react-redux";
function Home(props) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: config.googleApi,
  });
  const sportsList = useSelector(sportsListDetails);
  const [sponsors, setSponsors] = useState([]);
  const center = {
    lat: 41.49487,
    lng: -81.70813,
  };

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const mapOptions = {
    panControl: true,
    zoomControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    overviewMapControl: true,
  };

  const [login, setLogIn] = useState(false);
  const [email, setEmail] = useState("");
  const search = useLocation().search;
  const [signup, setSignUp] = useState(
    new URLSearchParams(search).get("signup")
  );
  const [headline, setHeadline] = useState("");
  const handleAdClick = (ad) => {
    if (ad && ad.hyper_link) {
      window.open(`${ad.hyper_link}`, "_blank");
    }
  };

  /*** Sponsor Carousel */
  const sponsorCarousel = () => {
    return (
      <>
        {sponsors && sponsors.length > 0 && (
          <div className="sponsorsNew-block in-cards">
            <div className="cs-row align-middle">
              <div className="c-12">
                <Carousel
                  autoPlay={true}
                  emulateTouch={false}
                  className="advt-carousel"
                  infiniteLoop={true}
                  interval={3000}
                  showThumbs={false}
                  showArrows={false}
                  showStatus={false}
                >
                  {sponsors &&
                    sponsors.length &&
                    sponsors.map((ad, idx) => (
                      <div
                        key={ad && ad.id ? ad.id : idx}
                        className="slider-sponsor"
                        onClick={() => handleAdClick(ad)}
                      >
                        <img
                          src={`${config.api}/${config.imageFolder}/sponsors/${ad.image}`}
                          alt=""
                        />
                      </div>
                    ))}
                </Carousel>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };
  useEffect(() => {
    axiosApi
      .get(
        `${config.apiUrl}/misc/disclaimer/${REGISTERED_THROUGH}/${Disclaimers.site_headline}`
      )
      .then((res) => {
        if (res && res.data && res.data.data) {
          setHeadline(res.data.data.disclaimer);
        }
      });
  }, []);

  useEffect(() => {
    axiosApi.get(`${config.apiUrl}/misc/sponsors/mulberry`).then((res) => {
      setSponsors(res?.data?.data);
    });
  }, []);

  useEffect(() => {
    if (
      props &&
      props.location &&
      props.location.state &&
      props.location.state.status
    ) {
      if (props.location.state.status === "signup") {
        setSignUp(true);
      } else if (props.location.state.status === "login") {
        setLogIn(true);
      }
      setEmail(props.location.state.email ? props.location.state.email : "");
    }
    pathChangeEmit("/");
    //eslint-disable-next-line
  }, [props?.location?.state?.status]);

  const pathChangeEmit = (path) => {
    props.pathChange(path);
  };

  return (
    <>
      <main className="wrapper-main">
        <section className="hero-section">
          <div className="banner-img">
            <img src={banner} alt="banner" />
            <img className="banner-curve" src={bannerCurve} alt="curve" />
          </div>
          <div className="banner-text">
            <div className="container">
              <div className="inner-text">
                <h1>Great Food, Great Fun!</h1>
                <p>
                  The all encompassing place for a great night out. Check out
                  our delicious pizza, join one of our sports leagues or just
                  stop by for a drink! Mulberry's is Cleveland's favorite spot
                  for everything friends and fun.
                </p>
                <div className="btn-block btn-group">
                  <Link
                    to={`/league/team/${
                      sportsList &&
                      sportsList.individual &&
                      sportsList.individual.length
                        ? sportsList.individual[0].id
                        : 0
                    }/individual`}
                    onClick={() =>
                      pathChangeEmit(
                        `/league/team/${
                          sportsList &&
                          sportsList.individual &&
                          sportsList.individual.length
                            ? sportsList.individual[0].id
                            : 0
                        }/individual`
                      )
                    }
                    className="btn"
                  >
                    Individual Registration
                  </Link>

                  <Link
                    to={`/league/team/${
                      sportsList &&
                      sportsList.sign_up &&
                      sportsList.sign_up.length
                        ? sportsList.sign_up[0].id
                        : 0
                    }/sign_up`}
                    onClick={() =>
                      pathChangeEmit(
                        `/league/team/${
                          sportsList &&
                          sportsList.sign_up &&
                          sportsList.sign_up.length
                            ? sportsList.sign_up[0].id
                            : 0
                        }/sign_up`
                      )
                    }
                    className="btn btn-dark-gray"
                  >
                    Team Registration
                  </Link>
                  <Link to={`/today-schedule`} className="btn">Today's Schedule</Link>
                  {/* <button className="btn">Order Now</button> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="mulberry-cleveland">
          <div className="container">
            <div className="mulberry-desc-with-ads">
              <div className="mulberry-desc-text">
                <div className="mulberry-desc">
                  <h2>Mulberry's Cleveland</h2>
                  <div className="text-container">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: headline,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="ad-section">{sponsorCarousel()}</div>
            </div>
          </div>
        </section>
        <section className="homemade-section">
          <div className="container">
            <div className="left">
              <img src={pizza} alt="food" />
            </div>
            <div className="right">
              <h2>Homemade, Oven Fresh.</h2>
              <p>
                We're known for our delicious homemade pizza! Having spent over
                a decade refining our recipe, we know it'll be a home run. Check
                out our full menu and order online or stop by for some great
                live sports entertainment!
              </p>
              {/* <button className="btn">View Menu</button> */}
            </div>
          </div>
        </section>
        <section className="testimonial-section">
          <div className="container">
            <div className="left">
              <h2>
                What People Are Saying
                <img src={whirl} alt="whirl" />
              </h2>
              <div className="rating">
                <img src={star} alt="rating" />
                <img src={star} alt="rating" />
                <img src={star} alt="rating" />
                <img src={star} alt="rating" />
                <img src={star} alt="rating" />
              </div>
              <p>
                Great supreme pizza with an excellent, light, tasty crust and
                side sauce. Good bar with fun atmosphere watching and/or playing
                local volleyball. Cool concept!
              </p>
              <span className="user-name">-Dar</span>
            </div>
            <div className="right">
              <img src={mulberryLogo} alt="mulberryLogo" />
            </div>
          </div>
        </section>
        <section className="leagues-section">
          <div className="container">
            <div className="left">
              <h2>Our Leagues</h2>
              <p>
                Looking to meet new people, get your friends together or just
                stay active? Check out our sports leagues! Leagues are hosted by
                Mulberry's all over the Cleveland area. View all leagues for
                more information on available sports and league details.
              </p>
              <Link
                to={`/league/team/${
                  sportsList && sportsList.sign_up && sportsList.sign_up.length
                    ? sportsList.sign_up[0].id
                    : 0
                }/sign_up`}
                onClick={() => pathChangeEmit("/league")}
                className="btn"
              >
                Register Now
              </Link>
            </div>
            <div className="right">
              <img src={leaguesImg} alt="leagues" />
            </div>
          </div>
        </section>
        <section className="maps-section">
          <div className="left">
            <h2>Hours</h2>
            <ul>
              <li>Sunday: 11 AM - 1 AM</li>
              <li>Monday: 5 PM - 1 AM</li>
              <li>Tuesday: 5 PM - 1 AM</li>
              <li>Wednesday: 5 PM - 1 AM</li>
              <li>Thursday: 5 PM - 1 AM</li>
              <li>Friday: 5 PM - 1 AM</li>
              <li>Saturday: 12 PM - 2 AM</li>
            </ul>
          </div>
          <div className="right">
            {isLoaded && (
              <GoogleMap
                options={mapOptions}
                streetView={false}
                mapContainerStyle={containerStyle}
                center={center}
                zoom={15}
              >
                <Marker
                  position={center}
                  label={"2316 Mulberry Ave, Cleveland OH, 44113"}
                />
              </GoogleMap>
            )}
            <div class="cs-map-dir-btn">
              <a
                href={(() => {
                  if (
                    navigator &&
                    navigator.userAgentData &&
                    navigator.userAgentData.platform &&
                    (navigator.userAgentData.platform.indexOf("iPhone") !== -1 ||
                      navigator.userAgentData.platform.indexOf("iPod") !== -1 ||
                      navigator.userAgentData.platform.indexOf("iPad") !== -1)
                  )
                    return `maps://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${center.lat},${center.lng}`;
                  else
                    return `https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${center.lat},${center.lng}`;
                })()}
                rel="noreferrer"
                target="_blank"
              >
                <button className="btn">Get Directions</button>
              </a>
            </div>
          </div>
        </section>
      </main>
      <Popup
        open={login}
        onClose={() => {
          setLogIn(false);
        }}
        closeOnDocumentClick={true}
      >
        <Login
          loginEmail={email}
          closeLoginModal={() => setLogIn(false)}
          login={true}
          openSignUp={() => setSignUp(true)}
        ></Login>
      </Popup>
      <Popup
        open={signup}
        onClose={() => {
          setSignUp(false);
        }}
        closeOnDocumentClick={false}
      >
        <Signup
          signupEmail={email}
          closeSignupModal={() => setSignUp(false)}
          openLogin={() => setLogIn(true)}
        ></Signup>
      </Popup>
    </>
  );
}
export default withRouter(Home);
