import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { config } from "../../config";
import axiosApi from "../interceptor/interceptor";

export default function PaymentGuard({ Component }) {
  const history = useHistory();
  const id = useParams().id;
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (!localStorage.getItem('token')) {
      history.push('/');
    } else {
      axiosApi.get(`${config.apiUrl}/leagues/league-status/${id}`).then((resp) => {
        setShow(resp.data.data);
        if (!resp.data.data) {
          history.push('/');
        }
      }).catch((err) => {
        history.push('/');
        console.error(err);
      });
    }
    //eslint-disable-next-line
  }, []);

  return <>
    {
      show ? <Component />
        : <>Getting League Details...</>
    }
  </>;
}