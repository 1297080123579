export const config = {
  apiUrl: `${process.env.REACT_APP_API_URL}`,
  api: `${process.env.REACT_APP_API}`,
  imageFolder: `images`,
  stripePublicKey: `${process.env.REACT_APP_STRIPE_KEY}`,
  captchaSitekey: `${process.env.REACT_APP_CAPTCHA_SITE_KEY}`,
  sportImage: `${process.env.REACT_APP_API}/images/sports/`,
  clevelandUrl: `${process.env.REACT_APP_CLEVELAND_BASE_URL}`,
  googleApi: `${process.env.REACT_APP_API_KEY}`,
};

export const HOSTED_BY = `mulberry`;
export const REGISTERED_THROUGH = `${process.env.REACT_APP_REGISTERED_THROUGH}`;

export const Disclaimers = {
	signUp_disclaimer: 'signUp_disclaimer',
	covid_policy: 'covid_policy',
	team_reg_policy: 'team_reg_policy',
	site_headline: 'site_headline',
  league_headline: 'league_headline',
  notification_banner: 'notification_banner'
}
