import React, { useState, useEffect } from 'react';
import { config } from '../../config';
import axiosApi from '../../core/interceptor/interceptor';
import { messages } from '../../messages';
import CustomSelect from '../../utils/CustomSelect';
import { validateEmail, validateRequiredFields } from '../../utils/Utils';
import ErrorInput from '../common/ErrorInput';
import './Contact.css';
import { phoneNumberMask } from "../../utils/Utils";
import InputMask from 'react-input-mask';
import { REGISTERED_THROUGH } from '../../config';

export default function Contact() {

  const [formData, setFormData] = useState({
    email: '',
    full_name: '',
    phone: '',
    address: '',
    city: '',
    state_id: null,
    state_name: '',
    zip: '',
    message: '',
    hear_about_us: '',
    platform: REGISTERED_THROUGH,
  })
  const [state, setState] = useState('');

  const [errors, setErrors] = useState({
    email: '',
    full_name: '',
    message: '',
    phone: '',
  })
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [stateList, setStateList] = useState('');
  
  useEffect(() => {
    handleValidation();
    //eslint-disable-next-line
  }, [formData['email'],formData['full_name'], formData['message'], formData['phone']]);

  useEffect(() => {
    console.log(REGISTERED_THROUGH);
    getStateList();
  },[])

  const getStateList = () => {
    axiosApi.get(`${config.apiUrl}/misc/states`)
    .then((res) => {
      setStateList(res?.data?.data);
    }, (err) => {
      console.error(err);
    })
  }
  
  useEffect(() => {
    const stateVal = stateList && stateList.filter(val => val.name.toLowerCase() === state.toLowerCase());
        setFormData((prev) => ({
          ...prev,
          state_id: stateVal && stateVal[0]?.id,
          state_name: state,
        }))
        //eslint-disable-next-line
  }, [state])

  const handleValidation = () => {
    let errArray = {};
    let formIsValid = true;
    if (validateRequiredFields(formData)) {
      if (!formData.email) {
        errArray.email = messages && messages.emptyEmail;
        formIsValid = false;
      }
      if(!formData.full_name?.trim()) {
        errArray.full_name = messages && messages.fullName;
        formIsValid = false;
      }
      if(!formData.message?.trim()) {
        errArray.message = messages && messages.message;
        formIsValid = false;
      }
      setErrors(errArray);
    }
    if(formData.phone && formData.phone.replace(/\D/g, '').length < 10) {
      errArray.phone = messages && messages.phoneLength;
      formIsValid=false;
    }
    if (formData.email && !validateEmail(formData.email)) {
      errArray.email = messages && messages.invalidEmail;
      formIsValid = false;
    }
    setErrors(errArray);
    return formIsValid;
  }

  const handleFormChange = (e) => {
    if(e.target.name === 'phone'){
      setFormData((previousFormData) => ({
        ...previousFormData,
        [e.target.name]: (e.target.value).replace(/\D/g, ''),
      }))
    }
    setFormData((previousFormData) => ({
      ...previousFormData,
      [e.target.name]: e.target.value,
    }))
  }

  const submitHandler = () => {
    const data = {...formData};
    if(data) {
      data.phone = data.phone.replace(/\D/g, '');
    }
    const keys = Object.keys(data);
    keys.forEach(element => {
      if(typeof data[element] === 'string' ) {
        data[element] = data[element]?.trim();
      }
    });
    setFormSubmitted(true);
    if (handleValidation()) {
      axiosApi.post(`${config.apiUrl}/contact-us`, data)
        .then((res) => {
          const data = {
            email: '',
            full_name: '',
            phone: '',
            address: '',
            city: '',
            state_id: null,
            zip: '',
            message: '',
            hear_about_us: '',
            platform: REGISTERED_THROUGH,
          }
          setFormData(data);
          setFormSubmitted(false);
        })
    }
  }
  
  const getPlace = (place) => {
    place?.address_components?.forEach(element => {
      if (element?.types.includes('postal_code')) {
        setFormData((prev) => ({
          ...prev,
          zip: element.long_name,
        }))
      }
      if (element?.types.includes('administrative_area_level_1')) {
        setState(element.long_name);
      }
      if (element?.types.includes('administrative_area_level_2')) {
        setFormData((prev) => ({
          ...prev,
          city: element.long_name,
        }))
      }
    });
    if (place.addressVal) {
      setFormData((prev) => ({
        ...prev,
        address: place.addressVal,
      }))
    } else {
      setFormData((prev) => ({
        ...prev,
        address: place,
      }))
    }
  }

  return (
    <>
      <main className="wrapper-main">
        <section className="content-as-block contact-us-wrapper">
          <div className="cs-container">
            <div className='contact-us-inner'>
              <div className="contact-updates-inner left">
                <div className="con-info">
                  <h2>Contact Info</h2>
                </div>
                <div className="con-info meets-hours">
                  <h3>Phone</h3>
                  <div className="inner-blk">
                    <span className="label">Mulberry's Bar</span>
                    <span className="value">216-443-4843</span>
                  </div>  
                  <div className="inner-blk">
                    <span className="label">Brian Miller, League info</span>
                    <span className="value">937-902-6129</span>
                  </div>  
                  <div className="inner-blk">
                    <span className="label">Keith Cochrane, Marketing</span>
                    <span className="value">216-410-2470</span>
                  </div>  
                </div>
                <div className="con-info meets-hours">
                  <h3>Rainouts</h3>
                  <div className="inner-blk">
                    <span className="label">Brian Miller</span>
                    <span className="value">937-902-6129</span>
                  </div>    
                </div>
                <div className="con-info meets-hours">
                  <h3>Email</h3>
                  <div className="inner-blk">
                    <span className="label">Brian Miller</span>
                    <span className="value">Mulberrys269@yahoo.com</span>
                  </div>  
                  <div className="inner-blk">
                    <span className="label">Keith Cochrane</span>
                    <span className="value">keithc@clevelandplays.com</span>
                  </div>  
                </div>
              </div>
              <div className="right">
                <div className="field-block-outer">
                  <div className="field-blocks">
                    <label>Email Address<sup>*</sup></label>
                    <input type="email" className="form-control" name='email' value={formData['email']} onChange={(e) => handleFormChange(e)} autoComplete='off'/>
                    <ErrorInput formSubmitted={formSubmitted} error={errors['email']} />
                  </div>
                  <div className="field-blocks">
                    <label>Full Name <sup>*</sup></label>
                    <input type="text" className="form-control" name='full_name' value={formData['full_name']} onChange={(e) => handleFormChange(e)} autoComplete='off'/>
                    <ErrorInput formSubmitted={formSubmitted} error={errors['full_name']} />
                  </div>
                  <div className="field-blocks">
                    <label>Phone Number</label>
                    <InputMask type="text" mask={phoneNumberMask} className="form-control" name='phone' value={formData['phone']} onChange={(e) => handleFormChange(e)} autoComplete='off'/>
                    <ErrorInput formSubmitted={formSubmitted} error={errors['phone']} />
                  </div>
                  <div className="field-blocks">
                    <label>Address</label>
                    <input type="text" className="form-control" name='address' value={formData['address']} onChange={(e) => handleFormChange(e)} autoComplete='off'/>
                  </div>
                  <div className="three-col">
                    <div className="select-block">
                      <CustomSelect
                        required={false}
                        label={'State'}
                        data={stateList}
                        placeholder={ formData.state_name ? formData.state_name : 'Select State'}
                        onSelect={(e) => e && handleFormChange({
                          target: {
                            name: 'state_id',
                            value: formData.state_id ? formData.state_id : e?.id,
                          }
                        })}
                        selector={'name'}
                      />
                    </div>
                    <div className="field-blocks">
                      <label>City</label>
                      <input type="text" className="form-control" name='city' value={formData['city']} onChange={(e) => handleFormChange(e)} autoComplete='off'/>
                    </div>
                    <div className="field-blocks">
                      <label>Zip Code</label>
                      <input type="text" className="form-control" name='zip' value={formData['zip']} onChange={(e) => handleFormChange(e)} autoComplete='off'/>
                    </div>
                  </div>
                  <div className="field-blocks messages">
                    <label>Message <sup>*</sup></label>
                    <textarea rows="4" cols="50" className="message-textarea" name='message' value={formData['message']} onChange={(e) => handleFormChange(e)} autoComplete='off'></textarea>
                    <ErrorInput formSubmitted={formSubmitted} error={errors['message']} />
                  </div>
                  <div className="field-blocks hear">
                    <label>How did you hear about Mulberry's Cleveland?</label>
                    <textarea rows="2" cols="50" className="message-textarea" name='hear_about_us' value={formData['hear_about_us']} onChange={(e) => handleFormChange(e)} autoComplete='off'></textarea>
                  </div>
                  <div className="send-message">
                    <button className="btn" onMouseDown={() => submitHandler()}>Send Message</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}