import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { config } from "../../../config";
import axiosApi from "../../../core/interceptor/interceptor";
import { useSelector } from "react-redux";
import { userDetails } from "../../../store/reducer/LoggedInUserDetails";
import '../../league/League.css';
import { formatNumberToCurrency, EVENT_TYPE, EVENT_STATUS } from "../../../utils/Utils";
import { Popup } from 'reactjs-popup';
import AddLeadPopup from "./AddLeadPopup";
import CustomSelect from "../../../utils/CustomSelect";
import bellicon from '../../../assets/icons/bell.svg';
import FullCalendarPopup from "../../common/FullCalendar/FullCalendarPopup";

function Leads(props) {
  const userDetail = useSelector(userDetails);
  const history = useHistory();
  const [leads, setLeads] = useState([]);
  const [openLeadPopup, setOpenLeadPopup] = useState(false);
  const [venues, setVenues] = useState([]);
  const [openCalendar, setOpenCalendar] = useState(false);

  const getMyLeads = (id, params) => {
    axiosApi.get(`${config.apiUrl}/leads/${id}`, { params })
      .then((res) => {
        if (res && res.data && res.data.data) {
          setLeads(res.data.data);
        }
      })
  }

  useEffect(() => {
    if (userDetail) {
      getMyLeads(userDetail.id);
    }
  }, [userDetail])

  const closePopup = (cb) => {
    setOpenLeadPopup(cb);
  }
  const refreshList = (cb) => {
    if (cb) {
      getMyLeads(userDetail.id);
    }
  }

  const getVenues = () => {
    axiosApi.get(`${config.apiUrl}/events/venues`)
      .then((res) => {
        setVenues(res?.data?.data);
      })
  }

  useEffect(() => {
    getVenues();
  }, [])

  useEffect(() => {
    if (leads) {
      let arr = [];
      arr.concat(leads);
      leads.forEach(element => {
        if (element.leads_documents) arr = arr.concat(element.leads_documents);
        if (element.event_invoices) arr = arr.concat(element.event_invoices);
      });
      const checkUnread = arr.some(e => e.is_seen === 0);
      if (props && props.unRead) {
        props.unRead(checkUnread);
      }
    }
  }, [leads, props]);

  const handleStatusChange = (status) => {
    getMyLeads(userDetail.id, { status });
  }

  const closeCalendar = (cb) => {
		setOpenCalendar(cb);
	}

  const getDateRange = (dateCallback) => {
    setOpenLeadPopup(true);
	}

  return <>
    <div className="acc-top-bar with-btn with-dd-btn">
      <div className="ns-page-title">
        <h3>My Events</h3>
      </div>
      <div className="breadcrumbs with-dropdown">
        <div className="custom-select">
          <CustomSelect
            required={false}
            // label={'State'}
            data={[
              {
                id: '',
                name: 'All'
              },
              {
                id: 'open',
                name: 'Open'
              },
              {
                id: 'active',
                name: 'Approved'
              },
              {
                id: 'rejected',
                name: 'Rejected'
              }
            ]}
            placeholder={'All'}
            onSelect={(e) => e && handleStatusChange(e.id)}
            selector={'name'}
          />
        </div>
        <button onClick={() => setOpenCalendar(true)} className="btn">Add Event</button>
      </div>
    </div>
    <div className="acc-main-container">
      <div className="event-list-page with-new-cols my-ev-cards">
        <div className="ev-row-cards">
          {
            Array.isArray(leads) && leads.length ?
              leads.map((lead, i) => {
                const leadDoc = lead?.leads_documents.some(element => element.is_seen === 0);
                const leadInvoice = lead?.event_invoices.some(element => element.is_seen === 0);
                return <div className="ev-row-card" key={i}>
                  <div className="ev-info-side">
                    <div className={`ev-text`}>
                      <h3 className={`${(leadDoc || leadInvoice) ? 'notify-dot' : ''}`}>
                        {lead?.title ? lead?.title : 'N/A'}
                        {(leadDoc || leadInvoice) ? <img className="bell-icon" src={bellicon} alt='' /> : ''}
                      </h3>
                      <p>{lead?.start_date_time && lead?.end_date_time ? `${moment(lead?.start_date_time).format('ll, hh:mm a')} - ${moment(lead?.end_date_time).format('ll, hh:mm a')}` : 'N/A'}</p>
                    </div>
                    <div className="ev-more-info">
                      <h3>{lead?.type ? EVENT_TYPE[lead?.type] : 'N/A'}</h3>
                      <p>Type</p>
                    </div>
                    <div className="ev-more-info">
                      <h3>{lead?.occasion ? lead?.occasion : 'N/A'}</h3>
                      <p>Occasion</p>
                    </div>
                    <div className="ev-more-info">
                      <h3>{lead?.budget ? formatNumberToCurrency(lead?.budget) : '$0.00'}</h3>
                      <p>Budget</p>
                    </div>
                    <div className="ev-more-info">
                      <h3>{lead?.status ? EVENT_STATUS[lead?.status === 'active' ? 'approved' : lead?.status] : 'N/A'}</h3>
                      <p>Status</p>
                    </div>
                  </div>
                  <div className="ev-card-right">
                    <div className="ev-card-actions">
                      <button
                        onClick={() => history.push({
                          pathname: `events/${lead?.id}`,
                          state: {
                            lead_type: lead && lead?.lead_type ? lead?.lead_type : ''
                          }
                        })}
                        className="btn"
                      >
                        View Detail
                      </button>
                      {/* <button onClick={() => handleDelete(lead?.id)} className="btn btn-gray">
                      Remove
                    </button> */}
                    </div>
                  </div>
                </div>
              })
              :
              <div className="noRecord-found">
                <p>No Record Found</p>
              </div>
          }
        </div>
      </div>
    </div>
    <Popup className="ns-popup full-cal-popup" open={openCalendar} closeOnDocumentClick={false}>
      <FullCalendarPopup dateRange={getDateRange} onClose={closeCalendar} venues={venues} />
    </Popup>
    <Popup className="ns-popup" open={openLeadPopup} closeOnDocumentClick={false}>
      <AddLeadPopup refresh={refreshList} close={closePopup} />
    </Popup>
  </>
}
export default withRouter(Leads);