import React, { useEffect, useState } from "react";
import './TeamInviteDetails.css';
import { useHistory, useParams } from "react-router";
import axiosApi from "../../../../core/interceptor/interceptor";
import { config, REGISTERED_THROUGH } from "../../../../config";
import WarningDialogue from "../../../../utils/WarningDialogue";
import Popup from "reactjs-popup";
import Policy from '../../../common/Policy/Policy';
import { messages } from '../../../../messages';
import ErrorInput from '../../../common/ErrorInput';
import { validateRequiredFields } from '../../../../utils/Utils';
import backArrowGreen from "../../../../assets/icons/backArrowGreen.svg";

export default function TeamInviteDetails(props) {

  const [invitation, setInvitation] = useState([]);
  const [formData, setFormData] = useState({
    policies: false,
    covid_policies: false,
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [openWarningPopup, setOpenWarningPopup] = useState(false);
  const [popupContent, setPopupContent] = useState({});
  const [policy, setPolicy] = useState(false);
  const [errors, setErrors] = useState({
    policies: '',
    covid_policies: '',
  });
  const id = useParams().id;

  useEffect(() => {
    getInvitation();
    //eslint-disable-next-line
  }, [])
  const history = useHistory();
  const getInvitation = () => {
    axiosApi.get(`${config.apiUrl}/team/invitations/${id}`)
      .then((res) => {
        if (res && res.data && res.data.data) {
          setInvitation(res.data.data);
        }
      }, (err) => {
        console.error(err);
      })
  }
  useEffect(() => {
    handleValidation();
    // eslint-disable-next-line
  }, [formData]);

  const handleCheckBox = (e) => {
    setFormData((previousFormData) => ({
      ...previousFormData,
      [e.target.name]: !formData[e.target.name],
    }))
  }

  const handleValidation = () => {
    let errArray = {};
    let formIsValid = true;
    if (validateRequiredFields(formData)) {
      if (!formData.policies || !formData.covid_policies) {
        formIsValid = false;
        errArray['policies'] = messages && messages.selectPolicies;
      }
      setErrors(errArray);
    }
    setErrors(errArray);
    return formIsValid;
  }

  const handleAcceptence = () => {
    setFormSubmitted(true);
    if (handleValidation()) {
      axiosApi.put(`${config.apiUrl}/misc/validate-token/${invitation.token}`)
        .then((res) => {
          axiosApi.post(`${config.apiUrl}/misc/add-player/${res.data.data.id}`, {
            team_id: res.data.data.team_id,
            user_id: res.data.data.player_id,
            email: res.data.data.email,
            policies: formData.policies,
            covid_policies: formData.covid_policies,
            registered_through: REGISTERED_THROUGH,
          }).then((res) => {
            history.push('/my-account/team-invites');
          }, (err) => {
            console.error(err);
          })
        }, (err) => {
          console.error(err);
        })
    }
  }

  const handleDecline = () => {
    setOpenWarningPopup(true);
    setPopupContent({
      header: 'Decline Invitation?',
      description: 'Are you sure you want to decline invitation?',
      action: () => {
        axiosApi.put(`${config.apiUrl}/misc/validate-token/${invitation.token}`)
          .then(() => {
            axiosApi.delete(`${config.apiUrl}/misc/delete-invitation/${invitation.id}`)
              .then(() => {
                setOpenWarningPopup(false);
                history.push('/my-account/team-invites');
              }).catch(() => {
                setOpenWarningPopup(false);
              })
          }).catch(() => {
            setOpenWarningPopup(false);
          })
      }
    })
  }

  return <>
    <main className="wrapper-main common-bg">
      <section className="main-side no-sidebar">
        <div className="main-side-container">
          <div className="main-top-headings">
            <div className="league-back-link">
              <a href="#" onClick={(e) => {
                history.push('/my-account/team-invites');
              }}>
                <img src={backArrowGreen} alt="" />
                Back
              </a>
            </div>
          </div>
          <section className="invite-view-block invite-page-ui">
            <div className="cs-row">
              <div className="c-12">
                <h4>Team</h4>
                <div className="team-deatils-inner my-account-inner">
                  <div className="matches-updates">
                    <div className="upcoming-updates">
                      <div className="athlete-league">
                        <div className="upcoming-updates-inner">
                          <p>{invitation?.team?.name}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h4>Invite Details</h4>
            <div className="my-account-inner invite-blk">
              <div className="matches-updates">
                <div className="invite-block-inner">
                  <p>You've been invited to join the team " {invitation && invitation.team && invitation.team.name} ". Please review our policies and either accept or decline the invitation.</p>
                  <div className="feature-check-block account-checked">
                    <div className="checkbox-policy-blk">
                      <label className="custom-checkbox">
                        <input type="checkbox" name='policies' value={formData.policies} onChange={(e) => handleCheckBox(e)} />
                        <span>I have read and fully understand the</span>
                      </label>
                      <span className='link' onClick={() => setPolicy({
                        open: true,
                        type: 'cancelation'
                      })}>Cancellation and Registration Policies</span>
                    </div>
                    <div className="checkbox-policy-blk">
                      <label className="custom-checkbox">
                        <input type="checkbox" name='covid_policies' value={formData.covid_policies} onChange={(e) => { handleCheckBox(e) }} />
                        <span>I have read and fully understand the</span>
                      </label>
                      <span className='link' onClick={() => setPolicy({
                        open: true,
                        type: 'covid19',
                      })}>Covid-19 Policies</span>
                      <ErrorInput formSubmitted={formSubmitted} error={errors['policies']} />
                    </div>
                  </div>
                  <div className="inviting-btn">
                    <span className="btn btn-gray" onClick={() => handleAcceptence()}>Accept Invitation</span>
                    <span className="btn" onClick={() => handleDecline()}>Decline Invitation</span>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <WarningDialogue
            isOpen={openWarningPopup}
            header={popupContent.header}
            description={popupContent.description}
            action={popupContent.action}
            onClose={() => setOpenWarningPopup(false)}
          />
          <Popup open={policy?.open} onClose={() => {
            setPolicy({ open: false, type: '' });
          }} closeOnDocumentClick="false">
            {
              <Policy type={policy?.type} closeModal={() => setPolicy({ open: false, type: '' })} />
            }
          </Popup>
        </div>
      </section>
    </main>
  </>
}
